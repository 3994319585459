/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { fetchNoticeList } from "../api/fetchNoticeList";
import { languageSelector } from "../util/language";
import { getYYYYmmDDFromISO } from "../util/time";
import "../style/notice.scss";
import styles from "../style/pagination.module.scss";
import ReactPaginate from "react-paginate";

export interface INoticeProps {
  lang: string;
  isBrowser?: boolean;
  isPagination?: boolean;
  itemPerPage?: number;
}

export const getNoticeUrl = (
  isBrowser: boolean | undefined,
  lang: string,
  id?: number
) => {
  let url = "/notice";
  if (isBrowser) {
    url = lang.toLocaleLowerCase() === "ko" ? "/notices_kr" : "/notices";
  } else {
    url = lang.toLocaleLowerCase() === "ko" ? "/notice_kr" : "/notice";
  }
  if (!isNaN(id as number)) {
    return url + `/${id}`;
  }
  return url;
};

const Notice: React.FC<INoticeProps> = ({
  lang,
  isBrowser,
  isPagination,
  itemPerPage,
}) => {
  //https://api.dq.neopin.io/napi/v2/support/noti/list
  const { data } = useSWR(`/napi/v1/notice`, fetchNoticeList);
  const [page, setPage] = useState<number>(0);
  const handlePageClick = (selectedItem: { selected: number }) => {
    setPage(selectedItem.selected);
  };
  window.scrollTo(0, 0);
  return (
    <div className="notice">
      <ul className="notice-list">
        {data &&
          data.notices &&
          data?.notices
            .filter((item: any) => item[lang.toLowerCase()])
            .filter((item, index) => {
              if (!isPagination || !itemPerPage) {
                return true;
              }
              return (
                index >= page * itemPerPage && index < (page + 1) * itemPerPage
              );
            })
            .map((item: any) => {
              const i18n = languageSelector(item, lang);
              return (
                <li className="notice-list-item" key={item.id}>
                  <Link
                    className="notice-list-item-link"
                    to={getNoticeUrl(isBrowser, lang, item.id)}
                  >
                    <span
                      className={`list-item-content ${
                        isBrowser ? "browser" : ""
                      }`}
                    >
                      <span className="list-item-title">
                        {i18n?.subject || ""}
                      </span>
                      <span
                        className={`list-item-open-at ${
                          isBrowser ? "browser" : ""
                        }`}
                      >
                        {getYYYYmmDDFromISO(item.openAt)}
                      </span>
                    </span>
                    <span
                      className={`list-item-arrow ${
                        isBrowser ? "browser" : ""
                      }`}
                    ></span>
                  </Link>
                </li>
              );
            })}
      </ul>
      {isPagination &&
        data?.notices &&
        data.notices.length > 0 &&
        itemPerPage && (
          <div className="notice-list-pagination">
            <ReactPaginate
              onPageChange={handlePageClick}
              pageCount={Math.ceil(data.notices.length / itemPerPage)}
              pageRangeDisplayed={5}
              breakLabel="..."
              nextLabel=">"
              previousLabel="<"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName={styles.pageNavigation}
              activeClassName={styles.activePage}
              disabledClassName={styles.disabledPage}
              forcePage={page}
            />
          </div>
        )}
    </div>
  );
};

export default Notice;
