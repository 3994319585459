/* eslint-disable import/no-anonymous-default-export */
export const payoutOpenEvt220228 = {
  ko: {
    subject: "[공지] 가입이벤트 7 NPT 보상 및 스테이킹 NPT 보상 지급 개시",
    body: `안녕하세요.
블록체인 오픈플랫폼 네오핀입니다.

네오핀 토큰(NPT)의 글로벌 상장을 기념하여 7 NPT 보상과 클레이튼(KLAY), 트론(TRX) 스테이킹 시의 NPT 보상 지급을 시작합니다.

지난 2월 7일(월) ~13일(일) 진행했던 가입 이벤트에 대한 7 NPT 보상을 기존 3월 31일(목)에서 2월 25일(금)로 일정을 앞당겨 지급합니다.

가입 이벤트에 참여하셨던 이용자님들은 25일 오후 9시에 네오핀 어플 내 ‘미수령 보상’ 탭에서 수령하실 수 있습니다.

[보상 수령 방법]
지갑 > 나의 투자 > ‘미수령 보상’에서 ‘수령하기’ 버튼 클릭 > ‘보상 수령’ 버튼 클릭

클레이튼(KLAY), 트론(TRX) 스테이킹 시 지급되는 NPT 보상도 기존 AFO 종료 48시간 이후가 아닌 25일부터 수령할 수 있습니다.

※ 클레이튼(KLAY) 스테이킹 보상과 네오핀 토큰(NPT) 보상은 최초 스테이킹 48시간 이후부터 발생하며, 매주 월요일 오후에 보상을 수령할 수 있습니다. 트론(TRX) 스테이킹 보상은 매일 수령할 수 있습니다.

기존에 받으신 스테이킹 NPT 보상은 25일 오후 9시에 네오핀 어플 내 ‘미수령 보상’ 탭에서 수령하실 수 있습니다.

감사합니다.
네오핀 드림
    `,
  },
  en: {
    subject:
      "[Notice] Advanced Payout of NPT Rewards: 7 NPT from Sign-up Event and NPT Rewards for Staking",
    body: `Hi, this is NEOPIN, an open blockchain platform for all.

​To celebrate NPT’s Global Listing, we would like to announce an advanced payout of NPT rewards. This payout applies to NPT rewards from the following two events:

​1. Payout of Sign-up Event Rewards
7 NPT Rewards for users who have signed up during the Sign-up Event [Feb. 7th (Mon) — Feb. 13th (Sun)] will be rewarded on Feb. 25th (Fri) instead of Mar. 31st (Thurs).

​You may view your NPT rewards on the “Remaining Rewards” tab on the NEOPIN app on Feb. 25th, 12:00.

​[How to Claim Rewards] 
Wallet > Invest > Tap “Claim” on the “Remaining rewards” tab > Tap “Claim Rewards” tab

​2. Payout of Additional NPT Rewards for Staking Klaytn (KLAY) or Tron(TRX)
You may view your additional NPT rewards for Staking Klaytn (KLAY) or Tron(TRX) on the “Remaining rewards” tab on the NEOPIN app on Feb. 25th, 12:00 instead of 48 hours after the end of the AFO period.

​Please note:
Klaytn(KLAY) Staking rewards and NPT rewards can only be received 48 hours after the first staking, and rewards are given out every Monday afternoon. However, TRON(TRX) Staking rewards can be received every day.

Team NEOPIN

    `,
  },
};
