export const iosOpen30401 = {
  en: {
    subject: "[Notice] NEOPIN iOS 2.2.0 Release Notice",
    body: `We are pleased to announce that NEOPIN iOS 2.2.0 version has successfully passed the store review and is now available for download on the Apple App Store. iOS users are advised to update to the latest version to enjoy the enhanced features and improved user experience.

    Thank you for your continuous support for NEOPIN.
    
    `,
  },
};
