/** @format */

import React, { useEffect } from "react";
import "../style/notice.scss";
import PageHeader from "../components/notice/PageHeader";
import NoticeDetail from "./NoticeDetail";
import { getNoticeUrl } from "./Notice";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const BrowserNoticeDetail: React.FC<{}> = () => {
  const { t, i18n } = useTranslation("common");
  let lang = "en";
  if (window.location.href.includes("_kr")) {
    lang = "ko";
  } else {
    lang = "en";
  }
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);
  return (
    <div className="browser-notice">
      <PageHeader
        pageTitle={lang === "ko" ? "공지사항" : "Notice"}
        titleLink={getNoticeUrl(true, lang)}
      />
      <div className="browser-notice__wrapper">
        <NoticeDetail isBrowser={true} />
        <div className="browser-notice__buttons">
          <Link
            to={getNoticeUrl(true, lang)}
            className="browser-notice__btn-back"
          >
            <span className="arrow">{"<"}</span>
            <span>{t("backToList")}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BrowserNoticeDetail;
