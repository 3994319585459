/* eslint-disable import/no-anonymous-default-export */
export const nftOpen221004 = {
  ko: {
    subject: "[소식] 네오핀 NFT 지갑 출시 안내",
    body: `안녕하세요, 네오핀입니다.

    네오핀이 NFT 지갑 기능을 선보입니다.
    
    네오핀 NFT 지갑을 통해 더욱 편리하게 NFT 컬렉션을 전송하고 확인해 보세요!
    
    [지원 NFT 컬렉션]
    현재 Klaytn 네트워크의 총 12개 프로젝트 NFT 컬렉션이 지원되며, 지원 네트워크와 컬렉션은 지속적으로 확장될 예정입니다.
    
    3SPACE ART, TEAMKOREA NFT, ArcheWorld_Land, ArcheWorld_FandomCard, BIRDIESHOT, Bellygom World Official, Puuvilla Society, Sunmiya Club Official, SuperWalk, G RILLA (Mutant Kongz & G.rilla Official), MTDZ (Meta Toy DragonZ), THE ROAZ (THE ROALAND)
    
    [네오핀 NFT 지갑 컬렉션 지원 문의]
    네오핀 NFT 지갑 지원에 관심이 있으신 NFT 서비스라면 아래 비즈니스 페이지를 통해 지원 신청이 가능합니다.
    
    - 네오핀 비즈니스 페이지 바로가기: <a href="https://neopin.io/business">https://neopin.io/business</a>
    - 관련 문의: support@neopin.io 
    
    [네오핀 NFT 지갑 이용 문의]
    서비스 이용 중 궁금하신 점이 있다면 아래 이메일을 통해 언제든지 연락 부탁드립니다.
    
    - 네오핀 고객센터: help@neopin.io 
    
    앞으로도 사용자님들의 편의를 위한 다양한 기능으로 찾아뵙는 네오핀이 되겠습니다.
    
    감사합니다.
    
`,
  },

  en: {
    subject: "[News] NFT Wallet Service Launch",
    body: `We would like to inform you that NEOPIN Wallet service has been launched. 
    Now you can transfer your NFT collections through the NEOPIN Wallet effortlessly.
    
    [NFT Collection Support]
    NFT collections of 12 projects on the Klaytn network listed below will be supported. The supported networks and collections will continue to expand.
    
    3SPACE ART, TEAMKOREA NFT, ArcheWorld_Land, ArcheWorld_FandomCard, BIRDIESHOT, Bellygom World Official, Puuvilla Society, Sunmiya Club Official, SuperWalk, G RILLA (Mutant Kongz & G.rilla Official), MTDZ (Meta Toy DragonZ), THE ROAZ (THE ROALAND)
    
    [Business Inquiry]
    NFT providers interested in onboarding to NEOPIN can apply for support through the business page below.
    
    - Go to NEOPIN business page: <a href="https://neopin.io/business">https://neopin.io/business</a>
    - Other inquiries: support@neopin.io
    
    [NFT Wallet Inquiry]
    If you have any questions while using the service, please feel free to contact us through the following:
    
    - NEOPIN Customer Center: help@neopin.io
    
    We will continue to look for opportunities to introduce various features for the convenience of users.
    
    Thank you.
    
`,
  },
};
