export const zemitEnd230501 = {
  en: {
    subject: "[Notice] Scheduled on Termination of the ZEMIT product service",
    body: `NEOPIN will no longer provide ZEMIT-related products including its swap features and rewards for providing liquidity on May 2nd 2023.
    Please refer to your investment and asset management.
    
    [ Subject to service termination ]
    - ZEMIT-NPT Pool
    - ZEMIT-NPT Swap
    
    [ Service Termination Schedule ]
    - Until 05:59:59 on May 2nd, 2023 UTC : Service available normally
    
    - From 06:00:00 on May 2nd, 2023 UTC:
      (1) ZEMIT-NPT Pool: Unable to deposit new pool, suspension of reward payment, and continue to provide pool withdrawal feature
      (2) ZEMIT Swap Not Supported
    
    Thank you for your continuous support for NEOPIN.
      
    `,
  },
};
