export const maintenance230425 = {
  en: {
    subject: "[Maintenance] Service Update and Maintenance",
    body: `NEOPIN will be undergoing maintenance to enhance products and improve user experience. 
    Please note that service will be temporarily suspended during the maintenance period.
    If you need to deposit or withdraw funds, please check the date and time below and plan accordingly.

    [ Schedule ]
    2023/04/27(Thu.) 05:00 (UTC)
    
    [ Updates ]
    1. TRON Stake 2.0:
    NEOPIN has added a new product, TRON Stake 2.0, which allows you to stake TRON and receive rewards.
    Please note that the previous Stake product only allows for reward claims and unstaking.
    
    2. Partial Unstaking for Klay:
    You can now partially unstake Klay instead of unstaking the full amount that was originally staked.
    
    3. New Token and Swap Pool:
    NEOPIN has added a new token $BEAT and a swap pool to the Polygon Network service.
    You can now easily provide liquidity and exchange tokens through NEOPIN.
    
      - METABEAT (BEAT) Token
      - NPT-BEAT Swap Pool
    
    [ Note ]
    Recently, there have been delays in the review process for the Apple App Store.
    Please note that updates for iOS apps may be delayed depending on the status of the review process.
    
    Thank you for your continued support of NEOPIN.
    
    `,
  },
};
