export const pixelplay230126 = {
  ko: {
    subject: "[파트너십] 네오핀, 픽셀플레이와 파트너십 체결, 픽셀배틀 온보딩",
    body: `안녕하세요. 네오핀입니다.

    네오핀이 픽셀플레이와 파트너십을 체결하고, 슈팅게임 픽셀배틀을 온보딩합니다.
    
    픽셀플레이는 15년차 이상의 전문가들로 구성된 블록체인 전문 개발사입니다. 특히, 주축 멤버들이 기존 선보였던 게임들의 글로벌 다운로드가 2,500만을 넘어서는 등 탄탄한 실력을 자랑하고 있습니다.
    
    픽셀배틀은 올해 출시를 앞둔 폴리곤 기반 웹3 PvP슈팅 게임입니다. 글로벌 최대 NFT 컨퍼런스인 ‘NFT.NYC 2022’에서 공개된 뒤 최근 오픈베타 서비스 및 NFT 발행에 이르기까지 높은 관심을 꾸준히 이끌어내고 있습니다.
    
    게임 온보딩 이외에도, 픽셀배틀의 거버넌스 토큰 PWC 디파이 상품 출시, 런치패드 기능 지원, 글로벌 공동마케팅 등이 계획되어 있으니 네오핀과 픽셀플레이에 많은 응원과 관심 부탁드립니다.
    
    감사합니다.
    
  `,
  },

  en: {
    subject:
      "[Partnership] NEOPIN signs partnership with Pixel Play, onboards Pixel Battle",
    body: `NEOPIN has signed a strategic partnership with Pixel Play and will onboard Pixel Battle, a galactic shooting game.

    Pixel Play is a blockchain developer consisting of experts with nearly two decades of experience. Its core members took part in global games with over 25 million cumulative downloads. 
    
    Pixel Battle is a Polygon-based Web3 PvP shooting game due for launch this year. It was first revealed in NFT.NYC 2022, and has garnered much positive attention throughout its open beta and NFT mint stages. 
    
    In addition to the game onboarding, we have much more planned for this partnership. They include PWC’s (Pixel Battle’s governance token) DeFi product launch, launchpad features, joint global marketing, and more, so we ask for your continuous support and attention.
    
    Thank you.
    
`,
  },
};
