/* eslint-disable import/no-anonymous-default-export */
export const coinoneListing220322 = {
  ko: {
    subject: "[상장] 네오핀 토큰(NPT) 코인원 거래소 상장",
    body: `안녕하세요.
블록체인 오픈플랫폼 네오핀입니다.

‘네오핀 토큰(NPT)’이 코인원 거래소에 오는 22일(화) 상장합니다. 22일(화) 한국시간 오전 10시에 입금이 개시되며, 22일(화) 오후 6시에 NPT/KRW 거래 페어가 개시될 예정입니다.

1. 거래소 : Coinone (코인원)
2. 토큰명 : NPT
3. 마켓 : NPT/KRW
4. 입금 개시 : 2022년 3월 22일(화) 오전 10:00 (KST)
5. 거래 개시 : 2022년 3월 22일(화) 오후 06:00 (KST)

     `,
  },
  en: {
    subject: "[Listing] NEOPIN Token(NPT) listing on Coinone",
    body: `Hi, we are NEOPIN, an open blockchain platform for all.

We brought you some more exciting news for you today: NPT(Neopin Token) will be listed on Coinone!

Deposit will open at 01:00 AM (UTC) on Mar. 22nd, 2022, and the trading pair for NPT-KRW will begin at 09:00 AM (UTC) on Mar. 22nd, 2022.

New listing on “Coinone” :
1. Exchange : Coinone
2. Name of Token : NPT
3. Market : NPT/KRW
4. Opening Date of Deposit : Mar. 22nd, 2022. 01:00 AM (UTC)
5. Opening Date of Trading Pair : Mar. 22nd, 2022. 09:00 AM (UTC)

    `,
  },
};
