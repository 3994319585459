export const maintenance230825 = {
  ko: {
    subject: "[공지] 서비스 업데이트에 따른 점검 사전 안내",
    body: `네오핀은 서비스 및 상품의 확장과 사용자 편의성 개선을 위해 업데이트를 진행할 예정입니다.
    업데이트 시간 동안 점검을 진행하여 서비스가 중단될 예정이오니, 자산 예치 및 인출이 필요하신 경우 참고해주시기 바랍니다.
    
    [ 점검 일시 ]
    - 2023년 8월 29일(화) 14:00 ~ 16:00(KST)
    * 점검 시간은 상황에 따라 변동될 수 있습니다.
    
    [ 업데이트 내용 ]
    1. Klaytn 체인 거버넌스 투표
    Klaytn의 거버넌스 의제에 투표권을 행사하는 기능을 도입하였습니다.
    Klay Stake & Klay Liquid Stake 상품 사용자분들은 투표에 참여하여 의사를 표현 할 수 있고, NEOPIN은 이를 반영하여 GC 투표를 진행합니다.
    
    더 다양하고 안전한 디파이 상품들을 출시할 예정이오니 많은 관심 부탁드립니다.
    항상 네오핀을 이용해주셔서 감사합니다.
    
    `,
  },
  en: {
    subject: "[Maintenance] Service Update and Maintenance",
    body: `NEOPIN will be undergoing maintenance to enhance products and improve user experience. Please note that service will be temporarily suspended during the maintenance period. If you need to deposit or withdraw funds, please check the date and time below and plan accordingly.

    [ Schedule ]
    2023/08/29(Thu.) 05:00 ~ 07:00(UTC)
    * Subject to change based on circumstances.
    
    [ Updates ]
    1. Klaytn Chain Governance Voting
    NEOPIN has introduced a feature that allows participation in the Klaytn Governance Forum agenda through voting. Users of KLAY Stake & KLAY Liquid Stake products can express their opinions by participating in the Governance Voting, and NEOPIN will conduct GC voting based on user opinions.
    
    Stay tuned for more DeFi products to come.
    Thank you for your continuous support.
    
    `,
  },
};
