export const swapNoti20221214 = {
  ko: {
    subject: "[공지] 네오핀 웹 서비스 이용 안내",
    body: `안녕하세요? 네오핀입니다.

    네오핀은 사용자의 서비스 이용 편의를 위해 웹에서도 Yield Farm, Swap 서비스를 제공하고 있습니다.
    네오핀 홈페이지에서 네오핀 커넥트를 이용해 간단한 QR 인증으로 서비스 이용이 가능합니다.
    
    자세한 이용 방법은 공식 미디엄의 가이드를 참고하시어, 네오핀 웹 서비스 많은 이용을 부탁 드립니다.
    
    <a href="https://medium.com/neopin-official/가이드-웹-web-에서-yield-farm-이용하기-8114e92bd35" >< 웹(Web)에서 Yield Farm 이용하기 ></a>
    <a href="https://medium.com/neopin-official/%EA%B0%80%EC%9D%B4%EB%93%9C-%EC%9B%B9-web-%EC%97%90%EC%84%9C-swap-%EC%9D%B4%EC%9A%A9%ED%95%98%EA%B8%B0-9927012b7a82">< 웹(Web)에서 Swap 이용하기 ></a>
    
    ** iOS 사용자의 경우 네오핀 홈페이지에서 스왑을 이용할 수 있습니다.
    
    항상 네오핀을 이용해 주셔서 감사합니다.
          
  `,
  },

  en: {
    subject: "[Notice] Services on NEOPIN Launchpad",
    body: `NEOPIN provides web based Yield Farm and Swap services in addition to the mobile app for the convenience of users.
    You can use all services on the web after completing a quick QR authentication using NEOPIN CONNECT on the NEOPIN web.
    
    Please refer to the official guides on Medium for more information.

    <a href="https://medium.com/neopin-official/guide-how-to-yield-farm-web-545a318e8e4b">< Using Yield Farm on the Web ></a>
    <a href="https://medium.com/neopin-official/guide-how-to-swap-web-fe9f3ab2696f">< Using Swap on the Web ></a>
    
    ** iOS users can use the swap feature on the NEOPIN web.
    
    Thank you for your continuous support of NEOPIN.
    
`,
  },
};
