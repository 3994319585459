export const ipvEnd221208 = {
  ko: {
    subject: "[공지] IPV 상품 서비스 종료 예정 안내",
    body: `안녕하세요? 네오핀입니다.

     네오핀에서 IPV 관련 상품 지원이 2023년 1월 9일부로 종료될 예정입니다.
    종료된 이후에는 IPV가 포함된 스왑 기능을 지원하지 않으며, 관련 리워드 지급이 중단되니 사용자님의 투자 및 자산 관리에 참고하시기 바랍니다.
    
    [ 서비스 종료 대상 상품 ]
    - IPV Stake
    - IPV-NPT Yield Farm
    - IPV Swap
    
    [ IPV 상품 서비스 종료 일정 ]
    - 2023년 1월 9일 14:59:59 까지 : 상품 서비스 정상 이용 가능
    
    - 2023년 1월 9일 15:00:00 부터 : 
    (1) IPV Stake  : 신규 스테이킹 불가, 리워드 지급 중단, 언스테이킹 가능
    (2) NPT-IPV Yield Farm : 신규 풀 예치 불가, 리워드 지급 중단, 풀 인출 가능
    (3) IPV Swap 미지원 
    
    항상 네오핀을 이용해주셔서 감사합니다.        
  `,
  },

  en: {
    subject: "[Notice] Scheduled on Termination of the IPV product service",
    body: `NEOPIN will no longer provide IPV-related products including its swap features and rewards on January 9th 2023.
     Please refer to your investment and asset management.

    [ Subject to service termination ]
    - IPV Stake
    - IPV-NPT Yield Farm
    - IPV Swap
    
    [ Service Termination Schedule ]
    - Until 05:59:59 on January 9, 2023 UTC : Service available normally
    
    - From 06:00:00 on January 9, 2023 UTC :
    (1) IPV Stake: No new staking, suspension of reward payment, and Unstaking is possible
    (2) NPT-IPV Yield Farm: Unable to deposit new pool, suspension of reward payment, and continue to provide pool withdrawal feature
    (3) IPV Swap Not Supported
    
    Thank you for your continuous support for NEOPIN.
        
`,
  },
};
