export const bugsPartnership220321 = {
  ko: {
    subject: "[파트너십] 네오핀, ‘NHN벅스’와 전략적 파트너십 체결",
    body: `안녕하세요.
블록체인 오픈플랫폼 네오핀입니다.

블록체인 전문 기업 네오플라이의 자회사인 네오핀은 24일 'NHN벅스'와 전략적 파트너십을 체결했습니다.

이번 파트너십은 미래 기술 산업인 블록체인과 생활밀착형 음악서비스 간의 상호 시너지를 위해 체결되었습니다.

양사는 블록체인 오픈플랫폼 네오핀과 국내 대표 음악 서비스인 '벅스'의 공동 마케팅을 진행할 계획입니다.

`,
  },
  en: {
    subject: "[Partnership] NEOPIN signs MOU with NHN Bugs",
    body: `Hi, we are NEOPIN, an open blockchain platform for all.

NEOPIN, a blockchain subsidiary of NEOPLY, has announced on Mar. 24th that it has signed a strategic partnership with NHN Bugs.

This partnership was signed for future-oriented collaborative efforts between the blockchain industry and music services. 
 
The two companies plan to jointly promote the NEOPIN platform and Bugs, a renowned music company specializing in online music service, distribution, and management in Korea.
 
`,
  },
};
