/** API가 아직 없어서 하드코딩. API가 추가되면 주석처리 한 부분 해제 */

// import { IFetcherOption, request } from "./apiRequest";

export interface ITopicFaq {
  title: string;
  topic: string;
  qna: {
    question: string;
    answer: string;
  }[];
}

export interface IFaq {
  ko?: ITopicFaq[];
  en?: ITopicFaq[];
}

export const fetchFaq = async (url: string): Promise<IFaq> => {
  // const option: IFetcherOption = {
  //   method: "GET",

  //   onRequest(params: any) {
  //     return params;
  //   },

  //   onResponse(res: IFaq) {
  //     return res;
  //   },

  //   onError(err: any) {
  //     return {
  //       ko: {},
  //       en: {}
  //     }
  //   },
  // };

  // return request<IFaq>(url, option); // TODO: 이후에 api생기면 활성화

  // *************
  // ** 샘플 api
  // *************
  // {
  //   topic: "Stake",
  //   qna: [
  //     {
  //       question: "스테이킹이 뭔가요?",
  //       answer: "스테이킹이란....",
  //     },
  //     {
  //       question: "리워드는 어떻게 계산 되나요?",
  //       answer:
  //         "질문에 대한 답변은 이렇습니다.질문에 대한 답변은 이렇습니다.질문에 대한 답변은 이렇습니다.질문에 대한 답변은 이렇습니다.",
  //     },
  //   ],
  // },
  return {
    ko: [
      {
        topic: "계정",
        title: "Account",
        qna: [
          {
            question:
              "휴대폰을 바꿨어요. 이전에 사용하던 계정을 사용할 수 있나요?",
            answer: `휴대폰을 바꿔도 복구단어만 정확히 알고 있으면, 이전에 사용하던 계정을 그대로 사용할 수 있습니다.<br />
            새 기기에 네오핀 앱을 새로 설치한 후 이전에 사용하던 Google 계정으로 로그인한 다음 복구단어를 입력하면 보유 중인 자산과 가입한 상품에 대한 모든 정보를 불러올 수 있습니다.`,
          },

          {
            question: "회원 탈퇴는 어떻게 하나요?",
            answer: `• 네오핀 지갑에 자산이 없는 경우:<br />
             1. [설정] 탭 - [앱 정보]에서 ‘회원 탈퇴’ 버튼을 선택합니다.<br />
             2. 탈퇴 규정 및 주의사항 숙지 후, 이에 동의한다면 하단 ‘동의합니다’ 텍스트를 따라 작성합니다.<br />
             3. 탈퇴가 완료가 완료되면 홈 화면으로 이동합니다.<br />
             <br />
             • 네오핀 지갑에 자산이 있는 경우:<br />
             네오핀 지갑에 가상자산 잔고(≈ $10 이상)가 있거나 가입 중인 상품이 있는 경우에는 탈퇴가 불가능합니다.<br />
             [지갑]탭으로 돌아가서 가입한 상품을 모두 해지하고, 남은 자산은 모두 외부 지갑으로 인출한 후 다시 시도해주세요.`,
          },

          {
            question: "네트워크 수수료는 어떻게 정해지나요?",
            answer: `모든 블록체인 네트워크(비트코인, 이더리움, 리플 등)를 사용하려면 네트워크상의 트랜잭션을 처리하고 보안을 유지하기 위해 끊임없이 계산 리소스를 지원하는 사람(채굴자 또는 검증자)의 도움을 필요로하며 이들에게 트랜잭션을 검증하고 확인할 때 수수료가 필요합니다.<br /> 
             네트워크 수수료는 그들에게 인센티브로 주어집니다.`,
          },

          {
            question: "회원 탈퇴 후 재가입할 수 있나요?",
            answer: `탈퇴한 사용자는 재가입을 할 수 없으며, 계정 정보 및 서비스에서의 모든 정보가 삭제되어 복구할 수 없습니다.<br />
             신중히 고민하신 후 탈퇴를 진행해주시기 바랍니다.`,
          },
        ],
      },

      {
        topic: "인증/보안",
        title: "Authentication / security",
        qna: [
          {
            question: "[복구단어] 복구단어란 무엇인가요?",
            answer: `복구단어(Recovery Phrase)는 가상자산 지갑의 프라이빗 키를 일반 사용자가 쉽게 읽을 수 있는 형태로 제공하는 24개의 단어입니다.<br />
            <br />
            사용자가 생성하거나 소유한 모든 가상자산 지갑에는 자산에 접근 할 수 있는 자체 프라이빗 키가 존재합니다.<br />
            프라이빗 키는 각각의 토큰별로 존재하기 때문에 모든 프라이빗 키를 기억하고 안전하게 보관하는 것은 어려운 일입니다.<br />
            <br />
            따라서 네오핀에서는 처음 계정을 만들 때 각각의 프라이빗 키를 표준화된 알고리즘을 통해 하나의 프라이빗 키로 변환합니다.<br />
            그리고 다른 표준화된 알고리즘을 통해 24개의 복구단어로 최종 생성합니다.<br />
            즉, 복구단어는 가상자산에 접근하고 사용할 수 있는 마스터 프라이빗 키이며,<br />
            기기 도난, 분실 또는 장애가 발생하였을 때 다른 기기에서 계정을 가져와 자산에 다시 접근할 수 있는 절대적인 키입니다.<br />
            <br />
            [중요]<br />
            • 따라서 발급된 24개의 복구단어는 복사 후 안전한 곳에 저장하거나 별도로 기록하여 보관해야 합니다.<br />
            • 온라인 뱅킹 비밀번호를 다른 사람과 공유하지 않는 것처럼 복구단어도 네오핀 직원을 포함한 그 누구에게도 공유해서는 안 됩니다!`,
          },

          {
            question: "[복구단어] 내 복구단어는 어디에서 확인할 수 있나요?",
            answer: `다음 위치에서 복구단어를 확인할 수 있습니다.<br />
             <br />
             • 신규 계정 생성 시: 계정 생성 단계에서 복구단어를 확인할 수 있습니다.<br />
             • 계정 생성 후 :  [설정]탭 - [보안설정] - [복구단어 확인]에서 PIN 번호 입력 후 복구단어를 확인할 수 있습니다.<br />
             <br />
             [중요]<br />
             해당 화면을 캡처하여 다른 사람에게 공유하거나 보안이 취약한 곳에 저장하는 것을 절대 금지합니다.`,
          },

          {
            question: "[복구단어] 복구단어는 어떻게 관리하나요?",
            answer: `네오핀 개발사는 사용자의 복구단어의 복사본을 따로 보관하지 않으며 사용자의 프라이빗 키와 관련된 어떠한 정보도 저장하지 않습니다.<br />
             복구단어는 사용자의 기기에서 생성되고 암호화되므로 복구단어에 대한 관리는 사용자 본인에 의해서만 이뤄집니다.<br />
             <br />
             따라서 발급된 복구단어는 복사 후 안전한 곳에 저장하거나 별도로 기록하여 보관해야 합니다.`,
          },

          {
            question:
              "[복구단어] 네오핀에서 생성한 복구단어를 다른 지갑 서비스에서도 이용할 수 있나요?",
            answer: `복구단어는 네오핀 서비스에서만 사용할 수 있으며, 다른 서비스와 호환되지 않습니다.`,
          },

          {
            question: "[인증 설정] PIN 번호는 무엇이며 어떻게 설정하나요?",
            answer: `PIN 번호는 네오핀에서 전송 및 상품 거래 시 사용자 인증 확인용으로 사용하는 번호입니다.<br />
             앱 신규 설치 또는 재설치 시 PIN 번호를 등록할 수 있습니다.<br />
             <br />
            [중요]<br />
             • PIN 번호는 거래 인증 시 필요한 정보이기 때문에 외부 노출이 되지 않도록 관리해주시기바랍니다.<br />
             • 앱 재설치 시에는 24개의 복구단어가 필요하오니 재설치 전 복구단어를 꼭 백업해 두시기 바랍니다.<br />`,
          },

          {
            question: "[인증 설정] PIN 번호를 변경하고 싶어요.",
            answer: `1. [설정]탭 - [인증 설정] - [PIN 번호 변경]을 클릭합니다.<br />
             2. PIN 번호를 인증하면 새로운 PIN 번호를 등록 하실 수 있습니다.`,
          },

          {
            question:
              "[인증 설정] PIN 번호를 잃어버렸어요. 초기화할 수 있나요?",
            answer: `1. [설정]탭 - [인증 설정] - [PIN 번호 초기화]를 선택합니다.<br />
             2. 24개의 복구단어를 입력하면 새로운 PIN 번호를 등록 하실 수 있습니다.`,
          },

          {
            question: "[인증 설정] OTP 코드는 어떻게 설정하나요?",
            answer: `1. [설정] 탭 - [인증 설정] - [OTP 인증 사용] 항목을 ON 시킵니다.<br />
             2. OTP 설정키 화면에서 생성된 설정키 또는 QR 코드를 확인합니다.<br />
             3. 구글 플레이스토어나 애플 앱스토어에 접속하여 Google Authenticator를 설치합니다.<br />
             4. 생성된 설정 키 또는 QR 코드를 Google Authenticator에 등록하여 계정을 추가한 후, ‘다음’을 클릭합니다.<br />
             5. OTP 인증 화면에 활성화된 OTP 코드를 입력합니다.<br />
             <br />
             [중요]<br />
             • 설정 키는 OTP 재등록 시 꼭 필요한 정보이기 때문에 메모 또는 복사하여 안전한 곳에 저장해주세요.`,
          },

          {
            question:
              "[인증 설정] OTP 설정 키를 잃어버렸어요. OTP 설정을 초기화할 수 있나요?",
            answer: `1. [설정] 탭 - [인증 설정] - [OTP 인증 사용]을 클릭합니다.<br />
             2. PIN 번호 입력 후 OTP 입력창 상단의 OTP 초기화 버튼을 선택합니다.<br />
             3. 24개의 복구단어를 입력하면, 새로운 설정키가 발급됩니다.<br />
             4. 새로 발급된 설정 키를 OTP 앱에 재등록하고 OTP 코드를 활성화합니다.<br />
             <br />
             [중요]<br />
             • OTP설정을 초기화하면, 이전에 사용하던 설정 키와 OTP 코드는 사용할 수 없습니다.`,
          },

          {
            question: "[KYC 인증] KYC 인증이란?",
            answer: `KYC 인증은 고객정보확인(KYC), 자금세탁방지(AML), 테러자금조달방지(CFT) 규정을 준수하기 위한 법적 확인 절차입니다.<br />
             제출한 자료는 KYC 인증 외에 어떠한 경우로도 사용되지 않습니다.`,
          },

          {
            question:
              "[KYC 인증] KYC 인증 심사가 거절되었어요. 이유가 무엇인가요?",
            answer: `심사 제출 시 아래 KYC 인증의 요구사항을 모두 충족하고 있는지 확인하여 주시기 바랍니다.<br />
             <br />
            • 인증 수단은 복사본이 아닌 원본으로 등록해야 합니다.<br />
            • 제출한 자료는 식별할 수 있도록 선명하게 나와야 합니다.<br />
            • 유효기간이 명시되어 있는 신분증명서의 경우 유효기간이 지나지 않아야 합니다.<br />
            <br />
            위 내용을 확인하신 후 재심사를 요청해주시기 바랍니다.`,
          },

          {
            question: "[KYC 인증] KYC 인증 심사는 얼마나 걸리나요?",
            answer: `KYC 인증 심사 결과는 심사 요청일로부터 약 2~3일 이내로 완료되며, 결과는 이메일 또는 푸시 메시지를 통해 알려드립니다.`,
          },

          {
            question: "[앱 잠금] 앱 잠금을 설정하고 싶어요.",
            answer: `1. [설정] 탭 - [보안설정] - [앱 잠금 설정]을 선택합니다.<br />
             2. PIN 번호를 인증하면, 네오핀 앱 잠금이 활성화됩니다.<br />
             <br />
             [Tip]<br />
             • 생체 인증 정보도 함께 등록하면 보다 빠르고 편리하게 잠금을 해제할 수 있습니다.`,
          },

          {
            question: "[앱 잠금] 앱 잠금번호를 변경하고 싶어요.",
            answer: `앱 잠금시 사용되는 번호는 PIN 번호를 같이 사용하고 있습니다.<br />
             PIN 번호 변경 시 앱 잠금 번호를 변경 하실 수 있습니다.
             <br />
             [핀 번호 변경]<br />
             1. [설정]탭 - [인증 설정] - [PIN 번호 변경]을 선택합니다.<br />
             2. PIN 번호를 인증하면 새로운 PIN 번호를 등록 하실 수 있습니다.`,
          },

          {
            question:
              "[앱 잠금] 앱 잠금번호를 잃어버렸어요. 잠금번호를 초기화할 수 있나요?",
            answer: `앱 잠금시 사용되는 번호는 PIN 번호를 같이 사용하고 있습니다.<br />
             PIN 번호 초기화 시 앱 잠금 번호를 초기화 하실 수 있습니다.<br />
             <br />
             [핀 번호 초기화]<br />
             1. [설정]탭 - [인증 설정] - [PIN 번호 초기화]를 선택합니다.<br />
             2. 24개의 복구단어를 입력하면 새로운 PIN 번호를 등록 하실 수 있습니다.`,
          },
        ],
      },
      {
        topic: "지갑",
        title: "Wallet",
        qna: [
          {
            question: "지갑 잔고 및 거래 내역이 불러와 지지 않아요.",
            answer: `간혹 네트워크 트래픽 초과 또는 앱 트래픽 혼잡으로 네오핀에서 지갑 잔고 및 거래 내역이 표시되지 않는 문제가 발생할 수 있습니다.<br />
             <br />
             네오핀 앱은 가상자산 잔액을 표시하고 사용자가 암호화된 자산을 관리하기 쉽도록 UI를 제공하는 앱일 뿐 가상자산을 보유하지 않습니다.<br />
             가상자산은 프라이빗 키를 따르기 때문에, 복구 단어를 잃어버리지 않는 한 사용자의 자산은 안전합니다.<br />
             <br />
             다음 블록체인 탐색 링크에서 최신 잔액 및 트랜잭션 정보를 참조할 수 있습니다.<br />
             ETH & ERC-20 토큰: https://etherscan.io/<br />
             BTC/LTC/XRP/XLM: https://blockchair.com/<br />
             ATOM: https://www.mintscan.io/cosmos<br />
             NPT : https://scope.klaytn.com/`,
          },

          {
            question: "실제 거래소 시세랑 달라요",
            answer: `제공된 가상자산의 통화 환산 추정값은 Coinmarketcap  또는 Binance 시세 기준으로 적용하여 환산한 정보입니다.<br />
             따라서 실제 통화로 교환할 시, 거래소별 시세에 따라 실수령 금액과 차이가 있을 수 있습니다.`,
          },

          {
            question: "내 지갑으로 입금하려면 어떻게 하나요?",
            answer: `1. 홈 화면 상단 [자산추가] 버튼 클릭하거나 [지갑] 탭으로 이동하세요.<br />
             2. 입금 주소를 생성할 토큰을 선택하면, 입금 주소 발급이 완료됩니다.<br />
             3. 해당 주소로 토큰을 보내면 트랜잭션 완료 후, 내 지갑으로 입금이 완료됩니다.<br />
             <br />
             [중요]<br />
             • 입금 시에는 주소를 반드시 확인하고 전송해주세요. 잘못된 주소로 입금하여 발생한 손실은 어떤 방법으로도 복구할 수 없습니다.<br />
             • 발급된 주소와 다른 토큰을 입금하면 해당 자산이 분실될 위험이 있습니다.`,
          },

          {
            question: "토큰은 어떻게 전송하나요?",
            answer: `1. [지갑] 탭에서 전송할 토큰을 선택합니다.<br />
             2. 전송할 주소를 직접 입력하거나 주소 QR코드를 스캔하여 받는 주소를 등록합니다.<br />
             3. 전송할 수량을 입력합니다.<br />
             4. 예상 전송 내역을 확인한 후 PIN 번호를 인증하면 전송 요청이 완료됩니다.<br />
             <br />
             [중요]<br />
             • 전송할 주소와 토큰 종류를 반드시 확인하고 전송해주세요. 잘못된 주소로 입금하여 발생한 손실은 어떤 방법으로도 복구할 수 없습니다.<br />
             • 본인의 주소로는 토큰을 전송할 수 없습니다.`,
          },

          {
            question: "주소록에 주소를 어떻게 등록할 수 있나요?",
            answer: `1회 이상 전송한 이력이 있는 주소는 주소록에 자동으로 저장됩니다.<br />
             자주 보내는 주소는 즐겨찾기 설정을 해두거나 별명을 등록하면 더 빠르고 편리하게 이용할 수 있습니다.<br />
             <br />
             [주소록 사용법]<br />
             1. 전송 화면에서 전송 주소 옆에 있는 ‘주소록' 아이콘을 클릭합니다.<br />
             2. 전송할 주소를 선택합니다.<br />
             3. 주소록 아이콘 옆에 주소록 정보가 잘 입력되었는지 확인합니다.`,
          },

          {
            question: "수수료 편집은 무엇인가요?",
            answer: `이더리움 또는 폴리곤과 같은 네트워크는 트랜잭션이 발생할 때 거래 수수료(가스비)를 지급해야합니다. 트랜잭션 시간에 따라 수수료를 조절하여 설정할 수 있는데, 트랜잭션 시간이 짧을 수록 수수료가 높아집니다.<br />
             (네크워크 혼잡도 및 네트워크 사정에 따라 수수료 설정값이 달라질 수 있습니다)<br />
             <br />
             [수수료 설정하는 법]<br />
             1. 예상 전송 내역에서 수수료 옆 [설정] 텍스트 버튼을 클릭합니다.<br />
             2. 수수료 설정 창이 생성됩니다.<br />
             3. 원하는 수수료 설정값을 선택한 후 ‘적용하기' 버튼을 클릭하면 해당 수수료가 적용됩니다.`,
          },

          {
            question:
              "실제 입금된 수량이 입력한 수량보다 적은 이유가 무엇인가요?",
            answer: `블록체인 네트워크를 통해 가상자산을 전송할 때, 네트워크 수수료가 발생합니다.<br />
             수수료는 전송 시 입력한 수량에서 직접 차감되기 때문에 입력한 전송 수량과 실제 입금된 수량과 차이가 발생할 수 있습니다.<br />
             네트워크 수수료는 트랜잭션 세부 정보 화면에서 확인할 수 있습니다.`,
          },

          {
            question: "전송을 요청했는데 아직 입금되지 않았어요.",
            answer: `전송 요청 후, 전송이 완료되기 전까지는 잔고에 입금되지 않습니다. 전송 내역은 블록체인 네트워크에서 승인을 거친 후 잔고에 반영되며, 거래내역에는 '진행 중(pending)' 상태로 표기됩니다.<br />
             보통 몇 분 내로 승인이 완료되지만, 네트워크 혼잡도에 따라 대기시간이 길어질 수 있습니다.<br />
             트랜잭션이 완료되면 푸시 알림을 보내드립니다.<br />
             (요청 후, 트랜잭션 완료까지 1시간 이상이 소요된다면 help@neopin.io로 문의 바랍니다)`,
          },

          {
            question: "다른 토큰(코인)은 거래할 수 없나요?",
            answer: `현재 네오핀 지갑에서 지원하는 토큰(코인)은 총 19개로 NPT, ETH, KLAY, TRX, S2T, BVT, MBX, BORA, SSX, IPV, WIKEN, BBC, ATT, CLBK, MIX, MON, NGIT, OBSR, WEMIX 입니다.<br />
             추후 업데이트를 통해 지원 가능한 토큰의 종류를 늘려갈 계획입니다.<br />
             <br />
             네오핀에서 지원하지 않는 토큰은 입금 또는 전송이 되지 않으니 주의해주시기바랍니다.`,
          },

          {
            question: "NPT는 왜 자산이 분리되어 표시되나요?",
            answer: `NPT 토큰은 멀티 체인을 지원하는 토큰으로 현재 Klaytn, Polygon 체인을 지원하고있습니다.<br />
             토큰 자산은 체인별로 보관할 수 있기에 개별 관리할 수 있도록 분리하여 표시하고 있습니다.<br />
             <br />
             추후 업데이트를 통해 지원하는 네트워크를 늘려갈 계획입니다.<br />`,
          },

          {
            question: "전송 시 네트워크를 선택하는 이유는 무엇인가요?",
            answer: `전송 시 네트워크를 선택하는 경우는 해당 토큰이 멀티 체인을 지원하는 경우에 제공하고 있습니다.<br />
             전송은 네트워크 별로 진행되기 때문에 도착지 주소의 네트워크와 동일한 네트워크로 전송을 진행 하셔야 합니다.<br />
             전송 하시려는 주소의 네트워크와 네오핀에서 제공하는 네트워크가 다른 경우 해당 자산이 유실 될 수 있습니다.<br />
             잘못된 네트워크로 전송된 자산은 복구할 수 없으니 꼭 네트워크를 확인하여 전송해 주시기 바랍니다.`,
          },
        ],
      },
      {
        topic: "보상 확인",
        title: "Reward",
        qna: [
          {
            question: "가입한 상품은 어디서 확인할 수 있나요?",
            answer: `[지갑] 탭 - [Stake] 또는 [Pool]에서 가입한 상품 목록과 상품에 대한 상세 정보를 확인할 수 있습니다.`,
          },

          {
            question: "투자 상품의 보상은 어떻게 수령할 수 있나요?",
            answer: `- Stake / 이벤트 / 기타 보상 수령의 경우<br />
             1. [지갑] 탭 - [Stake]에서 ‘미수령 보상' 탭을 선택합니다.<br />
             2. 수령을 원하는 토큰을 선택한 후 ‘보상 수령' 버튼을 선택합니다.<br />
             3. OTP 코드 인증을 진행합니다.<br />
             4. 트랜잭션 완료 시, 나의 지갑으로 입금됩니다.<br />
             • 미수령된 보상이 없는 경우 보상 수령(Claim) 버튼이 활성화되지 않습니다.<br />
             <br />
             - Pool 상품의 경우<br />
             1. [지갑] 탭 - [Pool]에서 참여한 상품 리스트에서 '수령하기' 버튼을 선택합니다.<br />
             2. 수령을 원하는 토큰을 선택한 후 ‘보상 수령' 버튼을 선택합니다.<br />
             3. OTP 코드 인증을 진행합니다.<br />
             4. 트랜잭션 완료 시, 나의 지갑으로 입금됩니다.<br />
             • Pool 상품의 경우 보상 수령은 24시간 내에 1회만 가능합니다.`,
          },

          {
            question: "보상 수령 시 수수료를 절약하려면 어떻게 하나요?",
            answer: `보상은 토큰별로 누적되어 보상 수령 요청 시마다 토큰별로 각 지갑에 입금됩니다.<br />
             따라서 보상 지급 요청 주기가 길어질수록 트랜잭션 요청 횟수가 줄어들기 때문에 수수료를 절약할 수 있습니다.`,
          },

          {
            question:
              "발생한 보상 수량은 보이는데 보상을 수령할 수 없다고 떠요. 어떻게 하나요?",
            answer: `• 수수료가 부족한 경우:<br />
             사용자의 지갑 주소에 입금 트랜잭션 수수료만큼의 토큰 수량이 없는 경우, 보상 수령이 이루어지지 않습니다.<br />
             해당 주소에 보유한 자산의 수량이 충분한지 확인한 후 다시 보상 수령을 요청해주시기 바랍니다.<br />
             <br />
             • '대기중' 탭에 위치한 경우:<br />
             각 네트워크의 고유한 특징에 따라 상품별로 보상 수령 요청 제한 시간 또는 대기 시간이 존재할 수 있습니다.<br />
             표기된 대기 시간이 지난 후 다시 보상 수령을 요청해주시기 바랍니다.<br />
             <br />
             위 단계를 따라도 문제가 해결되지 않는 경우엔 help@neopin.io로 문의 바랍니다.`,
          },
        ],
      },
      {
        topic: "Stake",
        title: "Stake",
        qna: [
          {
            question: "Stake를 하고 싶어요. ",
            answer: `1. 홈 화면 또는 [상품] 탭에서 원하는 Stake 상품을 선택합니다.<br />
             2. Stake 상품 정보와 유의 사항을 확인한 후, ‘Stake Now' 버튼을 선택합니다.<br />
             3. 예치할 수량을 입력해주세요.<br />
             4. 예상 내역을 확인한 후, PIN 번호를 인증하면 Stake 요청이 완료됩니다.`,
          },

          {
            question: "연 예상 수익률을 어떻게 계산되나요?",
            answer: `Stake의 연 예상 수익률은 체인 인플레이션에 의해 결정되며, 변경되는 정보는 Stake 상품 정보에 업데이트되어 표시됩니다.<br />
             연 예상 수익률은 APR(단리)로 표기되며, 복리 효과를 누리려면 입금된 보상을 추가로 Stake해야 합니다.`,
          },

          {
            question: "보상은 언제부터 누적되나요?",
            answer: `Stake 보상 지급 방식은 가상자산마다 다릅니다.<br />
             - NPT의 경우<br />
             Stake 상품에 참여하면 즉시 보상이 계산되기 시작합니다.<br />
             <br />
             - KLAY, TRX의 경우<br />
             Stake 상품에 참여하고 24시간의 대기 시간을 거친 후, 당일의 수익률을 기준으로 계산됩니다.<br />즉, 1월 1일 오후 2:00에 Stake에 참여한 경우, 1월 2일 오후 2:00에 대기가 해제되고 보상이 계산되기 시작합니다.`,
          },

          {
            question: "Stake 보상은 어떻게 확인하나요?",
            answer: `[지갑] 탭 - Stake 화면에서 내가 예치한 상품 중 확인을 원하는 상품을 선택하여 누적된 보상을 확인 하실 수 있습니다.`,
          },

          {
            question: "인출은 어떻게 하나요?",
            answer: `1. [지갑] 탭 - [Stake]에서 인출할 상품을 선택합니다.<br />
             2. 하단 Unstake 버튼을 선택 후, 인출 수량을 입력합니다.<br />
             3. PIN 번호를 인증하면 인출 요청이 완료됩니다.<br />
             <br />
             [중요]<br />
             • 각 상품별 Unstake 정책은 각 상품의 유의사항을 확인해주세요.<br />
             • 일부 상품의 인출은 예치된 전체 수량만 가능합니다. (KLAY, TRX에 해당)`,
          },
        ],
      },
      {
        topic: "Liquid Stake",
        title: "Liquid Stake",
        qna: [
          {
            question: "유동성 스테이킹 (Liquid Staking) 은 무엇인가요?",
            answer: `네오핀 팀이 오랜 블록체인 네트워크(노드) 운영 노하우를 바탕으로 제공하는 유동성 스테이킹 솔루션입니다. 자산을 스테이킹하면 그와 동일하거나 상응하는 가치의 npToken (staked token) 이 민팅 되어 제공됩니다. 스테이킹에 참여하는 도중에도 원 자산을 언스테이킹 할 필요없이 npToken들을 활용하여 다양한 디파이 상품들에 참여할 수 있습니다.`,
          },
          {
            question: "npToken(npETH, npKLAY)은 무엇인가요?",
            answer: `유동성 스테이킹 시, 스테이킹 된 ETH, KLAY 자산과 동일하거나 상응하는 가치의 npToken(staked token)이 민팅되어 지급되는 토큰입니다.`,
          },
          {
            question:
              "유동성 스테이킹과 지분증명 (PoS) 스테이킹의 차이점은 무엇인가요?",
            answer: `유동성 스테이킹에서는 스테이킹 된 자산과 동일하거나 상응하는 가치의 npToken(staked token)이 민팅되어 스테이킹 된 자산이 락업 되어있는 동안에도 npToken들을 활용해 디파이를 이용할 수 있습니다. 또한, 스테이킹 되어있는 자산의 보상이 자동으로 다시 스테이킹이 되어 복리 효과도 누릴 수 있습니다. PoS 스테이킹은 자산을 스테이킹하면 스테이킹 기간동안 락업이 되어 해당 자산에대한 활용이 불가능하며, 복리 효과를 누리기 위해서는 수동으로 보상을 수령하여 다시 스테이킹을 해야합니다.`,
          },
          {
            question: "유동성 스테이킹 보상은 어떻게 확인하나요?",
            answer:
              "스테이킹 보상은 교환비로 표시됩니다. 시간이 지날 수록 npToken의 가치가 증가할 것입니다.",
          },
          {
            question: "유동성 스테이킹 보상은 어떻게 수령할 수 있나요?",
            answer: `[ETH] <br />
              ETH 유동성 스테이킹 상품의 경우, 언스테이킹 요청 후 대기 시간이 지나고 언스테이킹이 완료 되었을 때 자산을 수령할 수 있습니다. 자산 수령 시 보상을 포함한 수량이 지급됩니다. 언스테이킹 대기 시간은 이더리움 네트워크의 혼잡도에 따라 달라질 수 있습니다.<br />
              <br />
              [KLAY]<br />
              KLAY 유동성 스테이킹 상품의 경우, 언스테이킹 요청시 7일 후 보상을 포함한 자산이 지갑으로 입금됩니다.
              `,
          },
          {
            question: "언스테이킹을 했는데 수령 가능한 보상이 없어요",
            answer: `네오핀은 탈중앙화된 CeDeFi 플랫폼으로서 직접 체인 네트워크에서 언스테이킹 요청이 처리됨에 따라 대기시간이 필요합니다. 언스테이킹 대기시간 종료 후 수령 가능한 자산을 확인할 수 있습니다. <br />
              *예상 대기시간은 홈 > Stake > 리워드 - 수령하기 버튼을 클릭하여 확인할 수 있습니다. 
              `,
          },
          {
            question: "유동성 스테이킹하고 받은 npToken들은 어디에 쓰이나요?",
            answer:
              "네오핀의 디파이 상품 중 거래 기능인 스왑에서 사용 가능하며 추후 다양한 디파이 프로토콜에서도 확장 지원 예정입니다.",
          },
          {
            question: "언스테이킹 할 때 대기 시간은 무엇인가요?",
            answer: `언스테이킹 시 대기 시간은 체인 네트워크에서 스테이킹된 자산을 언스테이킹하는데 필요한 시간입니다. <br />
              [KLAY]<br />
              KLAY의 언스테이킹은 클레이튼 네트워크 상 대기 시간이 7일로 정해져있습니다. <br />
              [ETH] <br />
              ETH의 언스테이킹 요청이 완료되면 이더리움 네트워크의 언스테이킹 대기열에 추가됩니다. 이는 네오핀이 관여할 수 없는 부분이며, 대기시간이 네트워크 상태에 따라 30일 이상 소요된 경우도 있습니다. 급하게 자산이 필요한 경우 네오핀의 스왑풀을 활용해 즉시 원 자산으로 교환할 수 있는 옵션도 있습니다. 
              `,
          },
          {
            question: "남은 대기시간은 어떻게 확인할 수 있나요?",
            answer:
              "홈 > Stake > 리워드 - 수령하기를 클릭하시면 해당 팝업창에 남은 대기 시간이 표시됩니다. 해당 대기 시간은 00:00 UTC 기준 24시간 주기로 업데이트 됩니다.",
          },
          {
            question: "즉시 언스테이킹은 무엇인가요?",
            answer:
              "즉시 언스테이킹은 해당 자산을 네오핀의 스왑 풀에서 스왑을 하여 대기 시간이 없이 인출이 됩니다. 다만, 언스테이킹 수량과 스왑풀의 유동성 컨디션에 따라 자산 가격에 영향이 발생할 수 있는 점 유의하시길 바랍니다.",
          },
          {
            question: "즉시 언스테이킹 했는데 자산 수량이 잘못 된 것 같아요.",
            answer:
              "즉시 언스테이킹은 해당 자산을 네오핀의 스왑 풀에서 스왑을 함으로 가격에 영향이 발생 되었을 가능성이 있습니다. 그 외 다른 문제라고 생각이 되신다면 help@neopin.io로 자세한 내용을 포함하여 이메일을 보내주십시오.",
          },
        ],
      },
      {
        topic: "블록체인",
        title: "Blockchain",
        qna: [
          {
            question: "네트워크 수수료를 지불해야 하는 이유는 무엇인가요?",
            answer: `모든 블록체인 네트워크를 사용하려면 네트워크 상의 트랜잭션을 처리하고 보안을 유지하기 위해 검증자는 끊임없이 계산 리소스를 지원합니다.<br />
             이들이 트랜잭션을 검증하고 확인할 수 있도록 인센티브를 지급하는데, 이것이 네크워크 수수료입니다. 즉, 블록체인 네트워크를 사용하기 위해 무조건적으로 발생하는 수수료입니다.<br />
             네트워크 수수료는 거래 중인 해당 블록체인 네트워크와 트래픽 사용량에 따라 달라집니다. DeFi 프로토콜에 의해 설정된 스마트 계약을 실행하기 위해서는 더 많은 컴퓨팅 파워가 필요합니다.<br />
             특히 스마트 계약 실행을 수반하는 특정 트랜잭션은 일반 전송보다 더 많은 비용이 들어가며, 이에 따라서 가스 제한이 더 많이 요구됩니다. 결과적으로 이는 더 높은 네트워크 수수료로 이어지게 됩니다.`,
          },

          {
            question: "네트워크 수수료는 어떻게 정해지나요?",
            answer: `네크워트 수수료는 블록체인별 합의 메커니즘에 따라 다르게 부과됩니다. 작업 증명 방식(Proof-of-Work)을 사용하는지, 지분 증명 방식(Proof-of-Stake)을 사용하는지 등에 따라서 영향을 받습니다.<br />
            그리고 많은 사용자가 동시에 거래하면서 네트워크의 혼잡도 및 트래픽에 따라 네트워크 요금 계산 방식은 달라집니다. 시장이 활황장이고 사용자들이 자산을 더 자주 이체하면 해당 가상자산의 네트워크 수수료가 크게 상승합니다.`,
          },

          {
            question: "트랜잭션이 완료되지 않아요.",
            answer: `블록체인 상의 네트워크 혼잡도에 따라 대기시간이 길어질 수 있습니다.<br />
             트랜잭션 완료까지 1시간 이상이 소요된다면 help@neopin.io로 문의해주시기 바랍니다.`,
          },
        ],
      },
      {
        topic: "일반",
        title: "Etc",
        qna: [
          {
            question: "알림이 오지 않아요. 어떻게 하나요?",
            answer: `• 네트워크 연결을 확인해주세요. 네트워크 환경이 원활하지 않은 경우에는 푸시 알림이 오지 않을 수 있습니다.<br />
             • 앱 내 [설정]탭 - 알림 기능이 활성화되어 있는지 확인해주세요.<br />
             • 기기 내 [설정] - [애플리케이션 관리] - [네오핀]에서 ‘알림 표시’를 활성화해주세요.`,
          },

          {
            question: "앱 사용 중 오류가 발생했어요. 어떻게 해결해야 하나요?",
            answer: `오류 발생 시 네오핀 고객센터(help@neopin.io)로 문의해주시기 바랍니다.`,
          },
        ],
      },
      {
        topic: "Approve",
        title: "Approve",
        qna: [
          {
            question: "Approve는 무엇인가요?",
            answer: `사용자님이 상품을 이용하려 할 시 상품의 컨트랙트가 사용자님의 자산에 접근할 수 있도록 접근 권한을 승인하는 절차를 Approve라 합니다.<br />
             Approve 절차는 사용자님의 자산을 안전하게 보호하기 위한 절차이며 최초 1회에 한해 권한 요청을 받고있습니다.<br />
             권한 승인 이후에는 상품을 바로 이용하실 수 있습니다.`,
          },

          {
            question: "Approve는 어떻게 이용하나요?",
            answer: `상품을 이용하시려는 경우 해당 상품의 컨트랙트가 사용자님의 자산에 접근이 불가능할 경우 사용자님에게 Approve 요청을 먼저 안내 드립니다.<br />
             제공되는 안내에 따라 Approve를 진행하시면 해당 상품을 이용하실 수 있습니다.<br />
             Approve는 상품 이용 시 최초 1회만 진행하며 이후에는 바로 이용 하실 수 있습니다.`,
          },
        ],
      },

      {
        topic: "스왑",
        title: "Swap",
        qna: [
          {
            question: "스왑은 무엇인가요?",
            answer: `네오핀의 스왑 서비스는 유동성 풀을 이용하여 토큰끼리 교환할수 있도록 제공하는 서비스입니다.<br />
             스왑을 위한 두 토큰의 교환 비율은 거래소에서 거래되는 시세가 아니라 유동성 풀의 교환 비율에 따라 계산되어 제공됩니다.<br />
             스왑 이용 시, 유동성 풀 이용에 따른 수수료가 요청됩니다.`,
          },

          {
            question: "스왑이 어떻게 이용하나요?",
            answer: `1. [스왑] 탭을 클릭하여 스왑 화면으로 이동합니다.<br />
             2. 'From'란에 토큰 아이콘을 클릭하여 교환할 토큰을 선택합니다.<br />
             3. 'To'란에 토큰 아이콘을 클릭하여 교환받을 토큰을 선택합니다.<br />
             4. 'From'란에 교환할 토큰의 양을 입력합니다.<br />
             5. 예상 내역 확인 후 '스왑' 버튼을 클릭합니다.<br />
             6. PIN 번호를 인증하면 교환 요청이 완료됩니다.`,
          },

          {
            question: "교환 비율은 무엇인가요?",
            answer: `스왑 거래는 예상 교환 비율에 따라 진행됩니다. 풀에 예치된 규모가 클수록 예상되는 교환 비율의 변동폭이 커져서 실제 교환되는 비율과 차이가 발생하게 됩니다.<br />
             스왑을 진행하기 전에 실제, 예상 교환 비율 간의 차이를 다시 한 번 확인하세요.`,
          },

          {
            question: "최소 스왑 가능 수량은 무엇인가요?",
            answer: `스왑을 통해 수령할 수 있는 최소한의 보장 수량 입니다. 스왑이 진행되는 동안 교환 비율이 실시간으로 계산되기 때문에 예상 교환비율과 실제 교환비율이 다를 수 있습니다.<br />
             최소 스왑 가능 수량은 이러한 차이가 발생하더라도 보장되는 최소 스왑 수량을 안내해드립니다.<br />
             단, 실제 스왑되는 수량이 안내된 최소 스왑 수량보다 적을 경우, 스왑이 취소됩니다.`,
          },

          {
            question: "수수료는 왜 발생하나요?",
            answer: `스왑은 유동성 풀을 이용하여 토큰끼리의 교환이 이뤄지는 서비스입니다. 이 때, 유동성 풀을 이용하는 수수료가 발생하고 이것이 '스왑 수수료'입니다.<br />
             해당 수수료는 유동성을 공급한 공급자들께 대부분 제공됩니다.`,
          },

          {
            question: "슬리피지란 무엇인가요?",
            answer: `여러 사용자가 동시다발적으로 스왑을 진행하면 유동성 풀의 교환 비율이 빠르게 변동하여 실제 지급 수량과 예상 수량 사이에 차이가 발생할 수 있습니다. 이 차이를 슬리피지라합니다.<br />
             스왑 화면에서 슬리피지 범위를 설정할 수 있으며, 슬리피지가 허용 범위를 초과하면 스왑이 취소됩니다.<br />
             <br />
             [중요]<br />
             • 슬리피지 설정 시 너무 낮거나 높은 수치를 적용할 경우에 거래가 진행 되지않거나 손실이 발생 할 수 있습니다.`,
          },

          {
            question: "교환 비율 그래프는 무엇인가요?",
            answer: `스왑 하려는 토큰의 교환 비율 변동을 쉽게 확인 하실 수 있도록 그래프로 변동 상황을 제공해 드리고 있습니다.<br />
             변동 상황은 하루 단위로 표시되며 주간, 월간 변화를 확인 하실 있습니다.`,
          },
        ],
      },
      {
        topic: "Pool",
        title: "Pool",
        qna: [
          {
            question: "Pool 상품은 무엇인가요?",
            answer: `Pool 상품은 AMM(Automated Market Maker) 모델을 기반으로 한 유동성 풀에 한 쌍의 토큰을 예치하여 네오핀(NEOPIN) 서비스에 유동성을 제공하고, 이에 대한 보상을 제공받는 투자상품 입니다.<br />
             유동성 풀에 예치한 비율에 따른 추가 NPT 보상과, 네오핀에서 발생하는 스왑 수수료의 일정 비율이 보상으로 포함됩니다.`,
          },

          {
            question: "Pool 상품은 어떻게 이용하나요?",
            answer: `1. 홈 화면 또는 [상품] 탭에서 원하는 Pool 상품을 선택합니다.<br />
             2. Pool 상품 정보를 확인한 후 [Deposit] 버튼을 클릭합니다.<br />
             3. 예치할 수량을 입력해주세요.<br />
                - Pool에 예치하려면 상품에 표시된 한 쌍의 토큰이 모두 필요합니다.<br />
                - 수량 입력 시, 한가지의 토큰 수량을 입력하면 나머지 토큰 수량은 자동으로 입력 됩니다.<br />
             4. 예상 내역을 확인한 후 [Deposit] 버튼을 클릭합니다.<br />
             5. PIN 번호를 인증하면 예치 요청이 완료됩니다.`,
          },

          {
            question: "연 예상 수익률은 어떻게 계산되나요?",
            answer: `Pool 상품의 연 예상 수익률은 체인 인플레이션에 의해 결정되며, 변경되는 정보는 Pool 상품 정보에 업데이트되어 표시됩니다.`,
          },

          {
            question: "보상은 언제부터 누적되나요?",
            answer: `Pool 상품은 예치를 시작하는 순간 부터 보상이 시작됩니다. 누적된 보상은 언제든지 수령이 가능합니다.<br />
             단, 수령 후 24시간이 지난 시점부터 다시 수령 신청이 가능합니다.`,
          },

          {
            question: "예치 보상은 어떻게 확인하나요?",
            answer: `[지갑]탭 - [Pool]에서 예치한 Pool 정보와 누적된 보상 금액을 확인 하실 수 있고, 보상 수령도 가능합니다.`,
          },

          {
            question: "인출은 어떻게 하나요?",
            answer: `1. [상품] 탭 - [List]에서 예치 중인 상품을 선택합니다.<br />
             2. [Deposit] 버튼을 선택하여 예치 화면으로 이동합니다.<br />
             3. 상단의 Withdraw 탭을 선택하여 인출 화면으로 이동합니다.<br />
             4. 인출할 수량을 입력 합니다.<br />
             5. 하단의 예상 내역을 확인 후, 인출 버튼을 선택합니다.<br />
             6. PIN 번호를 인증하면 인출 요청이 완료됩니다.<br />
             <br />
            [중요]<br />
             • 인출 시, 상품의 교환 비율에 따라 자본 손실이 발생 할 수도 있습니다.`,
          },
        ],
      },
      {
        topic: "지갑 연동",
        title: "WalletConnect",
        qna: [
          {
            question: "지갑 연동은 무엇인가요?",
            answer: `네오핀 웹 서비스는 탈 중앙 금융 시스템을 지원하여 별도의 회원 계정을 사용하지 않고 네오핀 블록체인 지갑을 통해 네오핀 서비스와 상호 작용을 진행합니다.<br />
             네오핀 앱과 지갑 연동 시 웹페이지에서도 네오핀 서비스를 이용하실 수 있습니다.`,
          },

          {
            question: "지갑 연동을 하고 싶어요",
            answer: `지갑 연동을 하시기 전에 네오핀 지갑을 먼저 생성해 주셔야 합니다.<br />
             <br />
             1. 네오핀 웹 우측 상단의 [Connect Wallet] 버튼을 선택하면 QR 코드가 화면에 표시 됩니다.<br />
             2. 네오핀 앱을 실행하여 홈화면 상단의 QR 스캔 버튼을 선택하거나 설정에서 [네오핀 지갑 연결]을 선택합니다.<br />
             3. QR 스캔 버튼이나 [지갑 연결 추가] 버튼을 선택 후 실행되는 카메라로 QR 코드를 스캔합니다.<br />
             4. 연결 확인 페이지에서 [Connect] 버튼을 선택하여 연결을 완료합니다.`,
          },

          {
            question: "승인 요청은 무엇인가요?",
            answer: `네오핀 웹에서는 별도의 사용자 계정을 지원하지 않습니다. 네오핀 웹에서 사용자의 자산을 전송하는 서비스를 이용하시는 경우, 네오핀 앱으로 승인 요청을 드립니다.<br />
             네오핀 앱에서 승인 요청이 표시되면, 본인이 요청하신 서비스인지 다시 한번 확인 후, 승인을 진행해 주시면 자산을 좀 더 안전하게 보호하실 수 있습니다.`,
          },

          {
            question: "지갑 연동을 중지하고 싶어요.",
            answer: `지갑 연동은 언제든 해제와 연결이 가능합니다.<br />
             <br />
             1. 네오핀 앱을 실행하여 [설정]에서 [네오핀 지갑 연결]을 선택합니다.<br />
             2. [연결된 서비스] 목록에서 네오핀 웹을 확인 후 선택합니다.<br />
             3. 연결 상세 화면에서 [지갑 연결 해제]를 선택하시면 연결이 해제됩니다.<br />
             <br />
             [중요]<br />
             - 공공장소 PC에서 지갑을 연동하신 경우 개인 자산의 정보가 유출되실 수 있으니 이용 종료 후 꼭 지갑 해제를 진행해 주시기 바랍니다.`,
          },
        ],
      },

      {
        topic: "네트워크 전환",
        title: "Change",
        qna: [
          {
            question: "네트워크 전환 기능은 무엇인가요?",
            answer: `네트워크 전환 기능은 코인 혹은 토큰이 여러 체인에서 사용될 수 있도록 네트워크 변경을 통해 서로 다른 체인을 연결해 주는 서비스입니다.<br />
             네오핀에서는 Kraytn > Polygon, Polygon > Kraytn 으로 자산의 네트워크를 변경 할 수 있도록 네트워크 전환 기능을 제공해 드리고 있습니다.<br />
             추후 업데이트로 전환 가능한 네트워크를 늘려나갈 계획입니다.`,
          },

          {
            question: "네트워크 전환은 어떻게 이용하나요?",
            answer: `네트워크 전환은 멀티 체인을 지원하는 자산만 가능합니다.<br />
             <br />
             1. [지갑] 탭을 클릭하여 지갑 잔고 화면으로 이동합니다.<br />
             2. 멀티 체인을 지원하는 자산을 클릭 합니다.<br />
             3. 네트워크 항목에 [Change] 버튼을 클릭합니다.<br />
             4. 좌측 네트워크 선택란에 전환할 네트워크를 선택합니다.<br />
             5. 우측 네트워크 선택란에 전환 받을 네트워크를 선택합니다.<br />
             6. 예상 내역 확인 후 '전환' 버튼을 클릭합니다.<br />
             7. PIN 번호를 인증하면 네트워크 전환 요청이 완료됩니다.`,
          },

          {
            question: "승인 요청은 무엇인가요?",
            answer: `네오핀 웹에서는 별도의 사용자 계정을 지원하지 않습니다. 네오핀 웹에서 사용자의 자산을 전송하는 서비스를 이용하시는 경우, 네오핀 앱으로 승인 요청을 드립니다.<br />
             네오핀 앱에서 승인 요청이 표시되면, 본인이 요청하신 서비스인지 다시 한번 확인 후, 승인을 진행해 주시면 자산을 좀 더 안전하게 보호하실 수 있습니다.`,
          },

          {
            question: "네트워크 전환시 토큰 변경도 가능한가요?",
            answer: `네트워크 전환은  동일 토큰의 네트워크만 변경하는 기능입니다. 멀티 체인을 지원하는 자산의 네트워크의 변경을 하는 기능이기 때문에 다른 자산과의 교환은 지원하지 않습니다.<br />
             네트워크 전환을 통해 이동된 자산은 스왑 기능을 통해 다른 자산으로 교환할 수 있습니다.`,
          },
        ],
      },
    ],
    en: [
      {
        topic: "Account",
        title: "Account",
        qna: [
          {
            question:
              "I have changed my mobile device. Can I still use my existing account?",
            answer: `Even if you have changed your mobile device, you may continue using the existing NEOPIN account as long as you know the exact recovery phrase. <br />
            After installing the NEOPIN app on a new device, sign in with the previous Google account, enter the Recovery Phrase, and complete OTP authentication. You may restore all data from your account, including your assets and subscribed services.`,
          },
          {
            question: "How do I delete my account?",
            answer: `• Users with no assets in NEOPIN Wallet: <br />
            1. Go to [Settings] - [App&Service Info] - tap [Delete my account]. <br />
            2. Type in ""I agree"" below after reviewing the Account Withdrawal Policy and Precautions. <br />
            3. You will be directed to the home screen once your account is successfully deleted. `,
          },
          {
            question: "Can I rejoin after deleting my account?",
            answer: `Once you delete your account, you will not be able to sign up again as all account information cannot be recovered. Please consider carefully before permanently deleting your account.`,
          },
        ],
      },
      {
        topic: "Authentication / security",
        title: "Authentication / security",
        qna: [
          {
            question: "What is a recovery phrase?",
            answer: `A Recovery Phrase is consisted of 24 words that allow you to store the private key for your virtual asset wallet.  <br />
            <br />
            Every virtual asset wallet created or owned by a user has its own private key. A Recovery Phrase is the master private key that allows you to access and manage your virtual assets. It is crucial for regaining access to your assets by importing your account from another device when your original device is permanently lost. <br/>
            <br />
            Please note the following:<br />
            ・ The 24 word Recovery Phrase must be copied and stored in a safe place or recorded separately.<br />
            ・ Do not share your Recovery Phrase with anyone, including NEOPIN employees at the Customer Service Center.`,
          },
          {
            question: "Where can I find my recovery phrase?",
            answer: `• If you do not have an account yet: You will view your Recovery Phrase as part of the procedure for account creation.<br />
            • If you already have an account: You may view your recovery phrase after entering the OTP code at [Settings] - [Security Settings] - [Check Recovery Phrase] tab.<br />
            <br/>
            [Important] <br />
            · Taking screenshots of the screen with the Recovery Phrase and sharing them with others or storing them in a place vulnerable for security breach is strictly prohibited. `,
          },
          {
            question: "How do I manage my recovery phrase?",
            answer: `NEOPIN does not store any Recovery Phrase or private key-related information. You must manage your own Recovery Phrase as it is created and encrypted on your device. Please make sure you store the issued Recovery Rhrase safely.`,
          },
          {
            question:
              "Is it possible to use NEOPIN's recovery phrase in other wallet services?",
            answer: `The Recovery Phrase created on NEOPIN is only available for NEOPIN services and are not compatible with other wallet services.`,
          },
          {
            question: "How do I enable OTP?",
            answer: `1. Install Google Authenticator on Google Play Store or the App Store. <br />
            2. Register the generated setup key or QR code on Google Authenticator to add an account, then click [Next].<br />
            3. Enter the activated OTP code on the OTP authentication screen.<br />
            <br />
            [Important] <br />
            · Please make sure you make a copy of your setup key and store it in a safe place. Your setup key is essential for OTP re-registration.`,
          },
          {
            question: "I lost my OTP code. Can I reset my OTP settings?",
            answer: `1. Go to [Settings] tab - [Security Setting] - [Reset OTP]. <br />
            2. Entering the Recovery Phrase will issue a new setup key.<br />
            3. Register the issued setup key on the OTP app and activate the OTP code. <br />
            <br />
            [Important] <br />
            · Once the OTP is reset, the existing setup key and the OTP code can no longer be used.`,
          },
          {
            question: "What is KYC?",
            answer: `KYC verification is a legal procedure to comply with Know Your Customer (KYC), Anti-money Laundering (AML), and Combating the Financing of Terrorism (CFT) regulations. The submitted document will only be used for KYC verification purposes.`,
          },
          {
            question: "Why was my KYC verfication was rejected?",
            answer: `Please make sure you meet all the requirements below for KYC verification when submitting for the review. <br />
            <br />
            · All documents submitted must be original rather than copies.  <br />
            ・ Submitted materials must be clearly visible for identification. <br />
            · In the case of an identification document with a specified expiration date, the validity period must not have passed. <br />
            <br />
            Please check the above before resubmitting documents for verification. `,
          },
          {
            question: "How long does the KYC verification process take?",
            answer: `KYC verification can take up to 2-3 days, and the result will be notified via email or a push notification.`,
          },
          {
            question: "[App Lock] I want to set a passcode to my App.",
            answer: `1. Go to [Settings] tab - [Security Settings] - [Lock app]. <br />
            2. Enter a 6-digit number to be used as a passcode and the NEOPIN app lock will be activated. <br />
            <br />
            [Tip] <br />
            · Registering your biometric authentication information makes it easier to unlock the app.`,
          },
          {
            question: "[App Lock] I want to change the passcode.",
            answer: `With the app lock enabled, you may change the passcode by going to [Settings] tab - [Security Settings] - [Reset App Lock Passcode]`,
          },
          {
            question: "[App Lock] I`ve lost my passcode, Can I reset it?",
            answer: `1. Tap [Forgot the passcode?]. <br />
            2. Entering the OTP code initializes the passcode and allows you to set a new passcode.`,
          },
        ],
      },
      {
        topic: "Wallet",
        title: "Wallet",
        qna: [
          {
            question:
              "My wallet balance and transaction history is not showing.",
            answer: `Occasionally, an incorrect wallet balance may be shown or the transaction history may not be displayed on NEOPIN due to network traffic or congestion.<br />
             <br />
             NEOPIN App merely shows the virtual asset balance and provides UI for users to easily manage cryptocurrency, but does not hold your assets. Your assets are safe unless your Recovery Phrase is lost.<br />
             <br />
             For reference, you may refer to the latest balance and transaction information in the following blockchain navigation link.<br />
             ETH & ERC-20 Token: https://etherscan.io/<br />
             BTC/LTC/XRP/XLM: https://blockchair.com/<br />
             ATOM: https://www.mintscan.io/cosmos<br />
             NPT : https://scope.klaytn.com/`,
          },

          {
            question: "The displayed price is different from the market price.",
            answer: `The displayed price is the estimated value based on Coinmarketcap or Binance market prices.<br />
             Therefore, when exchanging for real currency, there may be a difference from the actual amount received depending on the market price of each exchange.`,
          },

          {
            question: "How do I deposit to my wallet?",
            answer: `1. Tap [Add Token] at the top of the home screen or go to the [Wallet] tab.<br />
             2. Select a token to create a deposit address and the deposit address will be issued.<br />
             3. If you send tokens to the address, the tokens will be transferred to your wallet after the transaction is completed.<br />
             <br />
             [Important]<br />
             • Please be sure to confirm the address before sending. Loss caused by sending to a wrong address cannot be recovered for any reason.<br />
             • Depositing a token that is different from the issued address could result in the loss of the asset.`,
          },

          {
            question: "How do I send tokens?",
            answer: `1. On the [Wallet] tab, select the token to be transferred.<br />
             2. Enter the address to send or scan the address QR code to register the receiving address.<br />
             3. Enter the quantity to be sent.<br />
             4. After confirming the expected transfer details, verify the OTP code and the transfer request is completed.<br />
             <br />
            [important]<br />
            • Be sure to check the address and token type to be transferred before sending. Any loss caused by sending to a wrong address cannot be recovered for any reason.<br />
            • You cannot send tokens to your address.`,
          },

          {
            question: "How do I register an address?",
            answer: `Addresses used previously to send tokens are automatically saved in the address book.<br />
             Frequently used addresses can be used faster and more convenient by setting them as favorites or by registering a nickname.<br />
             <br />
             • How to use the address book<br />
             1. Click [Address Book] icon next to the 'To (address)'.<br />
             2. Select the address to send.<br />
             3. Ensure that the address book information is entered correctly next to the [Address Book] icon.`,
          },

          {
            question: "What is fee edit?",
            answer: `When transactions occur on networks like Ethereum or Polygon, transaction fees (known as gas costs) must be paid.<br />
             The fee can be adjusted based on the desired transaction time, with shorter transaction times requiring higher fees (Note: Fee settings may vary depending on network congestion and conditions).<br />
             <br />
             [How to set fees]<br />
             1. Click [Settings] next to 'Set Gas fee' in Estimated Summary.<br />
             2. [Set gas fee] window is created.<br />
             3. Select the desired gas fee setting value and click the 'Confirm' button to apply the fee.`,
          },

          {
            question:
              "Why is the actual quantity deposited different from the input quantity?",
            answer: `When transferring virtual assets through a blockchain network, network fees are incurred.<br />
             Fees are deducted directly from the amount entered at the time of transmission, so there may be a difference between the amount entered and the actual amount deposited.<br />
             Network fees can be viewed on the transaction details screen.`,
          },

          {
            question:
              "I requested a transfer but the tokens have not been deposited yet.",
            answer: `After the transfer request, the balance will not be deposited until the transfer is complete.<br />
             The transfer details are reflected in the balance after being approved by the blockchain network, and the transaction history is marked as 'pending'.<br />
             Authorization usually takes a few minutes, but depending on network congestion, latency may be longer.<br />
             PUSH notification will be sent when the transaction is completed.<br />
             (If it takes more than an hour, please contact help@neopin.io)`,
          },

          {
            question: "Can I trade different tokens(coins)?",
            answer: `Currently, NEOPIN Wallet supports a total of 19 tokens (coins), including NPT, ETH, KLAY, TRX, S2T, BVT, MBX, BORA, SSX, IPV, WIKEN, BBC, ATT, CLBK, MIX, MON, NGIT, OBSR , WEMIX.<br />
             We plan to increase the number of supported tokens through future updates.<br />
             <br />
             Please note that tokens not supported by NEOPIN cannot be deposited or transferred.`,
          },

          {
            question: "Why is NPT shown separately?",
            answer: `NPT is a token supported on multiple chains, and is currently supported on the Klaytn and Polygon chains.<br />
             Since token assets can be stored on a per-chain basis, we have separated them for individual management.<br />
             <br />
             We are planning to increase the number of networks supported in future updates.`,
          },

          {
            question:
              "Why is it necessary to choose a network during transfers?",
            answer: `Tokens that are supported on multiple chains require network choice because transfers are carried out on a network-by-network basis.<br />
             Transfers must occur on the same network as the network of the destination address.<br />
             If the network of the destination address is not included in the list of networks supported by NEOPIN, your assets may be lost.<br />
             Assets sent to the wrong network cannot be recovered, so please make sure to check the network before sending.`,
          },
        ],
      },
      {
        topic: "Reward",
        title: "Reward",
        qna: [
          {
            question: "Where can I check my product?",
            answer: `You may find the list of services that you have participated in and its details in the [Wallet] tab - [Stake] or [Pool].`,
          },

          {
            question: "How do I claim rewards?",
            answer: `- For Stake / Event / Other rewards<br />
             1. Go to [Wallet] - [Stake] - [Claimable Rewards].<br />
             2. Select the token you wish to claim and tap [Claim rewards].<br />
             3. Authenticate your PIN code.<br />
             4. Once transaction is completed, rewards will be transferred to your wallet.<br />
             • If there are no Claimable Rewards, the [Claim] button will not be activated.<br />
             <br />
             - For Pool<br />
             1. Go to [Wallet] tab - [Pool] and tap [Claim].<br />
             2. Select the token you wish to claim and tap [Claim rewards].<br />
             3. Authenticate your PIN code.<br />
             4. Once transaction is completed, rewards will be transferred to your wallet.<br />
             • For Pool products, you can only claim rewards once within 24 hours.`,
          },

          {
            question: "How do I save on reward fees?",
            answer: `Rewards are accumulated for each token and deposited into the wallet for each token whenever claimed.<br />
             The longer the claim request cycle, the fewer transaction requests, and lesser fees.`,
          },

          {
            question:
              "I can view accumulated rewards but cannot receive them. What do I do?",
            answer: `• Insufficient Fee:<br />
             If your wallet balance is less than the transaction fee, you can not receive rewards.<br />
             Please check if you have enough assets in your address and claim rewards again.<br />
             <br />
             • When its at the [In progress] tab:<br />
             There may be a time limit or waiting time to receive reward for each product depending on the network.<br />
             Claim rewards again after the indicated waiting time.<br />
             <br />
             If the above instructions cannot help with your issues, please contact help@neopin.io.`,
          },
        ],
      },
      {
        topic: "Stake",
        title: "Stake",
        qna: [
          {
            question: "How may I stake assets?",
            answer: `1. Choose any Staking service shown on the home screen or the [Earn] tab.<br />
             2. Tap [Stake Now] after reviewing service info and Terms of Use.<br />
             3. Enter the amount you wish to stake.<br /> 
             4. Review estimated rewards and complete PIN code authentication to stake your assets.`,
          },

          {
            question: "How is the estimated APR calculated?",
            answer: `As Staking APR is determined by the chain inflation, updated APR is shown in the service info for each staking service.<br />
             The estimated annual interest rate is shown as APR(simple interest). To earn APY(compound interest), APR rewards should also be staked.`,
          },

          {
            question: "When do rewards start accumulating?",
            answer: `The calculation of Staking rewards is different for each virtual asset.<br />
             <br />
             -For NPT<br />
             As soon as you start staking, your rewards will start accumulating.<br />
             <br />
             - For KLAY and TRX<br />
             The reward is calculated based on the APR of the day, 24 hours after the point of initial staking.<br />
             For example, if you start staking at 2:00 PM on January 1, the rewards will start to be calculated from 2:00 PM on January 2.`,
          },

          {
            question: "Where do I check my Staking rewards?",
            answer: `You may view your staked assets and accumulated rewards for each staking service in the [Wallet] tab - [Stake] in your wallet.`,
          },

          {
            question: "How may I unstake my assets?",
            answer: `1. Go to [Wallet] - [Stake] in your wallet and select the asset you wish to unstake.<br />
             2. Tap [Unstake] at the bottom of the screen and enter the amount you wish to unstake.<br /> 
             3. Authenticate your PIN code to unstake your assets.<br />
             <br />
             [Note]<br />
             • Please review the unstaking policies in the Terms of Use.<br />
             • Certain products only allow you to unstake the total amount of assets staked. (i.e. KLAY, TRX)`,
          },
        ],
      },
      {
        topic: "Liquid Stake",
        title: "Liquid Stake",
        qna: [
          {
            question: "What is Liquid Staking?",
            answer: `It is a Liquidity Staking protocol developed by the NEOPIN team based on years of experience in blockchain node operations. When assets are staked, an equal or equivalent npToken (staked token) is minted and given to users. With npTokens, users can explore and enjoy other various DeFi products without having to unstake the original assets.`,
          },
          {
            question: "What is npToken(npETH, npKLAY)?",
            answer: `For Liquid Staking protocol, when assets are staked, an equal or equivalent npToken (staked token) is minted and given to users.`,
          },
          {
            question:
              "What is the difference between Liquid Staking and PoS Staking?",
            answer: `When assets are staked through the Liquid Staking protocol, an equal or equivalent npToken (staked token) is minted and given to users. While the staked asset is locked up, users can utilize the npToken by using DeFi protocols. In addition, the rewards are automatically staked, resulting in a compounding yield. <br /><br />With PoS staking protocols, when assets are staked, they are locked up and cannot be used. In addition, users have to manually claim the reward and re-stake them for a compounding yield.`,
          },
          {
            question: "How can I check the rewards of Liquid Staking?",
            answer: `The rewards are displayed as an exchange rate between the npToken and the staked asset. The value of npToken will increase over time.`,
          },
          {
            question: "How do I claim rewards from liquid staking?",
            answer: `[ETH]<br />For the ETH Liquid Staking, assets will be available to claim after the waiting time of unstaking. The claimable assets consist of unstaked amounts and rewards. The waiting time of unstaking may vary due to Ethereum network’s traffic conditions.<br /><br />[KLAY]<br />For KLAY Liquid Staking, the assets with rewards will be deposited to the user's wallet after 7 days of waiting time upon the unstaking request.`,
          },
          {
            question:
              "I have unstaked but I do not see any rewards available to claim",
            answer: `In NEOPIN, a decentralized CeDeFi platform, the waiting time is required as unstaking requests are made directly on-chain blockchain networks. The unstaking status will be updated after the waiting time of blockchain networks. <br />*Estimated waiting time can be found at Home > Stake > Reward - Claim(click).`,
          },
          {
            question: "Where can npToken be utilized?",
            answer: `It can be used in NEOPIN’s DeFi products such as exchange feature Swap and it will be extended to other various DeFi protocols in the future.`,
          },
          {
            question: "What is the waiting time for unstaking?",
            answer: `Waiting time is a period of time required to unstake the staked assets on the network chain. <br />[KLAY]<br />KLAY’s waiting time is set to 7 days<br /><br />[ETH]<br />Once the unstake request of staked ETH is processed, the request will be queued in the ETH unstaking queue of the Ethereum network. NEOPIN cannot intervene in this queue, and there has been a case in the past where it took longer than 30 days to complete an unstaking request. If you need your staked asset urgently, you can exchange it to the original assets immediately by using the Swap feature.`,
          },
          {
            question: "How can I check the remaining Waiting time?",
            answer: `Go to Home > Stake > Reward - Claim (click)<br />A list of rewards pops up and shows the estimated waiting time of unstaked assets. The estimated waiting time is updated at 00:00 UTC every 24 hours.`,
          },
          {
            question: "What is immediate unstake?",
            answer: `Immediate unstake is unstaking without waiting time via swapping npTokens in NEOPIN’s Swap Pool. Please keep in mind that the price impact may occur depending on the unstaking amount and the Swap Pool’s liquidity conditions.`,
          },
          {
            question: "Asset amount seems wrong after immediate unstaking",
            answer: `A price impact may have been high if the assets were unstaked immediately. If you think it is due to another issue, please contact help@neopin.io with details included.`,
          },
        ],
      },
      {
        topic: "Blockchain",
        title: "Blockchain",
        qna: [
          {
            question: "Why do I have to pay a network fee?",
            answer: `To use any blockchain network, validators constantly support computational resources to process and secure transactions on the network.<br />
             Incentives are paid to enable them to validate transactions, which are unconditional network fees for using the blockchain network.<br />
             <br />
             Network fees vary depending on the corresponding blockchain network being transacted and traffic usage.<br />
             More computing power is required to execute the smart contract established by the DeFi protocol.<br />
             In particular, certain transactions that involve smart contract execution cost more than regular transfers, and therefore require more gas limits.<br />
             As a result, this leads to higher network fees.`,
          },

          {
            question: "How is the network fee calculated?",
            answer: `Network fees are charged differently depending on the consensus mechanism for each blockchain, such as Proof-of-Work or Proof-of-Stake, etc.<br />
            <br />
            With many users transacting at the same time, the method of calculating network charges will vary depending on the congestion and traffic of the network.<br />
            If the market is booming and users transfer assets more frequently, the network fees for those virtual assets will rise significantly.`,
          },

          {
            question: "The transcation is not being completed.",
            answer: `Depending on the network congestion on the blockchain, the latency may be extended.<br /> 
             If the transaction takes more than an hour to complete, please contact help@neopin.io.`,
          },
        ],
      },
      {
        topic: "Etc",
        title: "Etc",
        qna: [
          {
            question: "I don't get any PUSH notifications. What shall I do?",
            answer: `• Please ensure that you have a stable internet connection. Notifications may not be pushed under unstable internet connection.<br />
             • Check App settings at [Setting] tab - [Notification] : Ensure that Notification switches are turned on.<br />
             • Check Device settings: Settings > neopin > Notifications > Allow Notifications On.`,
          },

          {
            question:
              "An error occurred while using the app, How can I solve it?",
            answer: `In case of an error, please contact NEOPIN Customer Center (help@neopin.io).`,
          },
        ],
      },
      {
        topic: "Approve",
        title: "Approve",
        qna: [
          {
            question: "What is Approve?",
            answer: `When you want to use a product that requires access to your assets, you need to go through the Approve procedure to grant permission to the product's contract.<br />
             The purpose of this procedure is to protect your assets, and you only need to grant permission once.`,
          },

          {
            question: "How do I use Approve?",
            answer: `To use Approve, you simply need to follow the instructions provided when prompted to approve access.<br /> 
             Once you complete the procedure, you can use the product right away.<br /> 
             After the initial approval, you won't need to go through the process again when using the same product.`,
          },
        ],
      },
      {
        topic: "Swap",
        title: "Swap",
        qna: [
          {
            question: "What is Swap?",
            answer: `NEOPIN Swap is a service that allows tokens to be exchanged using a liquidity pool.<br />
             The exchange rate of two tokens for the Swap is calculated and provided based on the exchange rate of the liquidity pool, not the market price traded on the exchange.<br />
             When using the Swap, fees may occur for using the liquidity pool.`,
          },

          {
            question: "How do I swap assets?",
            answer: `1. Go to [Swap].<br />
             2. Click the token icon in the 'From' column to select the token to exchange.<br />
             3. Click the token icon in the 'To' column to select the token to be exchanged.<br />
             4. Enter the amount of tokens to be exchanged in the ‘From’ column.<br /> 
             5. Review the expected details below and tap [Swap].<br />
             6. Authenticate your PIN code to swap your assets.`,
          },

          {
            question: "What is the Swap ratio?",
            answer: `Swap transactions are based on the estimated exchange rate.<br />
             The larger the amount deposited in the pool, the greater the fluctuation of the estimated exchange rate, resulting in a difference from the actual exchange rate.<br />
             Please double check the difference between the actual and esimated exchange rates before proceeding with the swap.`,
          },

          {
            question: "What is the minimum Swap quantity?",
            answer: `The minimum Swap quantity is the minimum guaranteed quantity that can be received through a Swap.<br />
             As the exchange rate is calculated in real time while the Swap is in progress, the estimated exchange rate and the actual exchange rate may differ.<br />
             The minimum Swap quantity will guide you to the guaranteed minimum Swap quantity, even in the event of such a difference.<br />
             However, if the actual Swap amount is less than the guided minimum Swap amount, the Swap will be cancelled.`,
          },

          {
            question: "Why do fees occur?",
            answer: `Swap is a service in which tokens are exchanged using a liquidity pool.<br />
             A swap fee for using the liquidity pool is incurred, which is mostly distributed to liquidity providers.`,
          },

          {
            question: "What is slippage?",
            answer: `When multiple users swap simultaneously, the exchange rate in the liquidity pool fluctuates rapidly, resulting in a discrepancy between the actual and estimated quantity paid out.
             This difference is called ‘slippage’. <br />
             <br />
             You can set the slippage range on the swap screen and if the slippage exceeds the allowable range, the swap will be cancelled. <br />
             <br />
             [Note]<br />
             If the applied value is too high or too low when setting slippage, the transaction may not proceed or a loss may occur.`,
          },

          {
            question: "What is the Swap ratio graph?",
            answer: `The Swap ratio graph is provided to make it easier to check the exchange rate fluctuations of tokens.<br />
             Changes are displayed on a daily basis, and weekly and monthly changes can also be identified.`,
          },
        ],
      },
      {
        topic: "Pool",
        title: "Pool",
        qna: [
          {
            question: "What is Pool? ",
            answer: `Pool is a service that allows users to provide liquidity to NEOPIN by depositing a pair of tokens into a liquidity pool developed based on the Automated Market Maker (AMM) model, and rewards the users in return.<br />
             Users may earn additional NPT rewards proportionate to the amount deposited in the pool and a certain share of swap fees generated on NEOPIN.`,
          },

          {
            question: "How do I use Pool?",
            answer: `1. Tap [Earn] on the home screen to select the desired Pool service.<br />
             2. Review the information on the Pool and tap [Deposit].<br />
             3. Enter the amount you wish to deposit.<br />
                - You need a pair of tokens displayed to deposit in Pool.<br />
                - When you enter the amount for one token, the amount for the other token will be automatically entered.<br />
             4. Review expected rewards and tap [Deposit] at the bottom of the screen.<br />
             5. Authenticate your PIN code to deposit your assets.`,
          },

          {
            question: "How is the estimated APR calculated?",
            answer: `The estimated APR for Pool is determined by the chain inflation, and the APR will be updated and displayed in the service info for each Pool.`,
          },

          {
            question: "When do rewards start accumulating?",
            answer: `Pool rewards start accumulating from the moment you deposit into a pool.<br />
             Accumulated rewards can be claimed at any time, while the next reward claim is only available 24 hours after the previous claim.`,
          },

          {
            question: "Where do I check my Pool Rewards?",
            answer: `Go to [Wallet] - [Pool] tab in your wallet for your Pool information and accumulated rewards.<br />
             You may also claim rewards on the same screen.`,
          },

          {
            question: "How may I withdraw my assets from a pool?",
            answer: `1. Tap [Earn] and select the pool you have deposited on the [List].<br />
             2. Tap [Deposit] to go to the deposit screen.<br />
             3. Select [Withdraw] tab at the top of the screen.<br />
             4. Enter the amount to withdraw.<br /> 
             5. Review the expected rewards and tap [Withdraw] at the bottom of the screen to withdraw your assets.<br /> 
             6. Authenticate your PIN code to withdraw your assets.<br />
             <br />
             [Note]<br />
             • Asset loss may occur depending on the exchange rate of each pool at the point of withdrawal.`,
          },
        ],
      },
      {
        topic: "WalletConnect",
        title: "WalletConnect",
        qna: [
          {
            question: "What is WalletConnect?",
            answer: `NEOPIN Web supports a decentralized financial system that uses NEOPIN Wallet to connect NEOPIN App and Web services without having to create an additional account.<br />
             You may use NEOPIN Web services once you connect your app and your wallet.`,
          },

          {
            question: "How do I connect my wallet to the web? ",
            answer: `You must create a NEOPIN Wallet before connecting it to the web.<br />
             <br />
             1. Select [Connect Wallet] in the upper right corner of NEOPIN Web for a QR code.<br />
             2. Open NEOPIN App and tap QR Scan at the top of the home screen or tap [Settings] - [NEOPIN Wallet Connect] - [Connect Wallet].<br />
             3. Scan the QR code on the web with the camera that runs on NEOPIN App.<br />
             4. Tap [Connect] on the confirmation page to connect your wallet to the web.<br />`,
          },

          {
            question: "Why is a approval request needed? ",
            answer: `NEOPIN Web does not support additional user accounts. An approval request will be sent to the app when transferring users’ assets from the web.<br />
             <br />
             When the approval request is displayed on the NEOPIN App, please double-check if the service is what you requested and proceed with the approval for the purpose of asset protection.`,
          },

          {
            question: "How may I disconnect my wallet from the web? ",
            answer: `You may disconnect or connect your wallet to the web anytime.<br />
             <br />
             1. Open NEOPIN App and go to [Settings] - [NEOPIN Wallet Connect].<br />
             2. Select NEOPIN Web on the list of connected services.<br />
             3. Select [Disconnect Wallet] on the screen.<br />
             <br />
            [Note]<br />
            • If you have connected your NEOPIN Wallet to the web using a public PC, please make sure to disconnect your wallet after use as your personal information may be exposed with a security breach.`,
          },
        ],
      },
      {
        topic: "Change",
        title: "Change",
        qna: [
          {
            question: "What is the Change feature?",
            answer: `Change is a feature supported by NEOPIN that allows coins or tokens to be used across different chains by changing networks.<br />
             NEOPIN currently offers network changing between Klaytn and Polygon (both to and from).<br /> 
             <br />
             We plan to support more networks in future updates.`,
          },

          {
            question: "How do I use Change?",
            answer: `Change is only supported for assets that are supported on multiple chains.<br />
             <br />
             1. Go to the balance in [Wallet].<br />
             2. Click on an asset that is supported on multiple chains.<br />
             3. Click on the [Change] button in the network category.<br />
             4. Select the network you want to switch from in the network selection box on the left.<br />
             5. Select the network you want to switch to in the network selection box on the right.<br />
             6. Check the expected summary and click the 'Change' button.<br />
             7. Authenticate your PIN code to complete the network change request.`,
          },
          {
            question: "Is it possible to exchange tokens during Change?",
            answer: `Please note that network switching only allows you to change the network of the same token, and it does not support exchanging assets with other tokens.<br />
             You can exchange assets for other tokens through the Swap feature.`,
          },
        ],
      },
    ],
  };
};
