/* eslint-disable import/no-anonymous-default-export */
export const verifyVASP220316 = {
  ko: {
    subject:
      "[파트너십] 네오핀, ‘베리파이바스프’와 트래블룰 솔루션 사용 업무 협약 체결",
    body: `안녕하세요.
블록체인 오픈플랫폼 네오핀입니다.

블록체인 전문 기업 네오플라이의 자회사인 네오핀은 16일 ‘베리파이바스프(Verify VASP)’와 트래블룰(자금이동규칙, Travel Rule) 솔루션 사용에 대한 업무 협약을 체결했습니다.

네오핀은 추후 ‘베리파이바스프’ 트래블룰 솔루션을 도입하고 연동할 계획이며, 솔루션의 고도화와 활성화에도 적극 참여할 예정입니다. ‘베리파이바스프’ 솔루션 도입을 완료한 이후에는 주요 거래소와 네오핀 지갑 간의 자금 이동이 보다 수월해질 것으로 기대하고 있습니다. 

     `,
  },
  en: {
    subject:
      "[Partnership] NEOPIN signs MOU with “Verify VASP” for Travel Rule Solution",
    body: `Hi, we are NEOPIN, an open blockchain platform for all.

NEOPIN, a subsidiary of a blockchain company NEOPLY, has announced on the 16th of March that it has signed an MOU with “Verify VASP” for Travel Rule Solution.

NEOPIN plans to incorporate the Travel Rule Solution provided by “Verify VASP” and actively participate in the advancement of the solution. A successful incorporation of the solution into the NEOPIN platform will facilitate the movement of funds between major exchanges and the NEOPIN Wallet.

    `,
  },
};
