export const serviceUpdate230626 = {
  ko: {
    subject: "[공지] 서비스 업데이트에 따른 점검 사전 안내",
    body: `네오핀은 서비스 및 상품의 확장과 사용자 편의성 개선을 위해 업데이트를 진행할 예정입니다.
    업데이트 시간 동안 점검을 진행하여 서비스가 중단될 예정이오니, 자산 예치 및 인출이 필요하신 경우 참고해주시기 바랍니다.
    
    [ 점검 일시 ]
    - 2023년 6월 27일(화) 13:00 ~ 18:00(KST)
    * 점검 시간은 상황에 따라 변동될 수 있습니다.
    
    [ 업데이트 내용 ]
    1. 유동성 Stake 상품 추가
    유동성 Stake 상품에 KLAY를 스테이킹하면 지분증명 토큰인 npKLAY를 획득하고, 이 npKLAY를 다른 DeFi 상품에서 활용할 수 있습니다.
    Klaytn을 시작으로 Ethereum 등으로 확장될 예정입니다.
    
    2. 멤버십 프로그램
    네오핀 상품의 이용에 따라 다양한 혜택이 제공되는 멤버십 프로그램이 시즌제로 운영됩니다.
    유동성 Stake, 풀 예치, 스왑 등의 상품을 이용하고 멤버십 포인트를 획득하세요!

    3. 친구 초대 이벤트
    친구를 초대하면 초대받는 친구와 함께 포인트를 획득 받는 친구 초대 이벤트가 시작됩니다.
    친구들을 네오핀에 초대하고, 친구들의 이용에 따라 포인트를 획득하세요!
    
    4. 기능 개선
    Ethereum과 Polygon 네트워크간의 브릿지 지원 등 기능이 추가되고 편의성이 개선되었습니다.
    
    항상 네오핀을 이용해주셔서 감사합니다. 
    
    `,
  },
  en: {
    subject: "[Maintenance] Service Update and Maintenance",
    body: `NEOPIN will be undergoing maintenance to enhance products and improve user experience.
    Please note that service will be temporarily suspended during the maintenance period. If you need to deposit or withdraw funds, please check the date and time below and plan accordingly.

    [ Schedule ]
    2023/06/27(Thu.) 04:00 ~ 09:00(UTC)
    * Subject to change based on circumstances.
    
    [ Updates ]
    1. Liquid Staking Product
    By staking KLAY in the Liquid Staking product, users can earn npKLAY, a proof-of-stake token, which can be utilized in other DeFi products.
    The Liquid Staking product is planned to expand beyond Klaytn to Ethereum and other networks.
    
    2. Membership Program
    A seasonal membership program will be launched, offering various benefits based on the usage of NEOPIN products.
    Earn the membership points by using DeFi products such as Liquid Staking, PoS Staking, Pool Deposit, Swaps and Bridge!
    
    3. Friend Referral Event
    A friend referral event will be launched, where both the referrer and the referred friend can earn points together.
    Invite your friends to NEOPIN and earn points based on their protocol usage!
    
    4. Functionality Improvements
    Additional features and enhancements have been implemented, including support for bridging between Ethereum and Polygon networks.
    
    Thank you for your continuous support.
    
    `,
  },
};
