export const listingKlapEnd230512 = {
  en: {
    subject: "[Notice] Termination of the KLAP product service",
    body: `Please be informed that the services for KLAP-related products, including swap and liquidity provision rewards, have officially ended as of May 18th, 2023 UTC.
    Please refer to your investment and asset management.
    
    [ Subject to service termination ]
    - KLAP-NPT Pool
    - KLAP-NPT Swap
    
    [ Service Termination Schedule ]
    - 06:00:00 on May 18th, 2023 UTC
    
    [ Details ]
      (1) KLAP-NPT Pool: Unable to deposit new pool, suspension of reward payment, and continue to provide pool withdrawal feature
      (2) KLAP Swap Not Supported
    
    Thank you for your continuous support for NEOPIN.
    
    `,
  },
};
