export const airdrop3spaceArt221221 = {
  ko: {
    subject: "[소식] 네오핀 X 세번째공간(3space Art) NFT 에어드랍 이벤트 안내",
    body: `안녕하세요, 네오핀입니다.

    네오핀이 NFT 지갑 출시와 세번째공간(3space Art)의 온보딩을 기념하여 주목받는 신진작가 ‘AVANT CLASSIC’의 NFT 에어드랍을 선보입니다.
    
    아래 내용을 참고하셔서, 실험적인 아트 오브제, ‘구미나이트베어’ NFT 에어드랍에 참여해 보세요!
    
    [AVANT CLASSIC 에어드랍에 참여해야 하는 이유!]
    주목받는 신진작가, AVANT CLASSIC의 실험적인 프로젝트, ‘아반트 스태츄’.
    서울에서 주얼리 디자이너로 활동 중인 AVANT CLASSIC의 새로운 아트 오브제를 위한 실험적 프로젝트!
    우리 삶의 여러 모습들을 다양한 컨셉으로 풀어낸 ‘아반트 스태츄 구미 나이트베어’ NFT를 만나보세요!
    
    [네오핀 X 세번째공간 NFT 에어드랍 이벤트]
    - 이벤트 기간: 2022.12.21(수) 17:00 ~ 28(수) 16:59 [KST]
    - 당첨자 발표: 2023.1.5(목) 세번째공간 SNS
    - 참여 방법: SNS 참여 미션 수행 & 네오핀 회원가입 KYC 인증 <a href="https://docs.google.com/forms/d/e/1FAIpQLSd_RxR8XD-XHdoa0C17uIozy9ku3ePcuR8dn_6BOKWWKkogRw/viewform">(구글폼 바로가기)</a>
    - 에어드랍: 총 132만 원 상당의 AVANT CLASSIC 구미나이트베어 NFT 4종
    ① <a href="https://3space.art/art/871">Plants vs Nightbear</a>
    ② <a href="https://3space.art/art/870">Freddy Nightbear</a>
    ③ <a href="https://3space.art/art/813">Coffee Addict Nightbear</a>
    ④ <a href="https://3space.art/art/812">COVID 19 Nightbear</a>
    
    ※ KYC 인증 소요 시간을 고려해 이벤트 마감 전에 미리 앱 다운로드 후, 회원가입 및 KYC 완료를 권장드립니다.
    
  `,
  },

  en: {
    subject: "[Announcement] NEOPIN X 3space Art NFT Airdrop",
    body: `NEOPIN will be joined by 3space Art in an NFT airdrop event featuring super rookie artist, AVANT CLASSIC.

    Please read the following and participate in the “Gummy Nightbear” NFT airdrop event!
    
    [Why participate in the AVANT CLASSIC NFT Airdrop?]
    “Avant Statue” is an experimental project created by artist and jewelry designer, AVANT CLASSIC. Meet “Gummy Nightbear”, an Avant Statue collection which depicts life’s various aspects through bear-shaped NFTs!
    
    [NEOPIN X 3space Art NFT Airdrop]
    - WHEN: 2022.12.21(Wed.) 08:00 ~ 28(Wed.) 07:59 [UTC]
    - WINNER ANN: 2023.1.5(Thu.) on 3space Art’s social media
    - HOW: Social media participation tasks & NEOPIN KYC verification <a href="https://docs.google.com/forms/d/e/1FAIpQLSebtFvxD_lX9R3VJeqtNCMBTW2qmnScPS_TiLDflM-YDb7Kxw/viewform?usp=send_form">(Go to Google Form)</a>
    - REWARDS: 4 Gummy Nightbears worth 1,000$
    ① <a href="https://3space.art/art/871">Plants vs Nightbear</a>
    ② <a href="https://3space.art/art/870">Freddy Nightbear</a>
    ③ <a href="https://3space.art/art/813">Coffee Addict Nightbear</a>
    ④ <a href="https://3space.art/art/812">COVID 19 Nightbear</a>
    
    ※ KYC verification may take up to three working days. We recommend you to download the app and complete the process before the event ends.
    
    [How to find your NEOPIN Wallet address]
    1. Download NEOPIN and sign up
    2. Complete KYC verification
    3. Go to Wallet - Balance - NPT - Receive
    4. Copy NPT address and paste in Google Form
    
    [Precautions]
    Those who do not complete NEOPIN’s KYC verification within the event period will be considered ineligible.
    
`,
  },
};
