export const bittrexListing220602 = {
  ko: {
    subject: `[상장] 네오핀 토큰(NPT) 비트렉스 글로벌 거래소 상장`,
    body: `안녕하세요.
블록체인 오픈플랫폼 네오핀입니다.

‘네오핀 토큰(NPT)’이 비트렉스 글로벌 거래소에 상장했습니다. 6월 1일(수) 한국시간 오전 8시 지갑 입금 개시 및 6월 3일(금) 오후 1시 15분 부터 거래가 시작됐습니다.

1. 거래소 : 비트렉스 글로벌 (Bittrex Global)
2. 토큰명 : NPT
3. 상장 마켓 : NPT/USDT
4. 입금 개시 : 2022년 6월 1일(수) 오전 08:00 (KST)
5. 거래 개시 : 2022년 6월 3일(금) 오후 01:15 (KST)

`,
  },
  en: {
    subject: `[Listing] NEOPIN Token(NPT) listing on Bittrex Global`,
    body: `Hi, we are NEOPIN, an open blockchain platform for all.

We are thrilled to announce that NPT has been listed on Bittrex Global!

Deposits opened at 11:00 PM (UTC) on May 31, 2022, and NPT-USDT trading went live at 04:15 AM (UTC) on June 3, 2022.

New listing on “Bittrex Global”:
1. Exchange : Bittrex Global
2. Name of Token : NPT
3. Market : NPT/USDT
4. Deposit Open : May 31, 2022. 11:00 PM (UTC)
5. Market Open : June 3, 2022 04:15 AM (UTC)


`,
  },
};
