export const membershipEvent230711 = {
  ko: {
    subject: "[공지] 네오핀 멤버십 포인트 부스트 이벤트 안내",
    body: `지난 6월, 네오핀 상품을 이용하면 이용할수록 더욱 많은 혜택이 제공되는 시즌제 네오핀 멤버십 프로그램을 선보였습니다. 
    네오핀 멤버십 프로그램을 더욱 다채롭게 즐길 수 있도록 멤버십 포인트 부스트 이벤트를 아래와 같이 진행 중입니다. 
    
    [네오핀 멤버십 포인트 부스트 이벤트]
    - 이벤트 기간: 2023년 6월 27일(화) ~ 2023년 9월 26일(화)
    - 참여 방법: KLAY Liquid Stake 상품에 Stake하기. 
    - 보상: 이벤트 기간 동안 멤버십 포인트 2배 적립
    
    * 이벤트 기간은 상황에 따라 변동될 수 있습니다
    * KLAY Liquid Stake 상품 위치: [투자] 탭 > [Liquid Stake] 탭 진입 후 KLAY 상품 선택
    
    앞으로 더 다양한 혜택이 담긴 이벤트가 진행될 예정이오니 많은 관심 부탁드립니다.
    항상 네오핀을 이용해 주셔서 감사합니다.
    
    
    
    `,
  },
  en: {
    subject: "[Notice] NEOPIN Membership Point Boost Event",
    body: `In June, the seasonal NEOPIN Membership Program was released, where the more active users are, the more rewards they receive. 
    To make your experience more enjoyable, the Membership Point Boost Event is running as below. 
    
    [NEOPIN Membership Point Boost Event]
    - Event period: June 27, 2023 (Tue) ~ September 26, 2023 (Tue)
    - How to Participate: Stake on KLAY Liquid Stake product
    - Reward: Double the Membership Point earned during the event.
    
    * Event duration may be adjusted according to market conditions. 
    * How to get to KLAY Liquid Stake product: go to [Earn] tab > [Liquid Stake] tab and select KLAY product.
    
    More events with various benefits will be held in the future, so stay tuned.
    
    Thank you for using NEOPIN.
    
    
    `,
  },
};
