export const tronStake230407 = {
  en: {
    subject: "[Notice] Important Notice Regarding Tron Staking",
    body: `The Stake 2.0 proposed by Tron Foundation has been approved and applied to the Tron network as of 06:00:00 UTC on April 7th.

    As a result, additional stake cannot be added to the existing Tron Stake products after this point, and only reward claims and unstaking are possible. Please refer to these options for using the product.
    
    NEOPIN is currently preparing a new Stake product that is equivalent to Tron Stake 2.0 and will provide it as soon as possible.
    
    Thank you for your continued support of NEOPIN.
    
    `,
  },
};
