/* eslint-disable import/no-anonymous-default-export */
export const maintenance221014 = {
  ko: {
    subject: "[공지] 서비스 점검 사전 안내 (완료)",
    body: `안녕하세요. 네오핀(NEOPIN)입니다.

    네오핀은 기능 개선과 안정적인 서비스를 위해 업데이트 및 서비스 점검을 진행할 예정입니다.
    점검이 진행되는 동안 서비스 이용이 일시적으로 중단되오니 이 점 참고 부탁드립니다.
    
    * 일시 : 2022년 10월 19일, 14:00 ~ 17:00 (KST)
    
    * 업데이트 내용
    1) NFT 지갑 기능 개선
    - 동영상 미디어 형태의 NFT 지원
    
    항상 네오핀을 이용해주셔서 감사합니다.
    
    
`,
  },

  en: {
    subject: "[Notice] Prior Notice on Service Maintenance (completed)",
    body: `Please be informed that NEOPIN will perform a scheduled maintenance for security reinforcement and service updates. 

    All services will be temporarily unavailable during the update. We appreciate your patience and understanding. 
    
    * Date: October 19, 2022 5:00~8:00am (UTC)
    
    * Updates: 
    1) NFT Wallet feature Upgrade
    - Supports NFTs in video formats.
    
    
    Thank you for your continued interest and support.
    

`,
  },
};
