/* eslint-disable import/no-anonymous-default-export */
export const custormerService221024 = {
  ko: {
    subject: "[점검] 고객센터 문의하기 서비스 점검 사전 안내 (완료)",
    body: `안녕하세요. 네오핀입니다.
    네오핀은 보다 나은 서비스 제공을 위하여 아래와 같이 고객센터 문의하기 서비스 점검을 진행 예정입니다.
    
    해당 시간 동안 고객센터 문의하기 서비스 이용이 불가능 하오니
    점검 시간 동안 문의 발생 시, 임시 고객센터 메일 주소를 이용해주시기 바랍니다.
    # 해당 서비스 외 다른 서비스는 정상 이용이 가능하십니다.
    
    일시: 2022년 10월 25일(화) 14:00 ~ 2022년 10월 26일(수) 14:00 (KST)
    임시 고객센터 메일 주소 : support@neoply.com
    
    점검 시간은 상황에 따라 조기 종료 또는 지연 될 수 있습니다. 
    이점 양해 부탁드리며, 이용에 참고 부탁드립니다.
     (completed)
    항상 네오핀을 이용해주셔서 감사합니다.
    
    
`,
  },

  en: {
    subject: "[Maintenance] Customer Service maintenance (completed)",
    body: `NEOPIN will be going under maintenance to improve the “Contact Support” service.
    “Contact Support” will not be available during the hours below, so please contact us through temporary Contact Support email .
    # All other services will be available for use.
    
    Maintenance Period: 2022.10.25 (Tues.) 05:00 ~ 2022.10.26 (Wed.) 05:00 (UTC)
    Temporary Contact Support Email : support@neoply.com
    
    The maintenance period is subject to change.
    We thank you for your understanding.
    

`,
  },
};
