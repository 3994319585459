import { useLocation } from "react-router";
import { Link } from "react-router-dom";

export interface ILanguageListItem {
  link: string;
  langName: string;
}

interface IProps {
  langList: ILanguageListItem[];
}

const LanguageNav: React.FC<IProps> = ({ langList }) => {
  const location = useLocation();
  const isActiveLink = (pathname: string) => {
    return location.pathname === pathname;
  };
  return (
    <div className="lang-nav">
      {langList &&
        langList.map((item, i) => (
          <>
            {i !== 0 && <span className="lang-nav__divider">|</span>}
            <Link
              to={item.link}
              className={`lang-nav__item ${
                isActiveLink(item.link) ? "active" : ""
              }`}
            >
              {item.langName}
            </Link>
          </>
        ))}
    </div>
  );
};

export default LanguageNav;
