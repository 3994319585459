export const neopinStating20220701 = {
  ko: {
    subject: `[공지] NPT(네오핀 토큰) 스테이킹 서비스 출시`,
    body: `안녕하세요.
    블록체인 오픈플랫폼 네오핀입니다.
    
    네오핀이 ‘NPT(네오핀 토큰) 스테이킹’ 서비스를 선보입니다.
    
    스테이킹 서비스를 통해 원하는 기간만큼 자유롭게 NPT를 위임하고, 안정적인 리워드를 수령해 보세요!
    
    - 서비스 명: ‘NPT(네오핀 토큰) 스테이킹'
    - 론칭 일시: 2022년 7월 1일
    
    [NPT(네오핀 토큰) 스테이킹이란?]
    네오핀에 NPT를 위임하고, 위임에 따른 보상을 수령할 수 있는 서비스입니다.
    스테이킹은 NPT 위임 수량에 변화가 없고, 위임에 따른 보상까지 얻을 수 있어 안정적으로 수익을 얻을 수 있는 가상자산 예치 상품입니다.   
    오랜 블록체인 개발 경험과 운영 노하우를 가진 네오핀만의 신뢰성과 안전성, 이와 더불어 안정적인 수익률까지 기대할 수 있는 NPT 스테이킹 서비스를 이용해 보세요!
    
    [이용 안내]
    1. NPT를 네오핀 앱에 보유 시, 
    클릭 몇 번 만으로 NPT 스테이킹 서비스 이용 가능!
    
    2. 거래소 혹은 개인 지갑에 NPT 보유 시,
    거래소/개인지갑 → 네오핀 앱 NPT 전송 → NPT 스테이킹 서비스 이용
    
    3. NPT 미보유 시,
    NPT 상장 거래소 → NPT 매수 → 네오핀 앱 NPT 전송  → NPT 스테이킹 서비스 이용
    
    ※ 국내 거래소의 경우, 트래블룰에 따라 암호화폐를 네오핀 앱으로 100만 원 이상 직접 전송하는 것이 불가능합니다. 각 거래소 별 트래블룰 주의사항을 꼭 확인해 주세요.  
    
    [NPT 상장 거래소(22년 7월 1일 기준)] 
    후오비 글로벌, 게이트아이오, MEXC 글로벌, 비트렉스 글로벌, 프로비트 글로벌, 빗썸, 코인원, 코빗, 비트겟 등 총 9곳
       
    새로 출시한 NPT(네오핀 토큰) 스테이킹 서비스에 많은 관심과 예치 부탁드리며, 앞으로도 다양한 서비스를 통해 사용자 여러분의 편익을 위해 최선을 다하는 네오핀이 되겠습니다.
    
    감사합니다.
    
`,
  },
  en: {
    subject: `[Notice] NPT Staking Service Launch`,
    body: `We would like to inform you that you may now stake NPT (Neopin Token) on the NEOPIN App!

    - Service name: NPT (Neopin Token) Staking
    - Launch date: July 1, 2022 
    
    [What is NPT (Neopin Token) Staking?]
    NPT Staking is a crypto service that rewards users for delegating NPT to NEOPIN according to the amount delegated. 
    Stake NPT for rewards on NEOPIN, a safe and reliable platform developed by a team with vast experience in blockchain development and operation.  
    
    [User Guide]
    1. If you’re holding NPT on your NEOPIN App, NPT Staking is available with just a few clicks. 
    
    2. If you’re currently holding NPT at an exchange or in your private wallet: 
    Exchange/Private wallet → Transfer NPT to NEOPIN App → Stake NPT 
    
    3. If you currently do not own any NPT:
    Purchase NPT on an exchange where NPT is listed → Transfer NPT to NEOPIN App → Stake NPT
    
    ※ Transferring crypto assets worth more than 1 million won from Korean exchanges to the NEOPIN App is unavailable due to the FATF Travel Rule. Please review withdrawal policies carefully before proceeding with transactions. 
    
    [Exchanges that have NPT listed (as of July 1, 2022)]
    A total of 9 exchanges including Huobi Global, Gate.io, Bittrex Global, MEXC Global, Bitget, ProBit Global, Bithumb, Coinone, and Korbit
    
    Thank you for your support and interest in NPT Staking services on NEOPIN. We will keep striving to provide better services for our users. 
    
`,
  },
};
