/* eslint-disable import/no-anonymous-default-export */
export const preNotiSecure220928 = {
  ko: {
    subject: "[공지] 보안설정 변경 및 점검 사전안내 (완료)",
    body: `안녕하세요. 네오핀(NEOPIN)입니다.

    네오핀은 이용 편의성 제고와 보안 강화를 위하여 업데이트를 진행할 예정입니다.
    업데이트로 변경되는 항목을 확인하시고 필요한 정보를 사전에 준비하시기를 권장드립니다. 
    그리고 업데이트 시간동안 서비스 이용이 일시적으로 중단되오니 이 점 참고부탁드립니다.
    
    * 일시: 2022년 10월 4일, 14:00~16:00 (KSP) (예정)
              (해당 시간동안 서비스 일시 중단)
    
    * 확인 권장 사항
      - 복구 단어(Mnemonic) 확인
      - OTP 인증 정상적으로 작동하는지 확인
    
    * 업데이트 내용
    1) OTP 인증 선택화
        - 기존에 필수 설정이었던 OTP 인증이 선택사항으로 변경되어 ‘2차 보안 설정’으로 설정 가능합니다.
        - 초기화를 위하여 OTP 인증이나 Mnemonic이 필요합니다.
    
    2) PIN 번호 인증 필수화
        - PIN 번호 설정이 필수 항목으로 신규 개설됩니다.
        - 분실 시, Mnemonic으로 재설정 가능합니다.
    
    3) 생체정보 인증 지원
        - PIN 번호를 대체하는 생체정보 인증을 지원합니다.
    
    
    항상 네오핀을 이용해주셔서 감사합니다.
`,
  },

  en: {
    subject:
      "Prior Notice on Security Setting Changes and Service Maintenance (completed)",
    body: `Please be informed that NEOPIN will perform an update for reinforcing security and improving user convenience. 
    Please see below for more details, and prepare the necessary information. 

    Also, please note that all services will be temporarily unavailable during the update.
    
    * Date: October 4, 2022 5:00~7:00am (UTC)
    
    * Recommended Checklist:
      - Recovery Phrase(Mnemonic)
      - Proper operation of OTP authentication
    
    * Updates: 
    1) Make OTP optional
      - OTP authentication will no longer be required but will be changed to optional, which can be set through ‘Two Factor Authentication’. 
      - To reset, OTP authentication or Mnemonic is required.
    
    2) Mandatory PIN Code setting
      - PIN Code settings will be newly opened as required.
      - It can be reset with Mnemonic in case of loss.
    
    3) Biometric authentication support
      - Supports Biometric authentication as an alternative to PIN Code.
    

      
    Thank you for your continued interest and support.
    
`,
  },
};
