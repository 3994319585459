export const klaytnPartnership220321 = {
  ko: {
    subject: "[파트너십] 네오핀, ‘클레이튼 재단’과 전략적 파트너십 체결",
    body: `안녕하세요.
블록체인 오픈플랫폼 네오핀입니다.

블록체인 전문 기업 네오플라이의 자회사인 네오핀은 21일 ‘클레이튼 재단’과 전략적 파트너십을 체결했습니다.
 
이번 파트너십은 네오핀과 클레이튼의 성공적인 글로벌 블록체인 생태계 확장 및 4월부터 출시되는 클레이튼 네트워크 기반 P&E(Play and Earn) 게임의 성공을 위해 진행되었습니다. 
 
클레이튼 재단은 클레이튼 메인넷을 기반으로 개발된 블록체인 오픈플랫폼 네오핀의 생태계 활성화를 위해 ‘Klaytn Growth Fund’를 통해 전략적 투자를 단행합니다. 
네오핀 역시 클레이튼에 전략적 투자를 집행하고, 향후 클레이튼 기반의 P&E 게임을 확대해 나가기로 협의했습니다. 
 
양사는 성공적인 글로벌 확장을 위해 향후 홍보 및 마케팅 분야에서도 적극 지원할 예정입니다. 
네오플라이가 지난 2019년부터 클레이튼 거버넌스 카운슬(Governance Council)의 멤버로 활동해왔던 것도 파트너십 체결의 주요 배경 중 하나로 작용했습니다.
`,
  },
  en: {
    subject:
      "[Partnership] NEOPIN enters into strategic partnership with Klaytn Foundation as part of a “joint expansion of the global blockchain ecosystem”",
    body: `Hi, we are NEOPIN, an open blockchain platform for all.

NEOPIN, a blockchain subsidiary of NEOPLY, has announced on Mar. 21st that it has signed a strategic partnership with the Klaytn Foundation.
 
This partnership was signed for an efficient expansion of the global blockchain ecosystem of both NEOPIN and Klaytn, and for successful launches of Klaytn network-based P2E (Play to Earn) games from April. In addition, the fact that NEOPLY has been a member of the Klaytn Governance Council since 2019 has served as one of the motivating factors for the partnership. 
 
The Klaytn Foundation will make strategic investments through the “Klaytn Growth Fund” to invigorate the ecosystem of NEOPIN, an “open blockchain platform” based on the Klaytn mainnet. NEOPIN also agreed to make strategic investments in Klaytn, Kakao’s public blockchain mainnet, and expand Klaytn-based P2E games in the coming months. The two entities plan to actively collaborate in Public Relations and Marketing strategies for global expansion as well. 
`,
  },
};