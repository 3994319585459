export const npEthpool230808 = {
  ko: {
    subject: "[공지] npETH-ETH 스왑풀 추가 및 멤버십 포인트 부스트 이벤트 안내",
    body: `안녕하세요, 네오핀입니다. 

    네오핀은 상품의 확장과 사용자 편의를 위해 이더리움 네트워크 서비스에 아래 상품을 추가했습니다.
    
    npETH-ETH 스왑풀
    
    새로운 상품을 통하여, npETH 토큰을 유동성 풀에 예치하거나 다른 토큰으로 손쉽게 교환할 수 있습니다.
    
    npETH-ETH 스왑풀 출시를 기념하며 네오핀 멤버십 포인트 부스트 이벤트를 아래와 같이 진행하오니 많은 참여와 관심 부탁드립니다. 
    
    [네오핀 멤버십 포인트 부스트 이벤트]
    - 이벤트 기간: 2023년 8월 9일(수) ~ 2023년 9월 26일(화)
    - 참여 방법: npETH-ETH 스왑풀 상품에 예치
    - 보상: 이벤트 기간 동안 멤버십 포인트 2배 적립
    * npETH-ETH Pool 상품 위치: [투자] 탭 > [Pool] 탭 진입 후 npETH-ETH 상품 선택
    
    항상 네오핀을 이용해 주셔서 감사합니다.
    
    `,
  },
  en: {
    subject:
      "[Announcement] npETH-ETH Swap Pool Release and Membership Point Boost Event",
    body: `NEOPIN’s new Ethereum network product has been released as below:

      -  npETH-ETH Swap Pool
    
    With this new product, npETH tokens can be deposited into the liquidity pool and easily exchanged with other tokens. 
    
    To celebrate the release of the ETH-ETH Swap Pool, the NEOPIN Membership Point Boost event is running as below. 
    
    [NEOPIN Membership Point Boost Event]
    - Event Period: 09. August 2023 (Wed) ~ 26. September 2023 (Tue)
    - How to Participate: Deposit to the npETH-ETH Pool
    - Reward: Double the Membership Points earned during the event.
    * npETH-ETH Pool product Location: [Earn] tab > [Pool] tab, select npETH-ETH product. 
    
    Thank you for using NEOPIN.
    
    `,
  },
};
