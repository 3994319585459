export const xqgamesPartnership230103 = {
  ko: {
    subject:
      "[파트너십] 네오핀, 엑스큐케임즈와 파트너십 체결, 인피니티 사가X 온보딩",
    body: `안녕하세요. 네오핀입니다.

    네오핀이 엑스큐게임즈와 파트너십을 체결하고, P2E 게임 기대작 ‘인피니티 사가X’를 온보딩합니다.
    
    엑스큐게임즈는 다양한 게임 개발, 운영 등의 노하우로 인정받고 있는 회사인데요. 특히 올해 출시를 앞둔 인피니티 사가X는 전략 시뮬레이션과 수집형 RPG 등 2개의 장르가 결합한 새로운 방식의 폴리곤 기반 P2E 게임으로 많은 관심을 모으고 있습니다.
    
    네오핀은 이번 파트너십을 통해 클레이튼을 넘어 폴리곤까지 P2E 생태계를 확장하게 될 예정입니다.
    
    또한, 이미 온보딩 된 클레이튼 기반 게임 네오위즈 ‘브레이브 나인', ‘크립토 골프 임팩트', 가상세계 P2E게임 ‘쉽팜 인 메타랜드' 이외에도 ‘에픽리그’, ‘클레이다이스’, ‘클레이메타’에도 네오핀 지갑이 연동될 예정이니 많은 기대 부탁드립니다.
    
    감사합니다.
    
  `,
  },

  en: {
    subject:
      "[Partnership] NEOPIN signs partnership with XQGames, onboarding Infinity Saga X",
    body: `NEOPIN has signed a partnership with XQGames, and will be onboarding the highly anticipated P2E game, Infinity Saga X.

    XQGames is a company recognized for its know-how in developing and operating various games. In particular, Infinity Saga X, a Polygon-based P2E game scheduled to be released this year, is attracting a lot of attention as it is a game that combines two genres: strategy simulation and collectible RPG.
    
    This partnership marks the beginning of NEOPIN’s multi-chain P2E ecosystem, expanding from Klaytn to Polygon.
    
    Additionally, NEOPIN will add support for Epic League, Klay Dice, KlayMeta on its wallet. Please stay tuned for more exciting news coming this year.
    
    Thank you.
    
`,
  },
};
