export const impostorCaution220323 = {
  ko: {
    subject: "[공지] 네오핀 커뮤니티 사칭 주의 안내",
    body: `안녕하세요.
블록체인 오픈플랫폼 네오핀입니다.

현재 네오핀 텔레그램 커뮤니티 및 홈페이지를 사칭하여 운영하는 사례가 접수되어 주의 안내 말씀드립니다.

※ 네오핀 커뮤니티 및 운영자, 홈페이지를 사칭한 금전 사기에 유의해주시기 바랍니다.

네오핀 커뮤니티 운영자는 이용자분들께 DM을 보내지 않습니다. 
운영자를 사칭하여 복구 단어, 프라이빗 키, 지갑 주소, 비밀번호 등을 요청하는 경우에는 절대로 답장하시지 마시고 바로 차단하시기 바랍니다. 

네오핀을 사칭한 커뮤니티나 홈페이지에서 진행되는 이벤트 역시 모두 허위입니다.  

※ 네오핀 공식 커뮤니티 및 홈페이지 정보는 아래와 같습니다.

<네오핀 공식 커뮤니티 주소>

1. NEOPIN Official Announcements
    주소 : <a href="https://t.me/neopin_announcements">https://t.me/neopin_announcements</a>

2. NEOPIN Official Korean Group
    주소 : <a href="https://t.me/neopin_kor">https://t.me/neopin_kor</a>

3. NEOPIN Official English Group
    주소 : <a href="https://t.me/neopin_eng">https://t.me/neopin_eng</a>

<네오핀 공식 홈페이지>
네오핀 공식 홈페이지 : <a href="https://neopin.io/">https://neopin.io/</a>
 
<네오핀 공식 CS 메일>
서비스 오류 관련: <a href="mailto:help@neopin.io">help@neopin.io</a>
KYC 문의 전용: <a href="mailto:kyc@neopin.io">kyc@neopin.io</a>

사칭 커뮤니티 및 계정, 홈페이지 등으로 인한 피해가 없도록 다시 한번 유의 부탁드립니다.

감사합니다.
네오핀 드림
`,
  },
  en: {
    subject: "[Announcement] Scam Alert for NEOPIN Users",
    body: `Hi, we are NEOPIN, an open blockchain platform for all.

We would like to inform you that there have been numerous cases of impersonators and scammers attempting to trick our users into sending them NPTs.

Please beware of any fraudulent NPT events or airdrops taking place in fake NEOPIN communities/websites, or any financial scams by some unknown users impersonating NEOPIN’s official Community Managers.

Our Community Managers never DM our users or reply to any DMs.

DO NOT reply to any requests for recovery phrase, private key, wallet address, or password to your account, and immediately ban such users.

※ Information on the Official NEOPIN Communities and Website 

<Official NEOPIN Communities>

1. NEOPIN Official Announcements
   <a href="https://t.me/neopin_announcements">https://t.me/neopin_announcements</a>

2. NEOPIN Official Korean Group
   <a href="https://t.me/neopin_kor">https://t.me/neopin_kor</a>

3. NEOPIN Official English Group
   <a href="https://t.me/neopin_eng">https://t.me/neopin_eng</a>

<Official NEOPIN Website>
   <a href="https://neopin.io/">https://neopin.io/</a>
 
<Official NEOPIN Customer Service Center>
Service error inquires: <a href="mailto:help@neopin.io">help@neopin.io</a> 
KYC related inquiries: <a href="mailto:kyc@neopin.io">kyc@neopin.io</a> 

Once again, please beware of financial scams for a healthy and enjoyable crypto experience with NEOPIN.

Sincerely,

Team NEOPIN
`,
  },
};