/* eslint-disable import/no-anonymous-default-export */
export const nftairdrop221110 = {
  ko: {
    subject:
      "[소식] 네오핀 × LILLIUS 유틸리티 NFT 단독 에어드랍 이벤트 참여 안내 (완료)",
    body: `안녕하세요, 네오핀입니다.

    네오핀의 NFT 지갑 출시를 기념해 T2E(Training To Earn) 서비스 LILLIUS와 함께하는 스포츠 NFT 단독 에어드랍 이벤트가 시작됐습니다!
    
    오는 12월 론칭 예정인 LILLIUS는 AI 모션 분석 기술을 통해 실시간 운동 점수를 평가하고, 이에 대한 공정한 보상을 암호화폐로 제공하는 ‘WEB 3.0 Sports Experience Platform’입니다.
    
    네오핀이 단독으로 에어드랍하는 덤벨 NFT와 OG는 LILLIUS에서 운동 기능을 강화해주고, 다양한 혜택을 통해 민팅 권한을 부여하는 것이 특징입니다.
    
    아래 내용을 참고하셔서, 에어드랍 이벤트에 꼭! 참여해 보세요.
    
    [네오핀 × LILLIUS NFT 에어드랍 이벤트]
    - 참여 기간: 2022. 11. 10(목) 17:00:00~15(화) 23:59:59(KST) 
    - 참여 방법: 글림폼 미션 수행 <a href="https://gleam.io/BNE2f/lillius-x-neopin"><글림폼 바로가기></a>
    - 당첨자 발표: 2022. 11. 22(화) 17:00:00(KST), 릴리어스 트위터, 텔레그램, 디스코드
    - 에어드랍: Dumbbell NFT 5개, OG 20개
    
    [주의사항]
    이벤트 기간 내 KYC 처리가 완료되지 않을 경우 응모가 불가능합니다. 회원가입 이후 KYC 인증에 소요되는 시간을 고려하여 여유있게 이벤트 종료 3일 전에는 미리 앱 다운로드 후, 회원가입을 권장드립니다.
    
    감사합니다.
    
`,
  },

  en: {
    subject:
      "[Announcement] NEOPIN x LILLIUS Exclusive Utility NFT Airdrop Event Notice (completed)",
    body: `To celebrate NEOPIN’s NFT wallet launch, T2E (Training to Earn) service LILLIUS will join NEOPIN in an exclusive NFT airdrop event.

    LILLIUS, scheduled to launch this December, is a WEB 3.0 Sports Experience Platform which provides rewards and sports activity evaluation through AI motion analytics. 
    
    NEOPIN will be dropping exclusive Dumbbell NFTs and Beta Tester Roles. Dumbbells are utility NFTs that will act as reward boosters for in-app activity.
    
    Please read the below and make sure to join the event!
    
    [NEOPIN × LILLIUS NFT Airdrop Event]
    - When: Nov 10, 2022 (Thurs) 08:00:00 - Nov 15, 2022 (Tues) 14:59:59 (UTC) 
    - How: Gleam tasks  <a href="https://gleam.io/iqnfS/lillius-x-neopin-en/">< Go to Gleam ></a>
    - Winner Announcement: 2022. 11. 22(Tues) 08:00:00(UTC)
    - Airdrop: 5 Dumbbell NFTs, 20 OGs
    
    [Note]
    Entrants who have not completed KYC within the event period will have their entries canceled. We recommend you complete all tasks three days before the event ends to make sure your KYC verification is complete.
    
    Thank you.
    
`,
  },
};
