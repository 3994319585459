/** API가 아직 없어서 하드코딩. API가 추가되면 주석처리 한 부분 해제 */

import { IFetcherOption, request } from "./apiRequest";
import * as notice from "../lang/notice/index";

export interface INoticeDetail {
  desc: string;
  en?: {
    body: string;
    subject: string;
  };
  id: string;
  ko?: {
    body: string;
    subject: string;
  };
  openAt: string;
  result: number;
  resultString: string;
  title?: string;
}

export const fetchNoticeDetail = async (url: string) => {
  const option: IFetcherOption = {
    method: "GET",
    onRequest(params: any) {
      return params;
    },

    onResponse(res: INoticeDetail) {
      return res;
    },
  };
  const urlQuery = url.split("/");
  const notice_number = Number(urlQuery[urlQuery.length - 1]);
  const result = [
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "1",
      title: "NEOPIN Released",
      ko: {
        subject: "[안내] NEOPIN 서비스 오픈 안내",
        body: `안녕하세요. 블록체인 오픈플랫폼 ‘네오핀’입니다. 
        2022년 1월 25일부터 네오핀 상용화 서비스를 시작합니다.
        
        [네오핀이란?]
        네오위즈홀딩스의 블록체인 전문 자회사 네오플라이가 출시한 ‘블록체인 오픈플랫폼’입니다.
        
        [핵심 비전]
        네오핀의 핵심 비전은 ‘연결’과 ‘확장’입니다. 가상자산 지갑, 게임, 서비스, NFT 등 블록체인과 관련한 다양한 서비스가 결합되는 ‘블록체인 오픈플랫폼’을 지향하고 있습니다. 가상자산 금융서비스를 시작으로 연내 ‘연결’과 ‘확장’의 비전을 단계별로 실현해나갈 계획입니다.
        
        1분기 블록체인 오픈플랫폼 '네오핀' 서비스 출시
                  - 네오핀 서비스 출시(구글 플레이 스토어, 애플 앱 스토어)
                  - 네오핀 스테이킹 상품 및 NPT 락업 상품 출시
                  - 초기 스왑풀 모집(Airdrop Farm Offering)
                  - 신규 P2E 게임 출시 등 GameFi 공략 본격화
         
        2분기 ‘연결’과 ‘확장’의 비전 실현 1단계 
        <오픈플랫폼 확장 가속화>
                  - 크립토 금융 상품 확대(스왑풀, 스테이킹 상품 확대)
                  - 브라운더스트 등 신규 P2E 게임 출시 통한 GameFi 글로벌 공략 시작
                  - Dapp 서비스(S2E, NFT) 확장 목표
        
        3분기 '연결'과 '확장'의 비전 실현 2단계 
        <NEOPIN-콘텐츠 기업 상생플랫폼으로의 확장>
                 - 신규 P2E 게임 출시 통한 GameFi 글로벌 확장         
                 - 콘텐츠 기업 서비스 파트너십 체결
                 
        4분기 '연결'과 '확장'의 비전 실현 3단계
         <글로벌 플랫폼 연동>
                  - 글로벌 파트너사(거래소, 플랫폼) 협업 목표
        
        [지갑 기능]
        지갑 기능은 스테이킹을 시작으로 스왑, 이자농사, 락업과 같은 가상자산 금융서비스를 제공할 예정입니다. 암호화폐는 이더리움(ETH), 클레이튼(KLAY), 트론(TRX) 등을 서비스별로 상이하게 지원합니다. 
        
        서비스 오픈 초기에는 스테이킹 이용시 보상 이율 외에 ‘NPT(네오핀 토큰)’를 추가 보상으로 지급합니다. 순차적으로 오픈되는 금융서비스도 NPT를 보상으로 제공할 예정입니다. 
        
        [신뢰성]
        네오위즈홀딩스 블록체인 프로젝트 ‘네오핀’
        네오플라이는 지난 5년 동안 엔블록스(nBlocks)', 이오스 네트워크 프로젝트 ‘EOSeoul’ 등 블록체인 서비스를 안정적으로 운영해왔습니다. 한국인터넷진흥원(KISA)으로부터 정보보호관리체계(ISMS) 인증을 획득했으며, 자금세탁방지(Anti-Money Laundering, AML) 및 고객확인제도(Know-Your-Customer, KYC) 등 강화된 보안 기능을 네오핀에 적용했습니다. 
        
        네오핀에 많은 관심과 이용 부탁드립니다.`,
      },
      en: {
        subject: "[Notice] NEOPIN Released",
        body: `Hello, This is Blockchain Open Platform ‘NEOPIN’.
        NEOPIN will launch on January 25th, 2022.
        
        [What is NEOPIN?]
        NEOPIN is a Blockchain Open Platform launched by NEOPLY who is a Blockchain specialized subsidiary of NEOWIZ Holdings.
        
        [Core Vision]
        The Core Vision of NEOPIN are ‘Connection’ and ‘Expansion’.
        We are aiming for a Blockchain Open Platform that combines various Blockchain-related services such as Crypto asset wallets, games, services and NFTs.
        
        [Roadmap]
        Starting with Crypto asset financial service, we plan to realize the vision of ‘Connection’ and ‘Expansion’ in stages within this year.
        
        1st quarter
        Launch of the Blockchain Open Platform ‘NEOPIN’ service
          - NEOPIN service launch (Google Play Store, App Store)
          - NEOPIN Staking product and NPT(NEOPIN Token) Lock-up product
          - Initial Swap Pool Offering (Airdrop Farm Offering, AFO)
          - Work on  GameFi by launching new P2E games.
        
        2nd quarter
        Realizing the vision of ‘Connection’ and ‘Expansion’ - Step1.
        <Accelerating Open Platform expansion>
          - Expansion of Crypto financial products (Expansion of Swap Pool, Staking products)
          - Start to target global GameFi market with new P2E games such as ‘Brown Dust’.
          - Target expanding DApp service (S2E, NFT)
        
        3rd quarter
        Realizing the vision of ‘Connection’ and ‘Expansion’ - Step2.
        <Expand to a platform for ‘win-win’ between NEOPIN and contents partners>
          - Global GameFi expansion by launching new P2E games
          - Service partnership with contents companies
        
        4th quarter
        Realizing the vision of ‘Connection’ and ‘Expansion’ - Step3.
        <Connecting with Global platforms>
        - Goal of collaboration with global partners (Exchanges, Platforms)
        
        [Wallet function]
        Starting with Staking for Cryptocurrencies, the wallet function will provide Crypto asset financial services such as Swap, Yield Farming and Lock-up. Supported cryptocurrencies are Ethereum(ETH), Klaytn(KLAY), Tron(TRX), etc, and will vary between h services.
        
        In particular, in the early stage of service opening, NPT(NEOPIN Token) will be paid as an additional reward other than interest for  staking products.  Moreover, all coming up financial services will provide NPT as a reward.
        
        [Reliability]
        NEOWIZ Blockchain Project ‘NEOPIN’
        NEOPLY has been stably operating Blockchain services such as ‘nBlocks’ and EOS network project ‘EOSeoul’ for the past 5 years. We have acquired the Information Security Management System(ISMS) certification from the Korea Internet & Security Agency(KISA) and have enhanced security features such as Anti-Money Laundering(AML) and Know-Your-Customer(KYC) to NEOPIN.
        
        We ask for your interest and support for NEOPIN.
        `,
      },
      openAt: "2022-01-25T14:00:00.063Z",
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "2",
      title: "NEOPIN Released",
      openAt: "2022-02-07T00:00:00.063Z",
      ...notice.neopinReleased,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "3",
      title:
        "[Notice] Temporary network error due to traffic congestion (Network normalized)",
      openAt: "2022-02-10T01:20:04Z",
      ...notice.notice220210,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "4",
      title: "[안내] 네오핀 개인정보처리방침 개정 안내",
      openAt: "2022-02-14T01:20:04Z",
      ...notice.notice220214,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "5",
      title: "친구초대 이벤트",
      openAt: "2022-02-23T01:00:00Z",
      ...notice.friendInvite220223,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "6",
      title: "커뮤니티 입장 이벤트",
      openAt: "2022-02-23T01:00:00Z",
      ...notice.community220223,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "7",
      title: "[상장] 네오핀 토큰(NPT) 글로벌 동시 상장",
      openAt: "2022-02-28T02:50:00Z",
      ...notice.nptListing220228,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "8",
      title: "[이벤트] KLAY, TRX 스테이킹 시 NPT 추가 보상 이율 인상",
      openAt: "2022-02-28T02:50:00Z",
      ...notice.addNtpReward220228,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "9",
      title: "[공지] 가입이벤트 7 NPT 보상 및 스테이킹 NPT 보상 지급 개시",
      openAt: "2022-02-28T02:50:00Z",
      ...notice.payoutOpenEvt220228,
    },
    // 220302 afo 딜레이 이슈로 공지 내림
    // {
    //   result: 0,
    //   resultString: "Success",
    //   desc: "",
    //   id: "10",
    //   title: "[공지] ETH-NPT AFO 모집 안내",
    //   openAt: "2022-02-28T02:50:00Z",
    //   ...notice.afoOpen220228,
    // },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "11",
      title: "[안내] ETH-NPT AFO 일정 연기 ",
      openAt: "2022-03-02T12:00:45Z",
      ...notice.afoDelay220302,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "12",
      title: "[공지] 친구초대 이벤트 NPT 보상 지급 개시",
      openAt: "2022-03-11T00:00:45Z",
      ...notice.referralpayout220311,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "13",
      title: "[상장] 네오핀 토큰(NPT) 빗썸 거래소 상장",
      openAt: "2022-03-11T07:00:45Z",
      ...notice.bithumbListing220311,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "14",
      openAt: "2022-03-14T01:41:32Z",
      ...notice.golfImpact220314,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "15",
      openAt: "2022-03-16T01:47:57Z",
      ...notice.verifyVASP220316,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "16",
      openAt: "2022-03-17T06:32:16Z",
      ...notice.afoCancel220317,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "17",
      openAt: "2022-03-21T03:35:37Z",
      ...notice.klaytnPartnership220321,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "18",
      openAt: "2022-03-22T00:55:12Z",
      ...notice.coinoneListing220322,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "19",
      openAt: "2022-03-23T04:34:03Z",
      ...notice.impostorCaution220323,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "20",
      openAt: "2022-03-24T01:56:02Z",
      ...notice.bugsPartnership220321,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "21",
      openAt: "2022-03-25T01:36:52Z",
      ...notice.bitgetListing220325,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "22",
      openAt: "2022-03-30T09:31:01Z",
      ...notice.gateIoListing220330,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "23",
      openAt: "2022-04-04T01:42:23Z",
      ...notice.DVisionPartnership220404,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "24",
      openAt: "2022-04-12T07:50:43Z",
      ...notice.bravenineAirdrop220412,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "25",
      openAt: "2022-04-12T08:06:00Z",
      ...notice.huobiListing220412,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "26",
      openAt: "2022-04-28T02:00:00Z",
      ...notice.cgiRelease220428,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "27",
      openAt: "2022-05-13T02:20:00Z",
      ...notice.bravenineBvt220512,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "28",
      openAt: "2022-05-26T04:55:00Z",
      ...notice.korbitListing220526,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "29",
      openAt: "2022-06-03T04:15:00Z",
      ...notice.bittrexListing220602,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "30",
      openAt: "2022-07-01T00:00:00Z",
      ...notice.neopinStating20220701,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "31",
      openAt: "2022-07-25T10:03:00Z",
      ...notice.rewardSettlementCompletet20220725,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "32",
      openAt: "2022-07-25T10:05:00Z",
      ...notice.serviceUpdate20220725,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "33",
      openAt: "2022-08-01T00:00:00Z",
      ...notice.forceWithdraw220801,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "34",
      openAt: "2022-08-01T00:00:00Z",
      ...notice.servicelaunched20220801,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "35",
      openAt: "2022-08-23T00:00:00Z",
      ...notice.maintenance220823,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "36",
      openAt: "2022-08-26T09:00:00Z",
      ...notice.patchNote220826,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "37",
      openAt: "2022-09-28T00:00:00Z",
      ...notice.preNotiSecure220928,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "38",
      openAt: "2022-10-04T00:00:00Z",
      ...notice.patchOtp221004,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "39",
      openAt: "2022-10-04T00:00:00Z",
      ...notice.nftOpen221004,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "40",
      openAt: "2022-10-14T00:00:00Z",
      ...notice.maintenance221014,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "41",
      openAt: "2022-10-24T05:00:00Z",
      ...notice.custormerService221024,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "42",
      openAt: "2022-11-04T09:00:00Z",
      ...notice.systemUpdate221104,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "43",
      openAt: "2022-11-10T00:00:00Z",
      ...notice.nftairdrop221110,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "44",
      openAt: "2022-11-15T15:00:00Z",
      ...notice.nftairdropend221115,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "45",
      openAt: "2022-11-28T09:20:00Z",
      ...notice.sheepFarm221128,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "46",
      openAt: "2022-12-02T06:00:00Z",
      ...notice.sheepFarmAirDrop221202,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "47",
      openAt: "2022-12-07T00:00:00Z",
      ...notice.farmV1Return221207,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "48",
      openAt: "2022-12-08T09:00:00Z",
      ...notice.ipvEnd221208,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "49",
      openAt: "2022-12-08T09:00:00Z",
      ...notice.ethAddress221208,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "50",
      openAt: "2022-12-08T09:00:00Z",
      ...notice.serviceMaintanace221209,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "51",
      openAt: "2022-12-14T08:00:00Z",
      ...notice.swapNoti20221214,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "52",
      openAt: "2022-12-16T00:00:00Z",
      ...notice.sheepfarmEnd20221216,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "53",
      openAt: "2022-12-19T06:10:00Z",
      ...notice.airdrop3spaceArt221219,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "54",
      openAt: "2022-12-20T01:00:00Z",
      ...notice.polygonNetwork221219,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "55",
      openAt: "2022-12-21T00:00:00Z",
      ...notice.airdrop3spaceArt221221,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "56",
      openAt: "2022-12-22T00:00:00Z",
      ...notice.airdropSheepfarm221222,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "57",
      openAt: "2022-12-23T05:00:00Z",
      ...notice.ssxNpt221223,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "58",
      openAt: "2022-12-28T00:00:00Z",
      ...notice.airdrop3spaceArtEnd221228,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "59",
      openAt: "2023-01-03T00:00:00Z",
      ...notice.xqgamesPartnership230103,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "60",
      openAt: "2023-01-09T06:00:00Z",
      ...notice.ipvEnd230109,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "61",
      openAt: "2023-01-26T00:00:00Z",
      ...notice.pixelplay230126,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "62",
      openAt: "2023-02-15T00:00:00Z",
      ...notice.prestolabs230215,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "63",
      openAt: "2023-02-20T00:00:00Z",
      ...notice.serviceMaintenance230220,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "64",
      openAt: "2023-02-27T00:00:00Z",
      ...notice.serviceComplete230227,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "65",
      openAt: "2023-03-06T00:00:00Z",
      ...notice.ozysPartnership230306,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "66",
      openAt: "2023-03-08T00:00:00Z",
      ...notice.boomcoPartnership230309,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "67",
      openAt: "2023-03-15T00:00:00Z",
      ...notice.agatePartnership230315,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "68",
      openAt: "2023-03-27T11:15:00Z",
      ...notice.maintenance230327,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "69",
      openAt: "2023-03-27T11:15:00Z",
      ...notice.s2tEnd230327,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "70",
      openAt: "2023-03-31T00:00:00Z",
      ...notice.iosMaintenance230331,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "71",
      openAt: "2023-04-01T00:00:00Z",
      ...notice.iosOpen30401,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "72",
      openAt: "2023-04-04T00:00:00Z",
      ...notice.tronStake230404,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "73",
      openAt: "2023-04-05T00:00:00Z",
      ...notice.partnersTripbtoz0405,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "74",
      openAt: "2023-04-07T00:00:00Z",
      ...notice.tronStake230407,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "75",
      openAt: "2023-04-10T01:00:00Z",
      ...notice.s2tend230410,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "76",
      openAt: "2023-04-12T06:00:00Z",
      ...notice.ethPool230412,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "77",
      openAt: "2023-04-19T07:00:00Z",
      ...notice.meshSwap230419,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "78",
      openAt: "2023-04-25T10:00:00Z",
      ...notice.maintenance230425,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "79",
      openAt: "2023-05-01T00:00:00Z",
      ...notice.zemitEnd230501,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "80",
      openAt: "2023-05-02T06:00:00Z",
      ...notice.zemitEnd230502,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "81",
      openAt: "2023-05-09T06:00:00Z",
      ...notice.listingIndodax230509,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "82",
      openAt: "2023-05-12T06:00:00Z",
      ...notice.listingKlapEnd230512,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "83",
      openAt: "2023-05-19T06:00:00Z",
      ...notice.joySwapPool230519,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "84",
      openAt: "2023-05-24T00:00:00Z",
      ...notice.updateNotice230524,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "85",
      openAt: "2023-06-02T09:00:00Z",
      ...notice.bbcEnd230602,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "86",
      openAt: "2023-06-08T09:00:00Z",
      ...notice.paceSwapPool230608,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "87",
      openAt: "2023-06-26T04:00:00Z",
      ...notice.serviceUpdate230626,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "88",
      openAt: "2023-07-07T14:11:00Z",
      ...notice.maintenanceNoti230707,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "89",
      openAt: "2023-07-11T00:00:00Z",
      ...notice.membershipEvent230711,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "90",
      openAt: "2023-07-11T00:00:00Z",
      ...notice.adio230711,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "91",
      openAt: "2023-07-24T00:00:00Z",
      ...notice.oMEGApool230724,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "92",
      openAt: "2023-08-08T00:00:00Z",
      ...notice.npEthpool230808,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "93",
      openAt: "2023-08-25T12:20:00Z",
      ...notice.maintenance230825,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "94",
      openAt: "2023-08-29T00:00:00Z",
      ...notice.governanceOpen230829,
    },
    {
      result: 0,
      resultString: "Success",
      desc: "",
      id: "95",
      openAt: "2023-09-15T00:00:00Z",
      ...notice.serviceUpdate230915,
    },
  ];
  return result.find((v) => v.id === notice_number.toString());
  // return request<INoticeDetail>(url, option);
};
