/* eslint-disable import/no-anonymous-default-export */
export const notice220210 =  {
  ko: {
    subject: "[안내] 사용자 증가에 따른 간헐적 접속 장애 발생 (조치완료)",
    body: `안녕하세요. 네오핀입니다. 
2월 10일 오전 6시 30분 경 사용자 증가에 따른 일시적 트래픽 증가 문제가 발생하며 간헐적 접속 장애가 있었습니다.
오전 8시 경 장애 부분은 모두 복구 조치를 완료하여 현재 정상 이용이 가능합니다.
이용자님들에게 불편함을 끼쳐 드린 점 진심으로 사과의 말씀 올립니다.
동일 문제를 예방하기 위해 재발 방지 대책을 철저히 세우고 안정적인 운영을 위해 더욱 노력하겠습니다. 
감사합니다.

네오핀 드림
    `,
  },
  en: {
    subject:
      "[Notice] Temporary network error due to traffic congestion (Network normalized)",
    body: `We have experienced a temporary connection error at around 6:30AM, Feb. 
    10th [KST] due to a rapid increase in users that briefly caused traffic congestion. 
    Please be informed that the network has been normalized since approximately 8AM, Feb. 10th [KST], and all services are up and running again.

We apologize for any inconvenience and will strive for a more stable operation to provide our users with the best experience possible.

Team NEOPIN
    `,
  },
};
