export const zemitEnd230502 = {
  en: {
    subject: "[Notice] Termination of the ZEMIT product service",
    body: `Please be informed that the services for ZEMIT-related products, including swap and liquidity provision rewards, have officially ended as of May 2, 2023 UTC.
    Please refer to your investment and asset management.
    
    [ Subject to service termination ]
    - ZEMIT-NPT Pool
    - ZEMIT-NPT Swap
    
    [ Service Termination Schedule ]
    - 06:00:00 on May 2nd, 2023 UTC
    
    [ Details ]
      (1) ZEMIT-NPT Pool: Unable to deposit new pool, suspension of reward payment, and continue to provide pool withdrawal feature
      (2) ZEMIT Swap Not Supported
    

    Thank you for your continuous support for NEOPIN.
    
      
    `,
  },
};
