export const gateIoListing220330 = {
  ko: {
    subject: "[상장] 네오핀 토큰(NPT) 게이트아이오 거래소 상장",
    body: `안녕하세요.
블록체인 오픈플랫폼 네오핀입니다.

‘네오핀 토큰(NPT)’이 게이트아이오 거래소에 오는 4월 1일(금) 상장합니다. 3월 30일(수) 한국시간 오후 2시에 입금이 개시되며, 4월 1일(금) 오후 6시에 NPT/USDT 거래 페어가 개시될 예정입니다.

1. 거래소 : Gate.io (게이트아이오)
2. 토큰명 : NPT
3. 마켓 : NPT/USDT
4. 입금 개시 : 2022년 3월 30일(수) 오후 2:00 (KST)
5. 거래 개시 : 2022년 4월 1일(금) 오후 6:00 (KST)
`,
  },
  en: {
    subject: "[Listing] Neopin Token(NPT) listing on Gate.io",
    body: `Hi, we are NEOPIN, an open blockchain platform for all.

Another thrilling piece of news brought to you today: NPT will be listed on Gate.io!

Deposit will open at 5:00 AM (UTC) on Mar. 30th, 2022, and the trading pair for NPT-USDT will begin at 9:00 AM (UTC) on Apr. 1st, 2022.

New listing on “Gate.io” :
1. Exchange : Gate.io
2. Name of Token : NPT
3. Market : NPT/USDT
4. Opening Date of Deposit : Mar. 30th, 2022. 5:00 AM (UTC)
5. Opening Date of Trading Pair : Apr. 1st, 2022. 9:00 AM (UTC)
`,
  },
};