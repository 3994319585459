/* eslint-disable import/no-anonymous-default-export */
export const sheepFarm221128 = {
  //// 영문만 배포
  //   ko: {
  //     subject: "[소식] 네오핀 x 쉽팜 1,000 NPT & 베팅티켓 에어드랍 사전 안내",
  //     body: `안녕하세요, 네오핀입니다.

  //     쉽팜 인 메타랜드의 네오핀 온보딩을 기념해 NPT & 베팅티켓 에어드랍을 선보입니다.

  //     지난 7월 파트너십을 체결한 쉽팜 인 메타랜드는 Sheep Farming과 Sheep Racing을 즐길 수 있는 P2E virtual world인데요.

  //     앞으로 네오핀 앱을 통해 쉽팜 인 메타랜드와 연동해 플레이할 수 있고, 특히 NPT를 통해 Sheep Racing에서 사용할 수 있는 베팅티켓을 구매할 수 있게 됐습니다.

  //     이를 기념해 풍성한 이벤트를 개최하니, 아래 내용 참고하셔서 꼭 참여해 보세요!

  //     [네오핀 x 쉽팜 인 메타랜드 1,000 NPT & 베팅티켓 에어드랍]
  //     - 이벤트 기간: 2022.12.2(금) 14:00:00 ~ 12.16(금) 14:59:59
  //     - 에어드랍: 총 1,000 NPT, 참여자 전원 주당 베팅티켓 10장(최대 20장)
  //     - 참여 방법: 글림폼 미션 수행 & 이벤트 레이싱 참여 (추후 공지 예정)

  //     ※ KYC 인증 소요 시간을 고려해 이벤트 시작 전에 미리 앱 다운로드 후, 회원가입 및 KYC 완료를 권장드립니다.

  //     [주의사항]
  //     이벤트 기간 내 KYC 처리가 완료되지 않을 경우 응모가 불가능합니다.

  //     그럼, 12월 2일, 이벤트 시작을 알리는 공지로 다시 찾아오겠습니다!
  // `,
  //   },

  en: {
    subject:
      "[Announcement] NEOPIN x Sheepfarm 1,000 NPT & Betting Ticket Airdrop Pre-notice",
    body: `To celebrate Sheepfarm’s onboarding of NEOPIN, we will be dropping NPT and betting tickets.

    This July, we signed a partnership with Sheepfarm, a P2E virtual world in which users can “SheepFarming” and “SheepRacing”.
    
    Users will be able to connect the NEOPIN Wallet with Sheepfarm, and use NPT to buy betting tickets within Sheepfarm.
    
    Please make sure to read the following information and participate in the event!
    
    [NEOPIN x Sheepfarm 1,000 NPT & Betting Ticket Airdrop Event]
    - WHEN: 2022.12.2(Fri.) 05:00:00 ~ 12.16(Fri.) 05:59:59 (UTC)
    - REWARDS: Total of 1,000 NPT & up to 20 betting tickets each for all race participants (10 per week)
    - HOW: Gleam tasks & take part in a race (Details to follow soon)
    
    ※ KYC verification may take up to three working days. We recommend you to download the app and complete the process before the event starts.
    
    [Precautions]
    Those who do not complete NEOPIN’s KYC verification within the event period will be considered ineligible. 
    
    We will be back with the event announcement on 12/2(Fri.) so stay tuned!    
`,
  },
};
