/* eslint-disable import/no-anonymous-default-export */
export const neopinReleased = {
  ko: {
    subject: "NEOPIN 가입 이벤트",
    body: `안녕하세요. 블록체인 오픈플랫폼 ‘네오핀’입니다.

네오핀에서 ‘가입 이벤트’를 준비했습니다.
구글 플레이스토어, 애플 앱스토어에서 ‘네오핀’ 혹은 ‘NEOPIN’ 검색 후 다운로드 받고, 
KYC 심사 인증까지 완료하면 행운의 럭키세븐! 7 NPT(네오핀 토큰)를 드립니다.

[이벤트 상세 내용]
1) 이벤트 기간 
● 2022년 2월 7일(월) 00:00:00 ~ 13일(일) 23:59:59 (한국 시간 기준)

2) 참여방법
● 구글 플레이스토어 혹은 애플 앱스토어에서 ‘네오핀’ 혹은 ‘NEOPIN’ 검색
● 앱 다운로드 → 가입 절차 → KYC 인증까지 완료하면 이벤트 자동참여 완료 
※ 가입 상세 안내는 네오핀 가입하기 미디엄 포스팅에서 확인하시면 됩니다. 
※ 가입 이벤트 전에 가입한 이용자님들도 7 NPT를 리워드로 지급할 계획입니다.

3) 지급일정
● 2022년 3월 31일(목) 이후 '미수령 보상' 탭에서 수령 가능

[이벤트 유의사항 안내]
● KYC 인증까지 최종 승인받아야 가입이 완료되고, 이벤트 참여에 대한 보상을 수령할 수 있습니다. 
● KYC 인증은 최초 1회만 인정됩니다. 
● 보상 지급은 2022년 3월 31일(목)에 진행될 예정입니다.
● 수령한 보상금은 ‘미수령 보상’ 탭에서 확인할 수 있습니다. 단, ‘미수령 보상’ 탭에서 보상금을 수령하기 전에 네오핀 서비스 탈퇴 시, 보상금을 받을 수 없습니다.
● 비정상적이거나 불법적인 방법으로 이벤트 참여 및 이벤트 운영에 방해되는 행위를 한 경우 보상 대상에서 제외될 수 있습니다.
● 탈퇴한 사용자는 재가입을 할 수 없으며, 계정 정보 및 서비스에서의 모든 정보가 삭제되어 복구할 수 없습니다.
● 데이터 수집 및 이벤트 시작, 종료 시간은 네오핀 서버 시간 기준입니다. 스마트폰 시간과 일부 오차가 발생할 수 있는 점 유의 부탁드립니다.
● 본 이벤트는 당사의 사정에 따라 사전 고지 없이 언제든 변경되거나 종료될 수 있습니다.
    `,
  },
  en: {
    subject: "NEOPIN Join Event",
    body: `Hi, this is an open blockchain platform ‘NEOPIN’.

We have an awesome event ready for you as a warm welcome to the world of NEOPIN:

Just downloading NEOPIN on Google Play Store or App Store and completing your KYC verification will get you free 7 NPT!

[Event Details]

1) When is the event?
● Feb. 7th, 2022 (Mon) 00:00:00AM ~ Feb. 13th, 2022 (Sun) 11:59:59PM (Korea Standard Time)

2) How do I join?
● Search "NEOPIN" on Google Play Store or App Store.
● Download the app → Sign up → Complete KYC verification → You’re automatically eligible for the reward!
※ Check out our Medium post titled “How to Sign up on NEOPIN” for details.
※ Users who have already signed up before the membership event will also be rewarded 7 NPTs.

3) When can I receive my reward? 
● Mar. 31st, 2022 (Thu) [KST]
(You can view your reward status on the "Remaining Reward" tab on the app.)

[Notice]

● The reward will be given only after the user is KYC verified, which is a final step for the sign-up. 
● KYC verification is only accepted once. 
● Rewards will be awarded on Mar. 31st, 2022 [KST].
● Users can view their reward status on the “Remaining Reward” tab. However, if they withdraw from their account before receiving rewards, all rewards will be revoked. 
● Withdrawn users are not eligible for sign-up, and all the information in their accounts and services will be deleted and cannot be recovered. 
● If a user is involved in any transaction considered abnormal or illegal, the user may be excluded from the event.
● Data collection, opening and closing of the event, etc will be based on the NEOPIN server time. Please note that there may be a slight time difference with the standard time.
● This event is subject to change or cancellation at any time without prior notice, depending on the company’s circumstances.
    `,
  },
};
