export const s2tEnd230327 = {
  en: {
    subject: "[Notice] Scheduled on Termination of the S2T product service",
    body: `NEOPIN will no longer provide S2T-related products including its swap features and rewards for providing liquidity on April 10th 2023.
    Please refer to your investment and asset management.
    
    [ Subject to service termination ]
    - S2T-NPT Pool
    - S2T-NPT Swap
    
    [ Service Termination Schedule ]
    - Until 09:59:59 on April 10, 2023 UTC : Service available normally
    
    - From 10:00:00 on April  10, 2023 UTC:
      (1) S2T-NPT Pool: Unable to deposit new pool, suspension of reward payment, and continue to provide pool withdrawal feature
      (2) S2T Swap Not Supported
    
    Thank you for your continuous support for NEOPIN.
    `,
  },
};
