export const korbitListing220526 = {
  ko: {
    subject: `[상장] 네오핀 토큰(NPT) 코빗 거래소 상장`,
    body: `안녕하세요.
블록체인 오픈플랫폼 네오핀입니다.

‘네오핀 토큰(NPT)’이 코빗 거래소에 5월 26일(목) 상장합니다. 5월 25일(수) 한국시간 오후 12시에 입금이 개시되며, 26일(목) 오후 12시에 NPT/KRW 거래가 개시될 예정입니다.

1. 거래소 : 코빗 (korbit)
2. 토큰명 : NPT
3. 마켓 : NPT/KRW
4. 입금 개시 : 2022년 5월 25일(수) 오후 12:00 (KST)
5. 거래 개시 : 2022년 5월 26일(목) 오후 12:00 (KST)

`,
  },
  en: {
    subject: `[Listing] NEOPIN Token(NPT) listing on korbit`,
    body: `Hi, we are NEOPIN, an open blockchain platform for all.

We are pleased to announce that NPT will be listed on korbit!

Deposit will open at 3:00 AM (UTC) on May 25, 2022, and the trading pair for NPT-KRW will begin at 3:00 AM (UTC) on May 26, 2022 (UTC).

New listing on “korbit” :
1. Exchange : korbit
2. Name of Token : NPT
3. Market : NPT/KRW
4. Opening Date of Deposit : May 25, 2022. 3:00 AM (UTC)
5. Opening Date of Trading Pair : May 26, 2022. 3:00 AM (UTC)

`,
  },
};
