export const forceWithdraw220801 = {
  ko: {
    subject: `[공지] 서비스 업데이트 및 신규 서비스 런칭 안내`,
    body: `안녕하세요. 네오핀입니다.

    유연한 상품 확장과 보안 강화를 위하여 서비스 업데이트를 진행했습니다.
    
    - 업데이트 일시: 2022.08.01 오후 2:00
    
    - 내용
     1) 풀 업데이트에 따른 V2 버전 전환
       * 업데이트 이후부터 기존 풀(V1풀)에서는 보상 지급이 중단되고 유동성 공급이 제한될 예정입니다.
        * 지속적인 유동성 공급과 보상 지급을 받기 위해서는 새로운 풀(V2풀)로 자산 이동이 필요합니다.  
        * 자산 인출이 이뤄지지 않을 시, 서비스를 정상적으로 이용하기 어렵습니다.
    
      2) 신규 풀 추가 및 스왑 지원
         * 신규 풀: KLAY-NPT, BORA-NPT, MBX-NPT 등 총 9개
         * 스왑 서비스는 크로스 스왑을 지원하기때문에 여러번의 스왑을 거치지 않고 한번에 이용 가능합니다.
    
    
    항상 네오핀을 이용해주셔서 감사합니다.
    
`,
  },
  en: {
    subject: `[Notice] Major Update and New Service Launch`,
    body: `Please be informed that NEOPIN has performed a major update for security reinforcement and expansion of services.

    - Date: August 1, 2022 5:00am (UTC)
    
    - Details
    1) Update to V2 Pool 
        * Reward compensation for the previous V1 Pool is terminated and its liquidity supply restricted from the point of update. 
        * Asset transfer from V1 Pool to the new V2 Pool is necessary for continued liquidity supply and rewards.
        * Service use will be restricted without asset withdrawal from V1 Pool. 
    
    2) Addition of New Pools and Swaps
        * New pools: 9 pools including KLAY-NPT, BORA-NPT, MBX-NPT etc. 
        * With cross-swaps available, you may swap tokens in a single transaction. 
    
    We appreciate your interest in NEOPIN.
`,
  },
};
