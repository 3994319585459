/* eslint-disable import/no-anonymous-default-export */
export const community220223 = {
  ko: {
    subject: "[종료] 텔레그램 입장하고 커피쿠폰 받자!",
    body: `안녕하세요. 블록체인 오픈플랫폼 ‘네오핀’입니다.

많은 성원에 힘입어 커뮤니티 이벤트 하루만에 많은 분들이 참여해주셨습니다.
커뮤니티 유입 이벤트는 2월 24일 00시(자정) 기점으로 종료되었습니다.
자정 넘어 가입해주신 분들에게 양해의 말씀 올립니다.

참고로 전화번호 1개당 1개의 쿠폰만 지급되는 점 꼭 참고 부탁 드립니다.
커뮤니티 회원님들 대상 이벤트 많이 기획할 수 있도록 하겠습니다.

친구초대 이벤트는 계속해서 진행 중이오니 많은 참여 바랍니다.
아울러 3월 3일 예정 중인 ETH-NPT AFO도 많은 관심 부탁드립니다.

감사합니다.
네오핀드림

===

안녕하세요. 블록체인 오픈플랫폼 ‘네오핀’입니다.

네오핀에서 AFO(Airdrop Farm Offering)을 앞두고 ‘커뮤니티 입장’ 이벤트를 준비했습니다.
네오핀 텔레그램 공식 한국 커뮤니티에 입장하고 신청서를 제출하면 전원 스타벅스 커피 쿠폰을 드립니다.

[이벤트 상세 내용]
1) 이벤트 기간
● 2월 23일(수) ~ 3월 2일(수) (한국 시간 기준)

2) 참여방법
● 네오핀 텔레그램 공식 한국 커뮤니티 입장
● 상단 고정 공지를 통해 신청서 제출
● 텔레그램 아이디, 전화번호를 입력 후 제출하면 전원 스타벅스 커피 쿠폰을 드립니다.

3) 지급일정
2022년 3월 9일(수) 지급 예정

[커뮤니티 입장 이벤트 유의사항 안내]
● 이벤트는 2월 23일(수)부터 3월 2일(수)까지 진행됩니다.
● 본 이벤트는 한 개의 전화번호 당 한 번의 참여만 가능합니다.
● 스타벅스 커피 쿠폰은 신청서에 제출한 전화번호로 문자 발송됩니다.
● 쿠폰은 당사의 사정에 따라 사전 고지 없이 동일한 가치의 기프티콘으로 변경될 수 있습니다.
● 쿠폰 지급은 3월 9일(수)에 진행될 예정입니다.
● 정상적으로 신청서를 제출하였어도 네오핀 텔레그램 공식 채널에서 쿠폰 지급일 이전에 이탈 시 보상 지급에서 제외됩니다. 
● 비정상적이거나 불법적인 방법으로 이벤트 참여 및 이벤트 운영에 방해되는 행위를 한 경우 보상 대상에서 제외될 수 있습니다.
● 본 이벤트는 당사의 사정에 따라 사전 고지 없이 언제든 변경되거나 종료될 수 있습니다. 
    `,
  },
  en: {
    subject: "[종료] 텔레그램 입장하고 커피쿠폰 받자!",
    body: `안녕하세요. 블록체인 오픈플랫폼 ‘네오핀’입니다.

네오핀에서 AFO(Airdrop Farm Offering)을 앞두고 ‘커뮤니티 입장’ 이벤트를 준비했습니다.
네오핀 텔레그램 공식 한국 커뮤니티에 입장하고 신청서를 제출하면 전원 스타벅스 커피 쿠폰을 드립니다.

[이벤트 상세 내용]
1) 이벤트 기간
● 2월 23일(수) ~ 3월 2일(수) (한국 시간 기준)

2) 참여방법
● 네오핀 텔레그램 공식 한국 커뮤니티 입장
● 상단 고정 공지를 통해 신청서 제출
● 텔레그램 아이디, 전화번호를 입력 후 제출하면 전원 스타벅스 커피 쿠폰을 드립니다.

3) 지급일정
2022년 3월 9일(수) 지급 예정

[커뮤니티 입장 이벤트 유의사항 안내]
● 이벤트는 2월 23일(수)부터 3월 2일(수)까지 진행됩니다.
● 본 이벤트는 한 개의 전화번호 당 한 번의 참여만 가능합니다.
● 스타벅스 커피 쿠폰은 신청서에 제출한 전화번호로 문자 발송됩니다.
● 쿠폰은 당사의 사정에 따라 사전 고지 없이 동일한 가치의 기프티콘으로 변경될 수 있습니다.
● 쿠폰 지급은 3월 9일(수)에 진행될 예정입니다.
● 정상적으로 신청서를 제출하였어도 네오핀 텔레그램 공식 채널에서 쿠폰 지급일 이전에 이탈 시 보상 지급에서 제외됩니다. 
● 비정상적이거나 불법적인 방법으로 이벤트 참여 및 이벤트 운영에 방해되는 행위를 한 경우 보상 대상에서 제외될 수 있습니다.
● 본 이벤트는 당사의 사정에 따라 사전 고지 없이 언제든 변경되거나 종료될 수 있습니다. 
    `,
  },
};
