export const bravenineAirdrop220412 = {
  ko: {
    subject: "[이벤트] 브레이브나인(BRAVE NINE) KLAY 에어드랍 이벤트",
    body: `안녕하세요.
블록체인 오픈플랫폼 네오핀입니다.

브레이브나인(BRAVE NINE)에서 KLAY 에어드랍 이벤트를 진행합니다.
<a href="https://t.me/BraveNineofficial/3">https://t.me/BraveNineofficial/3</a>

네오위즈, 클레이튼 그리고 네오핀은 브레이브나인(BRAVE NINE) 출시 전 마케팅의 일환으로 네오핀 지갑 보유자 10,000명을 대상으로 총 15만 달러 상당의 KLAY 경품을 지급하는 대규모 에어드랍 이벤트를 진행합니다.

※ ‘브레이브나인(BRAVE NINE)’은 국내에서 서비스 중인 ‘브라운더스트’에 플레이 투 언 (Play to Earn) 요소를 접목한 글로벌 버전 게임입니다. 클레이튼(Klaytn) 메인넷 상에 구축, 블록체인 오픈플랫폼 네오핀(NEOPIN)을 기반으로 서비스됩니다.

참여 기간 : 2022년 4월 12일(화) ~ 18일(월)

참여 방법 :
● 이벤트 페이지에 소개된 과제 완료
● 네오핀 지갑 Klaytn 주소 등록
● 10,000명 럭키 추첨 후 네오핀 지갑에 Klay 에어드랍

※ 이벤트 참여하기
브레이브나인 이벤트 폼 바로가기
<a href="https://gleam.io/LBOs6/brave-nine-klay-airdrop-campaign">https://gleam.io/LBOs6/brave-nine-klay-airdrop-campaign</a>

지급 일정 : 2022년 4월 20일(수) 
`,
  },
  en: {
    subject: "[EVENT] BRAVE NINE KLAY Airdrop Event",
    body: `Hi, this is NEOPIN, an open blockchain platform for all.

BRAVE NINE is hosting a KLAY airdrop campaign.
<a href="https://t.me/BraveNineofficial/3">https://t.me/BraveNineofficial/3</a>

NEOWIZ, KLAYTN, and NEOPIN are hosting a large-scale airdrop event to give out KLAY prizes worth a total of $150,000 to 10,000 NEOPIN Wallet holders as part of their pre-launch marketing for BRAVE NINE.

※ BRAVE NINE is a global version of the game that combines the Play to Earn element with BROWN DUST, a game currently in service in Korea. BRAVE NINE is built on the Klaytn mainnet, and will be serviced based on the open blockchain platform NEOPIN.

1) Period of the Event
April 12 (Tue) ~ 18 (Mon), 2022

2) How to participate
● Complete the tasks introduced on the event page
● Register Klaytn address on NEOPIN Wallet
● KLAY airdrop to NEOPIN Wallet after 10,000 lucky draw

※ Participate in the event
<a href="https://gleam.io/LBOs6/brave-nine-klay-airdrop-campaign">https://gleam.io/LBOs6/brave-nine-klay-airdrop-campaign</a>

3) Reward Schedule
Winners will be announced along with the token distribution on April. 20th, 2022.
`,
  },
};
