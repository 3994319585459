import { Link } from 'react-router-dom';
import LogoImg from '../../assets/img/logo-w.png';
import '../../style/page-header.scss';
import LanguageNav, { ILanguageListItem } from '../LanguageNav';

interface IProps {
  pageTitle?: string;
  titleLink?: string;
  langList?: ILanguageListItem[];
}
const PageHeader: React.FC<IProps> = ({ pageTitle, titleLink, langList }) => {
  return (
    <div className="page-header">
      <div className="nav-bar">
        <div className="nav-bar-wrapper">
          <Link to={'/'} className="home-link">
            <img src={LogoImg} alt="neopin"/>
          </Link>
          {langList && <LanguageNav langList={langList} />}
        </div>
        { pageTitle && titleLink &&
          <div className="page-title">
            <Link to={titleLink}>{pageTitle}</Link>
          </div>
        }
      </div>
    </div>
  );
};

export default PageHeader;
