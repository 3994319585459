export const ipvEnd230109 = {
  ko: {
    subject: "[공지] IPV 상품 서비스 종료 안내",
    body: `안녕하세요? 네오핀입니다.

    네오핀에서 IPV 관련 상품의 서비스가 2023년 1월 9일부로 종료됩니다.
    해당 상품 서비스가 종료된 이후에는 IPV-NPT 스왑 기능을 제공하지 않으며, 리워드가 더 이상 발생하지 않게 되오니 사용자님의 투자 및 자산 관리에 참고하시기 바랍니다.
    
    [ 서비스 종료 대상 상품 ]
    IPV Stake
    IPV-NPT Yield Farm
    IPV-NPT Swap
    
    [ IPV 상품 서비스 종료 세부 사항 ]
    - 2023년 1월 9일 15:00:00 부터 :
      (1) IPV Stake : 신규 스테이킹 불가, 리워드 지급 중단, 언스테이킹 가능
      (2) NPT-IPV Yield Farm : 신규 풀 예치 불가, 리워드 지급 중단, 풀 인출 가능
      (3) IPV Swap 미지원
    - IPV 자산의 보관 및 입금, 전송 기능은 유지되오니 이용에 참고해 주시기 바랍니다.
    
    항상 네오핀을 이용해주셔서 감사합니다.
    
  `,
  },

  en: {
    subject: "[Notice] Termination of IPV product support",
    body: `NEOPIN will no longer support IPV-related products including swap and reward accrual features starting from January 9th 2023.
    Please keep the changes in mind for your investment and asset management plans.
    
    [ Products subject to service termination ]
    - IPV Stake
    - IPV-NPT Yield Farm
    - IPV Swap
    
    [ Details on service termination ]
    - From 06:00:00 on January 9, 2023 UTC:
      (1) IPV Stake: Cease new staking and reward payment, unstaking possible
      (2) NPT-IPV Yield Farm: Cease new deposits to pool and reward payment, withdrawal possible
      (3) IPV Swap Not Supported
    - Storing, depositing, and transferring IPV assets will still be supported.
    
    Thank you for your continuous support for NEOPIN.
    
`,
  },
};
