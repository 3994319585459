import { useEffect } from "react";
import styled from "styled-components";

const IntroDeck: React.FC<{ lang: string }> = ({ lang }) => {
  return (
    <PDFHolder>
      <iframe
        title="docs/neopin_vision_paper_v2.0-cp_1.pdf"
        src="https://docs.google.com/gview?url=https://neopin.io/docs/neopin_vision_paper_v2.0-cp_1.pdf&embedded=true"
      ></iframe>
    </PDFHolder>
  );
};

const PDFHolder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100%;
  iframe {
    width: 100%;
    height: 100%;
  }
`;

export default IntroDeck;
