export const ozysPartnership230306 = {
  en: {
    subject: "[Partnership] NEOPIN signs partnership with Ozys",
    body: `NEOPIN has signed a partnership with Ozys, a leading blockchain technology company, to leap forward globally and expand the ecosystem.

    On 6 March, the NPT-TON pool will be launched on Megaton Finance, an AMM-DEX recently released by Ozys and a 1,000 TON airdrop event will be followed at 03:00 UTC and 04:00 UTC, respectively. The airdrop will be given to the first 500 users who send at least $100 worth of polygon-based NPT to the TON Wallet, TonHub or Tonkeeper.
    
    Beginning with this event, NEOPIN and Ozys will work on establishing a strong cooperation to support and expand both parties’ ecosystems. As such, both parties will collaborate on blockchain based services including NEOPIN Wallet and Meshswap. Plus, Megaton Finance will be linked to NEOPIN and its liquidity will be provided to NEOPIN in the future.
    
    Thank you.    
    
`,
  },
};
