export const meshSwap230419 = {
  en: {
    subject: "[Announcement] NEOPIN Adds MESH token on Swap Pool",
    body: `NEOPIN has added new token $MESH and Swap Pool pairs to its Polygon based services:

    - Meshswap Protocol (MESH) Token
    - NPT-MESH Swap Pool
    
    With these new products, you can deposit and withdraw MESH tokens into your NEOPIN wallet, provide liquidity to your liquidity pool, or easily exchange them for other tokens.
    
    Thank you for your continuous support for NEOPIN.
    
    `,
  },
};
