export const adio230711 = {
  ko: {
    subject:
      "[소식] 네오핀, UAE 아부다비 투자진흥청(ADIO) 혁신 프로그램 지원 선정",
    body: `네오핀이 아랍에미리트(UAE)의 아부다비 투자진흥청(Abu Dhabi Investment Office, ADIO) 혁신 프로그램 지원사로 선정되었습니다.

    이를 통해 UAE의 국제금융자유구역인 ADGM(Abu Dhabi Global Market)과 협력해 디파이 산업을 위한 효율적이고 효과적인 규제 프레임워크를 함께 구축, 세계 최초 규제 인증 DeFi로서 글로벌 시장을 선도해나갈 계획입니다.
    
    또한 향후 ADGM의 금융혁신체 Digital Lab에 참여, 금융 서비스 규제당국(FSRA)과 긴밀히 협력해 금융 산업 및 소비자 리스크 완화, 소비자 경험을 개선할 수 있는 새로운 비즈니스 모델을 개발해 DeFi 산업의 프레임워크를 구축할 것입니다. 
    
    `,
  },
  en: {
    subject:
      "[Announcement] NEOPIN joins Innovation Programme of Abu Dhabi Investment Office (ADIO)",
    body: `We are pleased to announce that we have joined the Innovation Programme of Abu Dhabi Investment Office (ADIO), the entry point for investors in Abu Dhabi, the capital of the United Arab Emirates (UAE).

    We will work with Abu Dhabi Global Market (ADGM) for development of a sound and progressive DeFi framework with the aim of becoming one of the first regulated DeFi providers in the world. 
    
    We are looking to participate in ADGM’s Digital Lab and collaborate closely with their Financial Services Regulatory Authority (FSRA) to build a framework for the DeFi industry that both mitigates risks to consumers and the financial industry and lets new business models develop to improve consumer experiences and outcomes.
    
    `,
  },
};
