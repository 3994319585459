export const serviceUpdate20220725 = {
  ko: {
    subject: `[점검] 서비스 업데이트에 따른 점검 사전 안내`,
    body: `안녕하세요. 네오핀(NEOPIN) 입니다.

    네오핀은 보안 강화와 유연한 상품 확장을 위하여 풀 예치 관련 서비스 업데이트를 진행할 예정입니다. 업데이트 시간동안 점검을 진행하여 서비스가 중단될 예정이오니, 자산 예치 및 인출이 필요하신 경우 아래 일시를 참고하시기 바랍니다.
    
    - 일시: 2022.08.01 오후 2:00
    
    - 내용
     1) 풀 업데이트에 따른 V2 버전 전환
        * 지속적인 유동성 공급과 보상 지급을 받기 위해서는 새로운 풀(이하 ‘V2풀')로 자산 이동이 필요합니다.  
        * 업데이트 이후부터 V1풀에서는 보상 지급이 중단되고 유동성 공급이 제한될 예정입니다.
        * 자산 인출은 [지갑 > Balance(지갑잔고)]에서 가능합니다.
    
      2) 신규 풀 추가 및 스왑 지원
         * 신규 풀: KLAY - NPT, BORA - NPT 등 총 14개
         * 스왑 이용 시, 여러번의 스왑을 거치지 않고 한번에 이용 가능합니다.
    
    
    상기 내용은 공지사항에서도 확인이 가능합니다.
    
    항상 네오핀을 이용해주셔서 감사합니다.
    
`,
  },
  en: {
    subject: `Prior Notice on Service Maintenance for Update `,
    body: `Please be informed that NEOPIN will undergo a scheduled system maintenance and all services will be temporarily unavailable during the update. The purpose of this update is for security reinforcement and expansion of Yield Farm services. For more details, please see below. 

    - Date: August 1, 2022 2:00pm (KST)
    
    - Scheduled Updates
    1) Update to V2 Pool 
        * Asset transfer from the existing V1 Pool to the new V2 Pool is necessary for continued liquidity supply and rewards.
        * Reward compensation for V1 Pool will be terminated and its liquidity supply restricted from the point of update. 
        * Asset withdrawal from V1 Pool is available on [Wallet > Balance] tab. 
    
    2) Addition of New Pools and Swap Transactions
        * New pools: 14 pools including KLAY-NPT, BORA-NPT, etc. 
        * You may exchange tokens in a single transaction on one platform.
    
    You may also find the above information on our Notice.
    
    We appreciate your interest in NEOPIN.
    
`,
  },
};
