/** wallet connect에서 호출하는용도로 사용했으나, ios에서 링크 오픈 후 창이 남아있는 이슈가 있어 사용하지 않고 직접 Dynamic link를 호출하기로 결정 22.04.27 */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
const DOWNLOAD_INFO_PAGE = "https://neopin.io/download";

interface IProps {
  lang: string;
}

export interface IEventProps {
  lang: string;
}

const openLink = (link: string) => {
  window.location.replace(link);
};

const Event: React.FC<IProps> = ({ lang }) => {
  const DEEP_LINK = `https://neopin.page.link/?link=https://neopin.io&apn=com.blockchain.crypto.wallet.neopin&isi=1600381072&ibi=com.blockchain.crypto.wallet.neopin&ofl=${DOWNLOAD_INFO_PAGE}`;
  const [deepLink, setDeepLink] = useState<string>(DEEP_LINK);
  useEffect(() => {
    if (!URLSearchParams) {
      openLink(DEEP_LINK);
      return;
    }
    const searchParams = new URLSearchParams(document.location.search);
    const LINK_PARAM_KEY = "uri";
    const linkParamValue = searchParams.get(LINK_PARAM_KEY);
    const uriParamValue = searchParams.get("uri");
    const keyParamValue = searchParams.get("key");
    if (linkParamValue) {
      /** 다이나믹링크의 search param중 하나인 uri에 들어가는 값이 주소값인데,
       * 그 주소의 search param을 다이나믹링크의 param으로 생각하고 짤려서 base64인코딩을 했다. */
      const base64Encoded = btoa(`${uriParamValue}&key=${keyParamValue}`);
      const linkParamValueEncoded = `https://wc.neopin.link/wc?uri=${base64Encoded}`;
      const linkChanged = DEEP_LINK.replace(
        /link=\S+?(?=&)/,
        `link=${linkParamValueEncoded}`
      );
      setDeepLink(linkChanged);
      openLink(linkChanged);
    } else {
      openLink(DEEP_LINK);
    }
  }, [DEEP_LINK]);
  return (
    <div className={`app-link ${lang.toLowerCase()}`}>
      <Helmet>
        <link rel="alternate" href="ios-app://1600381072/neopin/open" />
        <link
          rel="alternate"
          href="android-app://com.blockchain.crypto.wallet.neopin/neopin/open"
        />
        <meta property="al:ios:url" content={deepLink} />
        <meta name="twitter:app:url:iphone" content={deepLink} />
        <meta property="al:ios:app_store_id" content="1600381072" />
        <meta name="twitter:app:id:iphone" content="1600381072" />
        <meta property="al:ios:app_name" content="Neopin" />
        <meta name="twitter:app:name:iphone" content="Neopin" />
        <meta property="al:android:url" content={deepLink} />
        <meta name="twitter:app:url:googleplay" content={deepLink} />
        <meta
          property="al:android:package"
          content="com.blockchain.crypto.wallet.neopin"
        />
        <meta
          name="twitter:app:id:googleplay"
          content="com.blockchain.crypto.wallet.neopin"
        />
        <meta property="al:android:app_name" content="Neopin" />
        <meta name="twitter:app:name:googleplay" content="Neopin" />
      </Helmet>
      {/* <a href={deepLink} id="app-link">
        네오핀 딥링크
      </a>
      <div>
        <a href={AOS_DEEP_LINK} id="play-store">
          플레이스토어링크
        </a>
      </div>
      <div>
        <a href={IOS_DEEP_LINK} id="app-store">
          앱스토어링크
        </a>
      </div>
      <div>열린 딥링크 주소: {deepLink}</div> */}
      <div
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          left: 0,
          top: 0,
        }}
      ></div>
    </div>
  );
};

export default Event;
