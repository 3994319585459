export const cgiRelease220428 = {
  ko: {
    subject: "[공지] 크립토 골프 임팩트 정식 출시",
    body: `안녕하세요.
블록체인 오픈플랫폼 네오핀입니다.

네오위즈의 첫번째 P2E 게임 ‘크립토 골프 임팩트’가 2022년 4월 28일 정식 출시됐습니다.

<크립토 골프 임팩트 소개>
크립토 골프 임팩트는 네오핀 플랫폼과 생태계가 적용된 P2E 모바일 골프 게임입니다.
플레이어는 ‘크립토 패스’를 통해 게임을 즐기며 ‘크리스탈’을 획득할 수 있고, ‘크리스탈’은 유틸리티 토큰 ‘S2토큰’으로 전환할 수 있습니다. S2토큰은 네오핀 앱에서 ‘NPT-S2T 스왑 풀’을 통해 NPT로 교환할 수 있습니다.
<a href="https://www.cryptogolfimpact.io/">▶ 크립토 골프 임팩트 홈페이지 바로가기</a>

<크립토 골프 임팩트 다운로드>
<a href="https://play.google.com/store/apps/details?id=com.neowiz.game.p2e.cryptogolfimpact">▶ 구글 플레이스토어</a>
<a href="https://apps.apple.com/ee/app/crypto-golf-impact/id1598281172">▶ 애플 앱스토어</a>

<정식 출시 기념 이벤트>
‘크리스탈’ 획득 가능한 ‘크립토 패스’ 1주간 50% 할인 이벤트 진행
누적 다운로드 25만 달성 시, 플레이어 전원 크리스탈 지급 예정 (추후 공지)

<NPT-S2T 스왑·유동성 풀 상품 오픈>
NPT-S2T 스왑 풀 : NPT<>S2T 간 스왑 가능
NPT-S2T 유동성 풀 : NPT-S2T 유동성 풀 예치 시, NPT 보상

네오위즈의 첫번째 P2E 게임 ‘크립토 골프 임팩트’와 네오핀에 많은 관심과 이용 부탁드립니다.

감사합니다.
`,
  },
  en: {
    subject: "[Notice] Crypto Golf Impact - Official Launch",
    body: `Greetings,
We are NEOPIN, an open blockchain platform for all.

NEOWIZ’s first P2E game Crypto Golf Impact has been officially launched on April 28th, 2022.

<Crypto Golf Impact - Introduction>
Crypto Golf Impact is a P2E mobile golf game integrated into NEOPIN’s blockchain platform and ecosystem. 
Players who hold “Crypto Pass” will be able to earn in-game “Crystals”, which can be converted into utility tokens called “S2 Tokens(S2T)”. “S2 Tokens” can be swapped for NPT through NEOPIN’s in-app “NPT-S2T Swap Pool”.
<a href="https://www.cryptogolfimpact.io/">▶ Go to Crypto Golf Impact’s Official Website</a>

<Download Crypto Golf Impact>
<a href="https://play.google.com/store/apps/details?id=com.neowiz.game.p2e.cryptogolfimpact">▶ Google Play Store</a>
<a href="https://apps.apple.com/ee/app/crypto-golf-impact/id1598281172">▶ Apple App Store</a>

<Official Launch Event>
“Crypto Pass”, which is needed to earn in-game “Crystals”, will be on a 50% discount for one week.
All players will be given “Crystals” at 250,000 cumulative downloads(To be announced).

<NPT-S2T Swap·Liquidity Pool Open>
NPT-S2T Swap Pool : NPT<>S2T Swap supported
NPT-S2T Liquidity Pool : NPT-S2T Liquidity providers rewarded with NPT

Thank you for your support and interest in NEOPIN and NEOWIZ’s first P2E game, “Crypto Golf Impact”.
`,
  },
};
