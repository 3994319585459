export const serviceMaintanace221209 = {
  ko: {
    subject: "[점검] 서비스 업데이트에 따른 점검 사전 안내 (완료)",
    body: `안녕하세요? 네오핀입니다.

    네오핀은 서비스 개선을 위해 업데이트를 진행할 예정입니다.
    업데이트 시간동안 점검 진행과 함께 서비스 이용이 잠시 중단될 예정이오니, 네오핀 이용에 있어 참고하시기 바랍니다.
    
    [ 일시 ]
    2022년 12월 14일(수) 14:00 ~ 17:00 (KST)
    
    [ 점검 내용 ]
      - 안정적인 서비스를 위한 전체 시스템 점검을 진행합니다.
      - 1.5.0 업데이트를 적용합니다.
    
    [ 1.5.0 업데이트 내용 ]
    1) 멀티 체인 지원
    - Polygon 네트워크의 토큰 이용이 가능합니다.
    
    2) 통합된 지갑 주소 지원
    - 통합된 1개의 지갑 주소로 Ethereum, Klaytn, Polygon 네트워크의 토큰을 이용할 수 있습니다.
    - Tron은 예외적으로 독립된 지갑 주소를 가집니다.
    - 기존 Ethereum 지갑 주소를 더이상 지원하지 않으며, 통합된 지갑 주소를 지원합니다.
    
    항상 네오핀을 이용해 주셔서 감사합니다.
          
  `,
  },

  en: {
    subject: "[Maintenance] System update notice (completed)",
    body: `NEOPIN will be going through maintenance to improve overall services.
    Please note that NEOPIN features will not be available during maintenance.
    
    We ask for your patience and understanding.

    [ Schedule ]
    2022/12/14(Wed.) 05:00 - 08:00 (UTC)
    
    [ Maintenance ]
    - Full inspection of the system for service stability
    - Implement 1.5.0 updates
    
    [ 1.5.0 Updates ]
    1) Multi-network chain support
    - Polygon tokens available in Neopin wallet.
    
    2) Wallet address integration
    - Tokens from Ethereum, Klaytn, and Polygon networks are available for one integrated wallet address.
    - TRX has an exceptionally independent wallet address.
    - Existing Ethereum wallet addresses are no longer supported; integrated wallet addresses are supported.
    
    Thank you for your continuous support for NEOPIN.
    
`,
  },
};
