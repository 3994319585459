/* eslint-disable import/no-anonymous-default-export */
export const nftairdropend221115 = {
  ko: {
    subject:
      "[소식] 네오핀 × LILLIUS 유틸리티 NFT 단독 에어드랍 이벤트 종료 안내",
    body: `안녕하세요, 네오핀입니다.

    네오핀과 LILLIUS가 함께한 스포츠 NFT 단독 에어드랍 이벤트가 종료되었습니다.
    
    당첨자는 2022년 11월 22일(화) 17:00(KST) LILLIUS의 트위터, 텔레그램, 디스코드 채널을 통해 공지될 예정입니다.
    
    참여자 여러분들의 많은 성원과 관심에 감사드립니다.
    
    감사합니다.
`,
  },

  en: {
    subject:
      "[Announcement] NEOPIN × LILLIUS Exclusive NFT Airdrop Event Completion Notice",
    body: `NEOPIN and LILLIUS’ joint sports NFT airdrop event has been completed. 

    Winners will be announced through LILLIUS’ official Twitter, Telegram, and Discord channels on Tuesday, Nov. 22, 2022 at 08:00 UTC. 
    
    We thank you for your support and participation.
`,
  },
};
