export const polygonNetwork221219 = {
  ko: {
    subject: "[소식] 멀티체인 지원 확대, 폴리곤 네트워크 토큰 추가 안내",
    body: `안녕하세요, 네오핀입니다.

    네오핀이 클레이튼, 이더리움, 트론에 이어 폴리곤을 지원한다는 반가운 소식을 전해드립니다.
    
    네오핀은 지난 8월, 2023년 상반기까지 BNB체인, 코스모스 등 다양한 체인을 도입하여 멀티체인을 확대해 나가겠다는 계획을 발표한 바 있는데요.
    
    이번 폴리곤 지원 소식은 네오핀이 빠른 속도로 성장하는 폴리곤 생태계에 시의적절하게 합류하여 글로벌 폴리곤 커뮤니티와 만나게 되었다는 점에서 의미가 있습니다.
    
    뿐만 아니라, 네오핀은 최근 폴리곤 기반 NFT 마켓플레이스 원플래닛과 파트너십을 체결하고, NFT 2차 거래 시 NPT를 활용하는 방안을 추진하고 있는데요.
    
    위와 같이 적극적인 멀티체인 확대, 파트너십 체결 등으로 생태계 확장을 위해 달려나가고 있는 네오핀에 많은 응원과 관심 부탁드립니다.
    
    앞으로도 반가운 소식을 전해드릴 수 있도록 최선을 다해 노력하는 네오핀이 되겠습니다.
    
    감사합니다.
    

  `,
  },

  en: {
    subject:
      "[Announcement] NEOPIN integrates Polygon for multi-chain expansion.",
    body: `We are happy to announce that NEOPIN will add support for Polygon, following Klaytn, Ethereum and Tron. 

This August, NEOPIN had announced plans for the first half of 2023 to expand its multi-chain support by adding various chains such as BNB Chain and Cosmos. 

NEOPIN went ahead of schedule and decided to join Polygon early to take part in Polygon’s thriving ecosystem. 

As the first step in marking its presence in the Polygon ecosystem, NEOPIN recently signed a partnership with OnePlanet, a Polygon-based NFT marketplace. This partnership will allow NFT transactions on OnePlanet through NPT.

We ask for your support as NEOPIN continues to build and expand its ecosystem.

Please stay tuned for more exciting news.

Thank you.

`,
  },
};
