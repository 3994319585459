import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import download from './download.json';
import common from './common.json';
import home from './home.json';

const resources = {
  en: {
    common: common.en,
    download: download.en,
    home: home.en
  },
  ko: {
    common: common.ko,
    download: download.ko,
    home: home.ko
  }
}

i18n.use(initReactI18next).init(
  {
    resources,
    lng: "en",
    interpolation: {
      // react already safes from xss
      escapeValue: false,
    },
  },
  (err, t) => {
    if (err) {
      console.error(err)
    }
  }
);

export default i18n;