/* eslint-disable import/no-anonymous-default-export */
export const friendInvite220223 =  {
  ko: {
    subject: "[종료] 초대코드 공유하고 NPT 받자!",
    body: `안녕하세요. 블록체인 오픈플랫폼 ‘네오핀’입니다.

    네오핀 ‘친구초대’ 이벤트가 종료되었습니다.
    참여해주신 모든 이용자님께 감사의 말씀 올립니다.
    
    이벤트 참여 조건은 3월 2일(수) 오후 6시까지 초대코드 입력 후 KYC 심사를 요청하신 이용자님입니다. 
    오늘 오후 6시 이전에 초대코드를 입력하고 가입을 신청했지만, 오후 6시 이후에 KYC 최종 승인 처리된 분들도 이벤트 참여 대상입니다.
    
    추후 더욱 풍성한 이벤트로 찾아뵙겠습니다.
    감사합니다.
    
    ====================
    
    안녕하세요. 블록체인 오픈플랫폼 ‘네오핀’입니다.
    
    네오핀에서 ‘친구초대’ 이벤트를 준비했습니다.
    친구가 내 초대코드를 통해 가입 후 KYC 인증 심사까지 완료하면 나와 친구 모두에게 1 NPT(네오핀 토큰)를 드립니다.
    
    [이벤트 상세 내용]
    1) 이벤트 기간
    ● 2022년 2월 23일(수) 10:00:00 ~ 3월 2일(수) 18:00:00 (한국 시간 기준)
    
    2) 참여방법
    ● 구글 플레이스토어 혹은 애플 앱스토어에서 ‘네오핀’ 혹은 ‘NEOPIN’ 검색하여 다운로드
    ● 네오핀 가입 및 초대코드 입력 후 KYC 인증 심사 완료
    ● 친구의 초대코드를 입력하면 1 NPT를 드립니다.
    ● 나의 초대코드를 공유하고 더 많은 NPT를 획득하시기 바랍니다.
    
    3) 지급일정
    2022년 4월 7일(목) 이후 ‘미수령 보상’ 탭에서 수령 가능
    
    [이벤트 유의사항 안내]
    ● 이벤트는 2월 23일(수) 오전 10시부터 3월 2일(수) 오후 6시까지 진행됩니다.
    ● 피추천인이 네오핀 앱 설치 후 초대코드 입력 및 KYC 인증까지 승인 받아야 친구 초대 이벤트 참여가 완료되고 보상도 수령할 수 있습니다.
    ● 추천인, 피추천인의 KYC 인증은 최초 1회만 인정됩니다.
    ● 탈퇴 후 재가입한 친구는 보상 대상에서 제외됩니다. 탈퇴한 사용자는 재가입을 할 수 없으며, 계정 정보 및 서비스에서의 모든 정보가 삭제되어 복구할 수 없습니다. 
    ● 이벤트 마감 전에는 무제한 친구 초대가 가능합니다.
    ● 보상 지급은 4월 7일(월) 이후에 진행될 예정입니다.
    ● 수령한 보상금은 ‘미수령 보상’ 탭에서 확인할 수 있습니다. 단, 추천인, 피추천인이 ‘미수령 보상’ 탭에서 보상금을 수령하기 전에 네오핀 서비스 탈퇴 시, 보상금을 받을 수 없습니다.
    ● 비정상적이거나 불법적인 방법으로 이벤트 참여 및 이벤트 운영에 방해되는 행위를 한 경우 보상 대상에서 제외될 수 있습니다.
    ● 데이터 수집 및 이벤트 시작, 종료 시간은 네오핀 서버 시간 기준입니다. 스마트폰 시간과 일부 오차가 발생할 수 있는 점 유의 부탁드립니다.
    ● 본 이벤트는 당사의 사정에 따라 사전 고지 없이 언제든 변경되거나 종료될 수 있습니다.    
    `,
  },
  en: {
    subject: "[Event Closed] Refer a friend & Earn 1NPT each!",
    body: `Hello, we are NEOPIN, an open blockchain platform for all. 

    Our Referral Event has now been closed!
    
    We appreciate your interest and support in NEOPIN. 
    
    Users who have entered Referral Codes and requested for the KYC verification by 9:00am, Mar. 2nd (Wed) [UTC] are eligible for the event. Those who have entered Referral Codes and requested for the KYC verification before 9:00am, Mar. 2nd (Wed) [UTC] and are KYC-verified after 9:00am, Mar. 2nd (Wed) [UTC] are still eligible for the event. 
    
    Please stay tuned to NEOPIN for upcoming events!
    
    Thank you.
    
    Team NEOPIN
    
    ====================
    
    Hello, we are NEOPIN, an open blockchain platform for all.
    
    We would like to introduce our first Referral Event. Both you and your friend will receive 1 NPT(Neopin Token) once your friend signs up on NEOPIN, enters your Referral Code, and completes KYC Verification.
    
    [Event Details]
    
    1) Event Period
    Feb. 23rd, 2022 (Wed.) 01:00:00 - Mar. 2nd, 2022 (Wed.) 09:00:00 [UTC]
    
    2) How to participate
    - Search “NEOPIN” on Google Play Store or App Store and download the app
    - Sign up on NEOPIN, enter Referral Code, and complete KYC Verification
    - Receive 1 NPT for entering Referral Code
    - Share your Referral Code, and earn more NPT!
    
    3) Reward Schedule
    You may receive NPT rewards from Apr. 7th, 2022 (Thurs.) on the “Remaining reward” tab.
    
    [Notes on the Event]
    
    • This Referral Event opens at 1AM on Feb. 23rd, 2022 and closes at 9AM on Mar. 2nd, 2022. 
    • Both the “referrer” and “referee” can receive rewards after the “referee” signs up on NEOPIN, enters Referral Code, and completes KYC verification.
    • KYC verification is accepted only once for both the “referrer” and “referee”.
    • Users who re-register after withdrawing their accounts are excluded from receiving rewards. Withdrawn users cannot sign up for the membership again, and all the information in their accounts and services will be deleted and cannot be recovered. 
    • The number of referrals is unlimited until the event closes.
    • NPT rewards will be distributed starting on April 7th, 2022. 
    • Users can view their reward status on the "Remaining reward" tab. However, if a “referrer” or “referee” withdraws the account before receiving rewards, rewards will be revoked.
    • If a user is involved in any transaction considered illegal or abnormal, the user may be excluded from the event and rewards.
    • Data collection, opening and closing of the event, etc. will be based on the NEOPIN server time. Please note that there may be a slight time difference with the standard time.
    • This event is subject to change or cancellation at any time without prior notice, depending on the company's circumstances.    
    `,
  },
};
