export const afoCancel220317 = {
  ko: {
    subject: "[공지] AFO 취소 및 기타 안내",
    body: `안녕하세요.
블록체인 오픈플랫폼 네오핀입니다.

AFO 진행 관련하여 안내드립니다.

AFO  연기 공지 안내 이후 가격 및 락업 조건 등에 대해 내부에서 꽤 오랜 기간 많은 논의를 진행했습니다. 그 과정 동안 많은 이용자님들이 상장 이후 현 시점에서의 AFO 진행에 대해 부정적인 의견을 주셨습니다. 
이에, 네오핀은 이용자님들의 의견을 적극 수용해 기존에 계획하였던 ETH-NPT AFO와 KLAY-NPT AFO를 취소하기로 결정하였습니다.
ETH-NPT와 KLAY-NPT은 추후 스왑, 풀 예치 상품으로 오픈될 예정입니다.

단, TRX-NPT AFO의 경우, 추후 이용자분들의 의견을 받아 진행 여부를 결정할 계획입니다.

감사합니다.
네오핀 드림
`,
  },
  en: {
    subject: "[Notice] Announcement on AFO Cancellation and Others",
    body: `Hi, we are NEOPIN, an open blockchain platform for all.

We would like to make an important announcement regarding the AFO.

After making the previous announcement on the postponed AFO schedule, our Team has engaged in a thorough discussion for several weeks on the issues of NPT price and lock-up conditions.

Considering that many users have expressed their concerns on proceeding with the AFO schedule at this moment in time, we have decided to cancel the ETH-NPT AFO and KLAY-NPT AFO. Instead, we plan to offer ETH-NPT and KLAY-NPT as Swap and Yield Farming products.

However, whether to proceed with the TRX-NPT AFO will be decided after careful consideration of feedback from our users. 

We greatly appreciate your support and interest in NEOPIN and will keep striving to provide a better user experience for all.

Thank you.
Team NEOPIN
`,
  },
};
