import React, { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import styled from "styled-components/macro";

const FontStyle = `
  font-family: "THICCCBOI";
  font-style: normal;
  line-height: 100%;
`;

const StyledColon = styled.div`
  ${FontStyle}
  font-weight: 700;
  font-size: 72px;
  color: #121f2a;
  width: 16px;
  height: 68px;
  margin-left: 5px;
  margin-right: 5px;

  @media only screen and (max-width: 787px) {
    font-size: 36px;
    width: 10px;
    height: 52px;
    margin: 0px;
  }
`;

const StyledCount = styled.div`
  ${FontStyle}
  font-weight: 700;
  font-size: 72px;
  color: #121f2a;
  width: 106px;
  height: 68px;

  @media only screen and (max-width: 787px) {
    font-size: 36px;

    width: 64px;
    height: 52px;
  }
`;

const Label = styled.div`
  ${FontStyle}
  font-weight: 500;
  font-size: 20px;
  color: #1d1d1d;
  opacity: 0.3;
  width: 106px;
  height: 32px;
  margin-top: 12px;

  @media only screen and (max-width: 787px) {
    font-size: 12px;
    width: 64px;
    height: 52px;
    margin-top: 0px;
  }
`;

const TimeLabelDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const CountdownDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin: auto;
`;

const TimeLabelSet: React.FC<{ label: string; time: number }> = (props) => {
  const time = useMemo(() => {
    if (props.time < 10) {
      return "0" + props.time;
    } else {
      return props.time;
    }
  }, [props.time]);

  return (
    <TimeLabelDiv>
      <StyledCount>{time}</StyledCount>
      <Label>{props.label}</Label>
    </TimeLabelDiv>
  );
};

const RemainDate: React.FC<{ openTime: string }> = ({ openTime }) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const remainTime = useCallback(() => {
    let diff = dayjs().diff(openTime);
    if (diff < 0) {
      diff = Math.abs(diff);
      setDays(Math.floor(diff / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((diff / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((diff / (1000 * 60)) % 60));
      setSeconds(Math.floor((diff / 1000) % 60));
    }
  }, [openTime]);

  useEffect(() => {
    setInterval(remainTime, 1000);
  }, [remainTime]);

  return (
    <CountdownDiv>
      <TimeLabelSet label="Days" time={days}></TimeLabelSet>
      <StyledColon>:</StyledColon>
      <TimeLabelSet label="Hours" time={hours}></TimeLabelSet>
      <StyledColon>:</StyledColon>
      <TimeLabelSet label="Minutes" time={minutes}></TimeLabelSet>
      <StyledColon>:</StyledColon>
      <TimeLabelSet label="Seconds" time={seconds}></TimeLabelSet>
    </CountdownDiv>
  );
};

export default RemainDate;
