export const s2tend230410 = {
  en: {
    subject: "[Notice] Termination of the S2T product service",
    body: `Please be informed that the services for S2T-related products, including swap and liquidity provision rewards, have officially ended as of April 10, 2023 UTC.
    Please refer to your investment and asset management.
    
    [ Subject to service termination ]
    - S2T-NPT Pool
    - S2T-NPT Swap
    
    [ Service Termination Schedule ]
    - 10:00:00 on April 10, 2023 UTC
    
    [ Details ]
      (1) S2T-NPT Pool: Unable to deposit new pool, suspension of reward payment, and continue to provide pool withdrawal feature
      (2) S2T Swap Not Supported
    
    Thank you for your continuous support for NEOPIN.
    
    
    `,
  },
};
