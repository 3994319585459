/** 한국어가 있으면 한국어를, 아니면 영어 표시 */
export function languageSelector(obj: any, lang: string) {
  if (obj) {
    return obj[lang.toLowerCase()];
  }
  // if (/ko/.include(navigator.language)) {
  //   return obj?.ko || obj?.en || null;
  // }
  // return obj?.en || null;
}
