/* eslint-disable import/no-anonymous-default-export */
export const afoDelay220302 = {
  ko: {
    subject: "[안내] ETH-NPT AFO 일정 연기 ",
    body: `안녕하세요. 네오핀입니다.
    오는 3월 3일(목)~7일(월)로 예정했던 ETH-NPT AFO의 일정 연기를 안내 드립니다.
    글로벌 거래소 상장 이후 가입자가 폭증하는 과정에서 KYC 인증이 딜레이 되고 있습니다.
    KYC 인증 문제로 인한 AFO 참여 불가 및 ETH-NPT AFO 풀 규모가 아쉽다는 민원, 트래블룰에 따른 거래소에서 네오핀 지갑으로의 이더리움 전송에 대한 어려움 등이 지속적으로 발생하고 있습니다. 
    
    이에 이용자님들의 의견을 반영하여 ETH-NPT AFO 일정을 연기하게 됐습니다. 
    AFO를 기다려주신 이용자님들에게 일정을 연기하게 된 점 사과의 말씀 올립니다.
    네오핀을 성원해주시는 분들에게 많은 혜택이 돌아갈 수 있도록 지속적으로 고민하고 더욱 노력하겠습니다.
    
    네오핀 드림
     `,
  },
  en: {
    subject:
      "[Important Announcement] ETH-NPT AFO Schedule Postponed Indefinitely",
    body: `Hello, we are Team NEOPIN.

    We regret to announce that the ETH-NPT AFO that had been scheduled for Mar. 3rd to 7th will be postponed indefinitely. 
    
    Our KYC verification process has been delayed with an explosion of subscribers after our dual listing on global exchanges. 
    
    There have also been complaints on the size of the ETH-NPT AFO pool, the inability to participate in the AFO due to delayed KYC verification, and difficulties in transferring Ethereum from an exchange to the NEOPIN wallet due to Travel Rules.
    
    We have therefore decided to postpone the ETH-NPT AFO to reflect on and re-examine the opinions of our users.
    
    We apologize to all users who have been waiting to participate in the AFO. 
    
    We greatly appreciate your support and interest in NEOPIN and will keep striving to provide a better user experience for all.
    
    Thank you again for your understanding and patience. 
    Team NEOPIN`,
  },
};
