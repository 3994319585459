/* eslint-disable import/no-anonymous-default-export */
export const farmV1Return221207 = {
  // 영문만 배포
  ko: {
    subject: "[공지] Yield Farm V1 상품 예치금 반환 안내 (완료)",
    body: `안녕하세요. 네오핀 입니다.

    네오핀은 지난 8월 1일 종료된 V1상품에 예치금을 미인출하신 사용자분에 대하여, 예치 자산을 사용자님의 지갑으로 반환시켜 드릴 예정입니다.
    해당 사용자 분들은 서비스 이용에 참고해 주시기 바랍니다.
    
    [ 일시 ]
    - 2022년 12월 14일 14:00(KST)
    
    [ 반환 대상 ]
    - 2022년 8월 1일 이전 NPT-S2T, NPT-BVT V1 상품 가입자 중 자산 미 인출 사용자
    
    [ 반환 자산 ]
    - NPT-S2T, NPT-BVT V1 상품 예치금
    - NPT-S2T, NPT-BVT V1 상품 리워드
    
    항상 네오핀을 이용해 주셔서 감사합니다.        
  `,
  },

  en: {
    subject: "[Notice] Yield Farm V1 product deposit return (completed)",
    body: `NEOPIN will return the deposited assets to users who have yet to withdraw the assets from the V1 product. V1 product will no longer be available by August 1st. 
    Please refer to the details below. 
    
    [ WHEN ] 
    - 2022.12.14 5:00(UTC)
    
    [ Return to ] 
    - NPT-S2T, NPT-BVT product before August 1, 2022 users who have not withdrawn assets. 
    
    [ Return asset ]
    - NPT-S2T, NPT-BVT V1 product deposit
    - NPT-S2T, NPT-BVT V1 product reward
    
    Thank you for your continuous support for NEOPIN.
        
`,
  },
};
