/** @format */

import React from 'react';
import '../style/notice.scss';
import PageHeader from '../components/notice/PageHeader';
import Notice, { getNoticeUrl } from './Notice';

export interface INoticeProps {
  lang: string;
}
const BrowserNotice: React.FC<INoticeProps> = ({ lang }) => {
  const ITEM_PER_PAGE = 10
  return (
    <div className="browser-notice">
      <PageHeader
        pageTitle={lang === "KO" ? "공지사항" : "Notice"}
        titleLink={getNoticeUrl(true, lang)}
        langList={[
          { link: "/notices_kr", langName: "KOR" },
          { link: "/notices", langName: "ENG" },
        ]}
      />
      <div className="browser-notice__wrapper">
        <div className="notice-header">
          <span className="notice-header__title">제목</span>
          <span className="notice-header__open-at">등록일</span>
        </div>
        <Notice
          lang={lang}
          isBrowser={true}
          isPagination={true}
          itemPerPage={ITEM_PER_PAGE}
        />
        
      </div>
    </div>
  );
};

export default BrowserNotice;
