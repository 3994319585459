export const tronStake230404 = {
  en: {
    subject: "[Notice] Tron Stake 2.0 and NEOPIN's Response",
    body: `The Tron Foundation has proposed a vote for the implementation of Stake 2.0.
    If this proposal is passed, Stake 2.0 will be applied to the Tron network from April 7th at 06:00:00 UTC.

    Once Stake 2.0 is applied, additional staking on existing Stake products will no longer be possible, and only reward claims and unstaking will be available.
    Please refer to these options for using the product.
    
    NEOPIN is currently preparing a new Stake product that is equivalent to Tron Stake 2.0 and will provide it as soon as possible.
    
    Thank you for your continued support of NEOPIN.
    
    `,
  },
};
