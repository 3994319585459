import { useEffect } from "react";

const RedirectNewNeopin = ({ neopinPath }: { neopinPath?: string }) => {
  useEffect(() => {
    window.location.href = `https://neopin.io${neopinPath ? neopinPath : ""}`;
  }, []);
  return <></>;
};

export default RedirectNewNeopin;
