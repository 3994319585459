/* eslint-disable import/no-anonymous-default-export */
export const nptListing220228 = {
  ko: {
    subject: "[상장] 네오핀 토큰(NPT) 글로벌 동시 상장",
    body: `안녕하세요.
    블록체인 오픈플랫폼 네오핀입니다.
    
    ‘네오핀 토큰(NPT)’이 2022년 2월 28일(월) 글로벌 거래소 ‘MEXC Global’과 ‘PROBIT Global’에 동시 상장합니다.
    
    ====================
    
    <글로벌 동시 상장 - MEXC Global>
    ‘네오핀 토큰(NPT)’이 가상자산 거래소 ‘MEXC Global’에 오는 28일(월) 상장합니다. 
    27일(일) 한국시간 23시에 입금이 개시되며, 28일(월) 13시에 NPT/USDT 거래 페어가 개시될 예정입니다.
    
    1. 거래소 : MEXC Global
    2. 토큰명 : NPT
    3. 마켓 : NPT/USDT
    4. 입금 개시 : 2022년 2월 27일(일) 23:00 (KST)
    5. 거래 개시 : 2022년 2월 28일(월) 13:00 (KST)
    
    ====================
    
    <글로벌 동시 상장 - PROBIT Global>
    ‘네오핀 토큰(NPT)’이 가상자산 거래소 ‘PROBIT Global’에 오는 28일(월) 상장합니다. 
    28일 12시에 입금이 개시되며, 16시에 NPT/USDT 거래 페어가 개시될 예정입니다.
    
    1. 거래소 : PROBIT Global
    2. 토큰명 : NPT
    3. 마켓 : NPT/USDT
    4. 입금 개시 : 2022년 2월 28일(월) 12:00 (KST)
    5. 거래 개시 : 2022년 2월 28일(월) 16:00 (KST)
    
    감사합니다.
    네오핀 드림
    `,
  },
  en: {
    subject: "[Listing] NEOPIN Token(NPT) Dual Listing on Global Exchanges",
    body: `Hi, this is NEOPIN, an open blockchain platform for all.

    On Feb. 28th, 2022 (Mon) [UTC], NPT is up for a DUAL listing on two global exchanges, “MEXC Global” and “PROBIT Global”.
    
    ====================
    
    <Listing on MEXC Global>
    NEOPIN Token (NPT) will be listed on MEXC Global on Feb. 28th, 2022 (Mon).
    The deposit will be open on Feb. 27th, 2022 14:00 (UTC), and the trading (NPT-USDT) will begin on Feb. 28th, 2022 04:00 (UTC).
    
    1.Exchange: MEXC Global
    2. Name of Token: NPT
    3. Market: NPT/USDT
    4. Opening Date of Deposit: Feb. 27th, 2022 14:00 (UTC)
    5. Opening Date of Trading: Feb. 28th, 2022 04:00 (UTC)
    
    ====================
    
    <Listing on PROBIT Global>
    NEOPIN Token (NPT) will be listed on PROBIT Global on Feb. 28th, 2022 (Mon).
    Deposit will be open on Feb. 28th, 2022 03:00 (UTC), and Trading(NPT-USDT) will begin on Feb. 28th, 2022 07:00 (UTC).
    
    1.Exchange: PROBIT Global
    2. Name of Token: NPT
    3. Market: NPT/USDT
    4. Opening Date of Deposit: Feb. 28th, 2022 03:00 (UTC)
    5. Opening Date of Trading: Feb. 28th, 2022 07:00 (UTC)
    
    Team NEOPIN
    `,
  },
};
