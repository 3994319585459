export const bitgetListing220325 = {
  ko: {
    subject: "[상장] 네오핀 토큰(NPT) 비트겟 거래소 상장",
    body: `안녕하세요.
블록체인 오픈플랫폼 네오핀입니다.

‘네오핀 토큰(NPT)’이 비트겟 거래소에 오는 25일(금) 상장합니다. 25일(금) 한국시간 오전 10시에 입금이 개시되며, 25일(금) 오후 6시에 NPT/USDT 거래 페어가 개시될 예정입니다.

1. 거래소 : Bitget (비트겟)
2. 토큰명 : NPT
3. 마켓 : NPT/USDT
4. 입금 개시 : 2022년 3월 25일(금) 오전 10:00 (KST)
5. 거래 개시 : 2022년 3월 25일(금) 오후 06:00 (KST)

`,
  },
  en: {
    subject: "[Listing] NEOPIN Token(NPT) listing on Bitget",
    body: `Hi, we are NEOPIN, an open blockchain platform for all.

We brought you some more exciting news for you today: NPT(Neopin Token) will be listed on Bitget!

Deposit will open at 01:00 AM (UTC) on Mar. 25th, 2022, and the trading pair for NPT-USDT will begin at 09:00 AM (UTC) on Mar. 25th, 2022.

New listing on “Bitget” :
1. Exchange : Bitget
2. Name of Token : NPT
3. Market : NPT/USDT
4. Opening Date of Deposit : Mar. 25th, 2022. 01:00 AM (UTC)
5. Opening Date of Trading Pair : Mar. 25th, 2022. 09:00 AM (UTC)

`,
  },
};
