export const bbcEnd230602 = {
  ko: {
    subject: "[공지] $BBC 상품 서비스 종료 예정 안내",
    body: `네오핀에서 $BBC 관련 상품의 서비스가 2023년 6월 8일부로 종료될 예정입니다. 해당 상품 서비스가 종료된 이후에는 BBC-NPT 스왑 기능을 제공하지 않으며, 풀 예치에 대한 리워드가 더 이상 발생하지 않게 되오니 사용자님의 투자 및 자산 관리에 참고하시기 바랍니다.

    [ 서비스 종료 대상 상품 ]
    BBC-NPT Pool
    BBC-NPT Swap
    
    [ BBC 상품 서비스 종료 일정 ]
    - 2023년 6월 8일 14:59:59 까지 : 상품 서비스 정상 이용 가능
    
    - 2023년 6월 8일 15:00:00 부터 :
      (1) BBC-NPT Pool : 신규 풀 예치 불가, 리워드 지급 중단, 풀 인출 가능
      (2) BBC Swap 미지원
    
    항상 네오핀을 이용해주셔서 감사합니다.
    
    ----------
    
    [ 네오핀 APR Top3 스왑풀 ]
      1 - USDT/NPT : 106.39% APR
      2 - ETH/NPT : 87.48% APR
      3 - MATIC/NPT : 26.17% APR
    
    * 표시된 APR은 지속적으로 변동되며, 보장된 수익률을 의미하지 않습니다.
    
    
    `,
  },
  en: {
    subject: "[Notice] Scheduled on Termination of the $BBC product service",
    body: `NEOPIN will no longer provide $BBC-related products including its swap features and rewards for providing liquidity on June 8th 2023.
    Please refer to your investment and asset management.
    
    [ Subject to service termination ]
    - BBC-NPT Pool
    - BBC-NPT Swap
    
    [ Service Termination Schedule ]
    - Until 05:59:59 on June 8th, 2023 UTC : Service available normally
    
    - From 06:00:00 on June 8th, 2023 UTC:
      (1) BBC-NPT Pool: Unable to deposit new pool, suspension of reward payment, and continue to provide pool withdrawal feature
      (2) BBC Swap Not Supported
    
    Thank you for your continuous support.
    
    ----------
    
    [ Top 3 APR Pools on NEOPIN ]
    1 - USDT/NPT : 106.39% APR
    2 - ETH/NPT : 87.48% APR
    3 - MATIC/NPT : 26.17% APR
    
    *The displayed APRs are estimated and should not be considered guaranteed rates of return.
    
    
    `,
  },
};
