/* eslint-disable import/no-anonymous-default-export */
export const systemUpdate221104 = {
  ko: {
    subject: "[점검] 서비스 업데이트에 따른 점검 사전 안내 (완료)",
    body: `네오핀은 서비스 개선을 위해 업데이트를 진행할 예정입니다.
    업데이트 시간동안 점검 진행과 함께 서비스 이용이 잠시 중단될 예정이오니, 네오핀 이용에 있어 참고하시기 바랍니다.
    
    * 일시 : 2022년 11월 8일(화) 14:00 ~ 16:00 (KST)
    
    * 점검 내용
       - 안정적인 서비스를 위한 전체 시스템 점검을 진행합니다.
       - 1.4.0 업데이트를 적용합니다.
    
    * 1.4.0 업데이트 내용
      1) 월렛 커넥트 연동 지원
          - DApp을 포함한 여러 서비스에서 네오핀을 크립토 지갑으로 사용할 수 있도록 월렛 커넥트 연동을 지원합니다.
    
       2) 클레이튼 거래 수수료 지원 정책 조정
           - 건전한 투자 환경을 위해 클레이튼 거래 수수료 지원을 '거래 기준 1일 최대 10회'로 조정합니다.
    
    항상 네오핀을 이용해주셔서 감사합니다.
    
`,
  },

  en: {
    subject: "[Maintenance] System update notice (completed)",
    body: `NEOPIN will be going through maintenance to improve overall services. Please note that use of services will not be available during the maintenance. We ask for your patience and understanding.
    
    * Schedule: 2022/11/08(Tues.) 05:00-07:00 (UTC)
    
    * Maintenance
      - Full inspection of system for service stability
      - Implement 1.4.0 updates
    
    * 1.4.0 Updates
      1) Wallet Connect support
          - Use of NEOPIN in various DApps and services will be available through Wallet Connect. 
    
      2) Klaytn transaction fee policy update
          - Klaytn transaction fee support will be adjusted to “up to 10 times per day” to ensure a sound investment environment.
    
    Thank you for using NEOPIN.
    
`,
  },
};
