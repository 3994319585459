export const joySwapPool230519 = {
  ko: {
    subject: "[공지] JOY 토큰과 스왑풀 추가",
    body: `네오핀은 상품의 확장과 사용자 편의를 위해 클레이튼 네트워크 서비스에 DKR(Drawshop Kingdom Reverse)의 Joystick 토큰과 스왑풀을 추가했습니다:

    - Joystick Token (JOY)
    - JOY-NPT 스왑 풀
    
    새로운 상품을 통해 JOY 토큰을 네오핀 지갑으로 입출금하고, 유동성 풀에 유동성을 공급하거나 다른 토큰으로 손쉽게 교환할 수 있습니다.
    
    NEOPIN에 대한 지속적인 지원에 감사드립니다.
    `,
  },
  en: {
    subject: "[Announcement] NEOPIN Adds JOY token on Swap Pool",
    body: `NEOPIN has added new token $JOY of DKR(Drawshop Kingdom Reverse) and Swap Pool pairs to its Klaytn based services:

    - Joystick Token (JOY)
    - JOY-NPT Swap Pool
    
    With these new products, you can deposit and withdraw JOY tokens into your NEOPIN wallet, provide liquidity to your liquidity pool, or easily exchange them for other tokens.
    
    Thank you for your continuous support for NEOPIN.
    
    `,
  },
};
