export const maintenance230327 = {
  en: {
    subject: "[Maintenance] Service Update and Maintenance - Schedule Change",
    body: `NEOPIN will be going through maintenance to expand products and enhance user experience. 
    Please note that service will be temporarily suspended during maintenance. 
    If you need to deposit or withdraw assets, please refer to the date and time below and plan accordingly.

    [ Schedule ]
    2023/03/31(Fri.) 05:00 (UTC)
    
    [ Updates ]
    1. Ethereum Products
    You can now provide liquidity to Ethereum-based products or exchange tokens with NEOPIN.
        - ETH-NPT Pool
        - USDT-NPT Pool
    
    2. Speed Up
    You can speed up transaction processing on some networks using the Speed Up function.
        - This feature is available on Ethereum and Polygon networks
    
    Thank you for your continued support of NEOPIN.
    `,
  },
};
