/** @format */

import { Navigate, Routes, Route, useLocation } from "react-router-dom";
import BrowserNotice from "./pages/BrowserNotice";
import BrowserNoticeDetail from "./pages/BrowserNoticeDetail";
import Notice from "./pages/Notice";
import NoticeDetail from "./pages/NoticeDetail";
import Faq from "./pages/Faq";
import NewHome from "./pages/NewHome";
import Event from "./pages/Event";
import AppLink from "./pages/AppLink";
import AppDownload from "./pages/AppDownload";
import "./assets/fonts/font.css";
import { useEffect } from "react";
import CountDown from "./pages/countdown/CountDown";
import Business from "./pages/Business";
import IntroDeck from "./pages/IntroDeck";
import RedirectNewNeopin from "./pages/RedirectNewNeopin";
function App() {
  useEffect(() => {
    // initGtag(config.firebase.measurementId);
  });
  return (
    <div className="App">
      <Routes>
        {/** 리브랜딩 이후 사용하지 않아서 neopin.io로 리다이렉트 시킴 */}
        <Route path="/" element={<RedirectNewNeopin />} />
        {/** 모바일 or PC 브라우저 Notice */}
        <Route path="/notices" element={<BrowserNotice lang="EN" />} />
        <Route path="/notices_kr" element={<BrowserNotice lang="KO" />} />
        <Route path="/notices/:id" element={<BrowserNoticeDetail />} />
        <Route path="/notices_kr/:id" element={<BrowserNoticeDetail />} />
        {/** 네오핀 앱 웹뷰용 Notice */}
        <Route path="/notice" element={<Notice lang="EN" />} />
        <Route path="/notice_kr" element={<Notice lang="KO" />} />
        <Route path="/notice/:id" element={<NoticeDetail />} />
        <Route path="/notice_kr/:id" element={<NoticeDetail />} />
        {/** 모바일 or PC 브라우저 FAQ */}
        <Route path="/faq_kr" element={<Faq lang="KO" />} />
        <Route path="/faq" element={<Faq lang="EN" />} />
        {/** 네오핀 앱 웹뷰용 FAQ */}
        <Route path="/faq_kr" element={<Faq lang="KO" />} />
        <Route path="/faq" element={<Faq lang="EN" />} />
        {/** 네오핀 이벤트페이지 */}
        <Route path="/event/:event" element={<Event lang="EN" />} />
        <Route path="/event_kr/:event" element={<Event lang="KO" />} />
        {/** 딥링크 실행페이지 */}
        <Route path="/app" element={<AppLink lang="EN" />} />
        {/** 다운로드 안내페이지 */}
        <Route path="/download" element={<AppDownload lang="EN" />} />
        {/** Business페이지(오픈플랫폼) */}
        <Route
          path="/business"
          element={<RedirectNewNeopin neopinPath="/business" />}
        />
        {/** 보람님이 작성하신 홍보용 pdf 뷰어 페이지 */}
        <Route path="/intro_deck" element={<IntroDeck lang="EN" />} />s
        <Route
          path="*"
          element={<Navigate to="/" state={{ from: useLocation() }} />}
        />
        <Route path="/count_down" element={<CountDown lang="EN" />} />
      </Routes>
    </div>
  );
}

export default App;
