/** @format */

import React from "react";
import { Navigate, useParams } from "react-router";
import useSWR from "swr";
import { fetchNoticeDetail, INoticeDetail } from "../api/fetchNoticeDetail";
import { languageSelector } from "../util/language";
import { getHHmmSSFromISO, getYYYYmmDDFromISO } from "../util/time";
import "../style/notice.scss";
import { getNoticeUrl } from "./Notice";

interface IProps {
  isBrowser?: boolean;
}

const NoticeDetail: React.FC<IProps> = ({ isBrowser }) => {
  window.scrollTo(0, 0);
  const { id } = useParams<"id">();
  const { data } = useSWR(`/napi/v1/notice/${id}`, fetchNoticeDetail);
  let lang = "en";
  if (window.location.href.includes("_kr")) {
    const content = languageSelector(data, "ko");
    if (content?.subject) {
      lang = "ko";
    } else {
      lang = "en";
    }
  } else {
    lang = "en";
  }
  const parseTitle = (data: INoticeDetail | undefined): string => {
    if (!data) {
      return "";
    }
    const content = languageSelector(data, lang);
    if (!content || !content.subject) {
      return "";
    }
    return content.subject;
  };

  const parseBodyLineBreak = (data: INoticeDetail | undefined): string[] => {
    if (!data) {
      return [];
    }
    const content = languageSelector(data, lang);
    if (!content || !content.body) {
      return [];
    }
    const strList = content.body.split("\n");
    return strList;
  };

  const parseTime = (data: INoticeDetail | undefined) => {
    if (!data?.openAt) {
      return "";
    }
    return `${getYYYYmmDDFromISO(data.openAt)} ${getHHmmSSFromISO(
      data.openAt
    )}`;
  };
  // const getNoticeListPath = (pathname: string) => {
  //   // 공지사항 리스트 페이지로 이동하는거 만들긴햇는데 여러가지 점에서 조금 애매하다.
  //   // 뒤로가기로 만들자니 공지사항이 바로 열렸을때 애매하고, 공지사항 리스트로 이동하자니 뒤로가기가 아니라서 나중에 pagination추가할때 애매하다.
  //   // 일단 사용하지 않고, 사용할때 <Link to={`/${getNoticeListPath(location.pathname)}`}> 로 사용하자.
  //   const path = /notice((\w|\d|-)+?)?(?=\/)/.exec(pathname);
  //   return path ? path[0] : "";
  // };
  return (
    <div className={`notice ${isBrowser ? "browser" : ""}`}>
      {data && data.resultString === "Failed" && (
        <Navigate to={getNoticeUrl(isBrowser, lang)} />
      )}

      <div className="detail-title">
        <div className="detail-title-content">
          <span className="detail-title-text">{parseTitle(data)}</span>
          <span className="detail-open-at">{parseTime(data)}</span>
        </div>
      </div>
      <div className={`detail-body ${lang}`}>
        {parseBodyLineBreak(data).map((str, i) => {
          let listStyle = "none";
          switch (str.trim().charAt(0)) {
            case "-":
              listStyle = "list";
              break;
            case "[":
              listStyle = "title";
              break;
            case "※":
              listStyle = "title";
              break;
          }
          if (str.trim().includes("<img")) {
            return (
              <div
                dangerouslySetInnerHTML={{ __html: str }}
                className="block-img"
              ></div>
            );
          }
          if (str.trim().includes("<a href")) {
            return <p dangerouslySetInnerHTML={{ __html: str }}></p>;
          }
          if (str.trim().includes("===")) {
            return <hr />;
          }
          return (
            <p key={i} className={listStyle}>
              {str}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default NoticeDetail;
