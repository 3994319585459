export const updateNotice230524 = {
  ko: {
    subject: "[공지] NEOPIN v2.4.0 업데이트 완료 안내",
    body: `네오핀은 사용자 편의성 개선을 위한 앱 업데이트를 진행하였습니다.
    자세한 업데이트 내용은 아래를 확인해 주시기 바랍니다.
    
    [ 업데이트 내용 ]
    1. 한국어 지원
        설정 - 언어 설정을 통해 앱 서비스를 한국어로 이용할 수 있습니다.
    
    2. 로딩 개선
        네트워크 상태가 좋
    지 않은 환경에서도 서비스를 원활하게 이용할 수 있도록 최적화하고 로딩 연출을 개선하였습니다.
    
    3. 사용자 편의성 개선
        사용자님의 편의성을 높이기 위하여 일부 항목을 개선하였습니다.
    
    항상 네오핀을 이용해주셔서 감사합니다.
    
    `,
  },
  en: {
    subject: "[Notice] NEOPIN v2.4.0 Update",
    body: `This update of the NEOPIN brings the following improvements:

    [ Updates ]
    
    1. Korean Language Support
    You can use the app in Korean through Settings - Language.
    
    2. Improved Loading Performance
    Optimized loading performance which can provide smooth user experience even in bad network environments.
    
    3. Improved User Convenience
    This version includes new features and performance improvements.
    
    Thank you for your continuous support.
    
    
    
    
    `,
  },
};
