export const servicelaunched20220801 = {
  ko: {
    subject: `[공지] 네오핀 웹 서비스 및 신규 서비스 출시에 대한 세부 안내`,
    body: `안녕하세요, 네오핀입니다.

    8월 1일, 네오핀이 웹 버전을 오픈하고 다양한 신규 서비스를 출시했습니다.
    
    [네오핀 웹 버전 오픈 안내]
    
    이제 웹을 통해 PC와 모바일에서 보다 편리하게 서비스를 이용하실 수 있습니다.
    
    ※ 웹 스테이킹 서비스는 추후 지원 예정입니다.
    
    [신규 서비스 출시 안내]
    
    웹 오픈과 함께 다양한 신규 상품이 추가되었습니다. 금일 추가된 상품들에 더해, 앞으로 더욱 많은 상품들을 선보일 예정이오니 많은 기대 부탁드립니다.
    
    - 신규 추가 토큰: 총 19종(8/1 13종, 8/17 3종, 8/29 1종, 9/29 1종, 11/29 1종 추가)
    
    MARBLEX(MBX), BORA(BORA), SOMESING(SSX), Project WITH(WIKEN), Observer(OBSR), IPVERSE(IPV), Artube(ATT), Cloudbric(CLBK), DSC MIX(MIX), Monstock(MON), Nightingale Token(NGIT), Blue Baikal(BBC), WEMIX(WEMIX) 8/1, TEMCO(TEMCO), Klap Finance(KLAP), GAMETREE(GTCOIN) 8/17, Hiblocks(HIBS) 8/29, ZEMIT(ZEMIT) 9/29, Marmalade Token(MARD) 11/29 신규 추가
    
    ※ 지갑 서비스를 통해 토큰을 보관하거나 투자 상품에 활용할 수 있습니다.
    
    - 신규 Stake 상품: 총 1종(8/29 1종 추가)
    
    IPVERSE(8/29 신규 추가)
    
    - 신규 Yield Farm 상품: 총 14종(8/1 9종, 8/17 2종, 8/29 1종, 9/29 1종, 11/29 1종 추가)
    
    Klaytn, MARBLEX, BORA, SOMESING, Project WITH, IPVERSE, DSC MIX, Nightingale Token, Blue Baikal(8/1), TEMCO, Klap Finance(8/17), Hiblocks(8/29), ZEMIT(9/29), Nightingale Token-Marmalade Token(11/29)
    
    ※ 13종의 신규 토큰을 NPT와 함께 투자에 활용할 수 있으며, NGIT-MARD 상품이 추가되었습니다.
    
    - 신규 스왑 상품: 총 13종(8/1 9종, 8/17 2종 8/29 1종, 9/29 1종 추가)
    
    Klaytn, MARBLEX, BORA, SOMESING, Project WITH, IPVERSE, DSC MIX, Nightingale Token, Blue Baikal(8/1), TEMCO, Klap Finance(8/17), Hiblocks(8/29), ZEMIT (9/29)
    
    ※ 상기 토큰 이외에도 S2T-BVT, NGIT-MARD 등의 상품이 제공되며 총 17종 토큰 간의 크로스 스왑이 지원됩니다.
    
    사용자 님들의 편의를 위해 최선을 다하는 네오핀에 앞으로도 꾸준한 관심과 응원 부탁드립니다.
    
    감사합니다.
    
    `,
  },
  en: {
    subject: `[Notice] Details on NEOPIN Web, Swaps & Farms Launch`,
    body: `We are pleased to announce that we have launched NEOPIN Web and a variety of new services on August 1, 2022.

    [NEOPIN Web Open]
    
    Now you can conveniently access NEOPIN services on both mobile and PC.
    
    ※ Web Staking service will be available over time.
    
    [New Swaps & Farms]
    
    New pools and tokens for Swap and Yield Farming are now open.
    
    - New Tokens: 19 in total (Added 13 on 8/1, 3 on 8/17, 1 on 8/29, 1 on 9/29, 1 on 11/29)
    
    MARBLEX(MBX), BORA(BORA), SOMESING(SSX), Project WITH(WIKEN), Observer(OBSR), IPVERSE(IPV), Artube(ATT), Cloudbric(CLBK), DSC MIX(MIX), Monstock(MON), Nightingale Token(NGIT), Blue Baikal(BBC), WEMIX(WEMIX) on 8/1, TEMCO(TEMCO), Klap Finance(KLAP), GAMETREE(GTCOIN) on 8/17, Hiblocks(HIBS) on 8/29, and ZEMIT(ZEMIT) on 9/29, Marmalade Token(MARD) on 11/29.
    
    ※ New tokens can be stored in the NEOPIN Wallet and utilized for services.
    
    - New Stake: 1 in total(Add 1 on 8/29)
    
    IPVERSE on 8/29
    
    - New Yield Farms: 14 in total (Added 9 on 8/1, 2 on 8/17, 1 on 8/29, 1 on 9/29, 1 on 11/29)
    
    Klaytn, MARBLEX, BORA, SOMESING, Project WITH, IPVERSE, DSC MIX, Nightingale Token, Blue Baikal on 8/1, TEMCO, Klap Finance on 8/17, Hiblocks on 8/29, and ZEMIT on 9/29, Nightingale Token-Marmalade Token on 11/29.
    
    ※ The above 13 new tokens will be paired with NPT for Yield Farming and NGIT-MARD is now available.
    
    - New Swaps: 13 in total (Added 9 on 8/1, 2 on 8/17, 1 on 8/29, 1 on 9/29)
    
    Klaytn, MARBLEX, BORA, SOMESING, Project WITH, IPVERSE, DSC MIX, Nightingale Token, Blue Baikal on 8/1, and TEMCO, Klap Finance on 8/17, Hiblocks on 8/29, and ZEMIT on 9/29.
    
    ※ A wide range of swap products is offered including S2T-BVT & NGIT-MARD and cross-swap between 17 tokens is available.
    
    We appreciate your interest in NEOPIN.
    
    Thank you.
    
    `,
  },
};
