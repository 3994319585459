/* eslint-disable import/no-anonymous-default-export */
export const patchOtp221004 = {
  ko: {
    subject: "[공지] 보안설정 변경 및 점검 안내 (완료)",
    body: `안녕하세요. 네오핀(NEOPIN)입니다.

    네오핀은 이용 편의성 제고와 보안 강화를 위하여 업데이트를 진행하였습니다.
    
    * 일시: 2022년 10월 4일, 14:00~16:00 (KST)
              (해당 시간동안 서비스 일시 중단)
    
    * 업데이트 내용
    1) OTP 인증 선택화
        - 기존에 필수 설정이었던 OTP 인증이 선택사항으로 변경되어 ‘2차 보안 설정’으로 설정 가능합니다.
        - 초기화를 위하여 OTP 인증이나 복구 단어가 필요합니다.
    
    2) PIN 번호 인증 필수화
        - PIN 번호 설정이 필수 항목으로 신규 개설됩니다.
        - 분실 시, 복구 단어로 재설정 가능합니다.
    
    3) 생체정보 인증 지원
        - PIN 번호를 대체하는 생체정보 인증을 지원합니다.
    
    
    항상 네오핀을 이용해주셔서 감사합니다.
    
`,
  },

  en: {
    subject:
      "[Notice] Security Setting Changes and Service Maintenance (completed)",
    body: `Please be informed that NEOPIN has performed an update for reinforcing security and 
    improving user convenience. 
    
    * Date: October 4, 2022 5:00~7:00am (UTC)
    (All services were suspended during the update)
    
    * Updates
    1) Make OTP optional
      -  OTP authentication, which was previously a required setting, has been changed to optional and can be set through ‘Two Factor Authentication’. 
      - To reset, OTP authentication or Recovery Phrase is required.
    
    2) Mandatory PIN Code setting
      - PIN Code setting is newly opened as required.
      - It can be reset with Recovery Phrase in case of loss.
    
    3) Biometric authentication support
      - Supports Biometric authentication as an alternative to PIN Code.
        
    
    Thank you for your continued interest and support.
    
    
    
    
    
`,
  },
};
