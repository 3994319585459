export const ethAddress221208 = {
  ko: {
    subject: "[공지] ETH 지갑 주소 통합 예정 안내",
    body: `네오핀은 서비스 확장과 사용자 편의성 개선을 위해 Ethereum 지갑 주소를 Klaytn 지갑 주소로 통합하여 제공할 예정입니다.
    이에 따라 안타깝지만 네오핀 서비스에서 기존의 Ethereum 지갑 주소는 더이상 사용할 수 없으며, 새롭게 통합된 주소를 사용하게 됩니다. 
    
    기존 이더리움 지갑 주소 사용자분께서는 주소 지원 종료 전까지 외부 지갑으로 자산을 이동 하시거나 지원 종료와 함께 제공 예정인 
    Private key 내보내기 기능을 통해 외부 타 암호화 지갑으로 자산을 이전하여 이용하실 수 있습니다.
    
    기존 클레이튼 지갑 주소는 그대로 유지되며, 주소 통합 이후에는 클레이튼 지갑 주소와 동일한 이더리움 주소를 사용할 수 있게 됩니다.
    
    아래의 일정으로 지갑 주소의 통합이 이루어질 예정이오니 사용자님의 자산관리에 참고하여 주시기 바랍니다.
    
    [ 주소 통합 일정 ]
    2022년 12월 14일 14:00 (예정)
    
    [ 지원 중지 지갑 주소에 대한 지원 ]
    기존 ETH 주소에 대한 Private key 내보내기 기능 제공
    
    항상 네오핀을 이용해주셔서 감사합니다.       
  `,
  },

  en: {
    subject: "[Notice] Scheduled on the integration of ETH  address",
    body: `NEOPIN will begin merging the Ethereum wallet address into the Klaytn wallet address to improve user experiences and expand our service coverage. 
    Therefore, the current ETH address will no longer be available in NEOPIN apps.

    Assets held within the current ETH address will need to be transferred or imported into an external wallet using the private key export feature, which will be available at the termination point.
    
    Klaytn Wallet address will remain the same, and the ETH  address of the same address will be available after address integration.
    
    Please refer to the address integration schedule below.
    
    [ Address integration schedule ]
    December 14, 2022 05:00 UTC (subject to change)
    
    [ Support for wallet addresses that have been discontinued ]
    We will provide a private key export function for current ETH addresses.
    
    Thank you for your continuous support for NEOPIN.
        
`,
  },
};
