export const ssxNpt221223 = {
  ko: {
    subject: "[소식] SSX-NPT Yield Farm 상품 SSX 토큰 리워드 이벤트 안내",
    body: `
    안녕하세요. 네오핀입니다.

네오핀과 썸씽이 SSX-NPT Yield Farm 상품 이용자분들을 대상으로 SSX 토큰 리워드 이벤트를 진행합니다.

SSX-NPT Yield Farm 상품 이용자분들께 감사의 마음을 담아 이벤트 기간 동안 기본 리워드인 NPT 토큰 외 SSX 토큰을 추가로 지급합니다.

[이벤트 소개]
1. 이벤트 기간: 2022.12.23 17:00:00 ~ 2023.12.23 16:59:59 [KST]
2. 이벤트 대상 상품: SSX-NPT Yield Farm
3. 이벤트 보상: 이벤트 기간 동안 SSX 토큰 리워드 추가 제공

[참여 안내]
1. 참여 방법: 
앱 실행 후 [SSX 토큰 리워드 이벤트] 팝업 창에서 이벤트 참여하기 버튼 누르기 
- SSX-NPT Yield Farm 상품 기존 참여자: 인증 절차 완료 시 이벤트 참여 완료
- SSX-NPT Yield Farm 상품 미 참여자: 기간 내 해당 상품에 신규 예치 시 이벤트 참여 완료
2. 이벤트 보상 수령 방법
- 이벤트 기간 내: 해당 상품 리워드 수령 시 기본 보상과 동시 수령
- 이벤트 기간 후: 지갑 탭 > Yield Farm > 미수령 보상 항목에서 수령

[주의사항]
- SSX 토큰 리워드는 기간 내에 이벤트에 참여 완료한 이용자를 대상으로 제공됩니다.
- 이벤트 참여 완료 시, 이벤트 팝업에서 [이벤트 참여하기] 버튼이 [이벤트 참여중]으로 변경 됨을 꼭 확인해 주시기 바랍니다.
- 기존 상품 이용자가 이벤트 참여 시, 참여 전까지 누적된 기본 리워드가 정산 수령된 후 이벤트에 참여됩니다.
- 신규 예치 사용자는 예치 시점부터 이벤트 기간 종료 시 까지 SSX 토큰 리워드가 지급됩니다.
- 이벤트 기간은 연장, 축소 또는 중지 될 수 있습니다.

항상 네오핀을 이용해주셔서 감사합니다.

    `,
  },

  en: {
    subject: "[Announcement] Bonus Reward Event for SSX-NPT Pool (Extended)",
    body: `NEOPIN and SOMESING will provide bonus rewards for yield farmers in the SSX-NPT pool during the event period. SSX will be additionally distributed on top of the base NPT reward.

    [About]
    1. Period: 2022.12.23 08:00:00 ~ 2023.12.23 07:59:59 [UTC] (Extended)
    2. Event pool: SSX-NPT pool
    3. Rewards: SSX tokens
    
    [How To]
    1. How to participate: 
    Launch app and press Join in the [SSX Event] pop-up window 
    - Users already participating in the SSX-NPT pool: Complete verification to join
    - New participants of the SSX-NPT pool: Deposit assets to the event pool to join
    2. How to receive event rewards
    - During the event: Event rewards claimed along with base rewards
    - After the event: Claim rewards from Wallet > Yield Farm >  Unclaimed
    
    [Precautions]
    - SSX rewards are distributed to users who have completed event participation within the event period.
    - Make sure that the event pop-up text has changed from [Join] to [Join Complete] once you complete event participation.
    - Users who have already been using the SSX-NPT pool will have their previously accumulated rewards distributed first before being admitted to the event.
    - New participants of the SSX-NPT pool will receive SSX rewards from the point of deposit until the event period ends.
    - The event period may be subject to change.
    
    Thank you for your participation.
    
    
    
`,
  },
};
