export const maintenanceNoti230707 = {
  ko: {
    subject: "[공지] 서비스 업데이트에 따른 점검 사전 안내",
    body: `네오핀은 서비스 및 상품의 확장과 사용자 편의성 개선을 위해 업데이트를 진행할 예정입니다.
    업데이트 시간 동안 점검을 진행하여 서비스가 중단될 예정이오니, 자산 예치 및 인출이 필요하신 경우 참고해주시기 바랍니다.
    
    [ 점검 일시 ]
    - 2023년 7월 11일(화) 14:00 ~ 16:00(KST)
    * 점검 시간은 상황에 따라 변동될 수 있습니다.
    
    [ 업데이트 내용 ]
    1. ETH Liquid Stake 상품 추가
    - LiquidStake 상품에 ETH를 스테이킹하면 지분만큼 npETH를 획득하고, 다른 DeFi 상품에서 활용할 수 있습니다.
    
    2. ETH Early Bird 이벤트 시작
    - 이벤트 기간동안 ETH Liquid Stake 상품과 ETH Stake 상품에 스테이킹하면 스테이킹 기간 및 수량에 따라 NPT(Polygon) 보상을 지급할 예정입니다.
    - NPT(Polygon) 보상은 이벤트가 종료된 후 일괄 지급됩니다.
    
    3. 편의성 개선
    - 네트워크가 느린 환경에서도 서비스를 원활하게 이용할 수 있도록 앱을 최적화하고 개선하였습니다.
    
    더 다양하고 안전한 디파이 상품들을 출시할 예정이오니 많은 관심 부탁드립니다.
    항상 네오핀을 이용해주셔서 감사합니다.
    
    
    `,
  },
  en: {
    subject: "[Maintenance] Service Update and Maintenance",
    body: `NEOPIN will be undergoing maintenance to enhance products and improve user experience. Please note that service will be temporarily suspended during the maintenance period. If you need to deposit or withdraw funds, please check the date and time below and plan accordingly.

    [ Schedule ]
    2023/07/11(Thu.) 05:00 ~ 07:00(UTC)
    * Subject to change based on circumstances.
    
    [ Updates ]
    1. ETH Liquid Stake Now Available
    By staking ETH in the Liquid Staking product, users can earn npETH, a proof-of-stake token, which can be utilized in other DeFi products.
    
    2. ETH Early Bird Event
    - During the event period, participants who stake in the ETH Liquid Stake and ETH Stake products will receive NPT(Polygon) rewards based on the staking duration and amount.
    - The NPT(Polygon) rewards will be distributed after the event concludes.
    
    3. Performance Improvement
    - Optimized and improved the app to ensure smooth service usage even in slow network environments.
    
    Stay tuned for more DeFi products to come.
    Thank you for your continuous support.
    
    
    `,
  },
};
