/* eslint-disable import/no-anonymous-default-export */
export const addNtpReward220228 = {
  ko: {
    subject: "[종료] KLAY, TRX 스테이킹 시 NPT 추가 보상 이율 인상",
    body: `안녕하세요.
블록체인 오픈플랫폼 네오핀입니다.

지난 2월 26일(토)부터 3월 31일(목)까지 약 34일간 진행했던 KLAY, TRX 스테이킹 시 추가 지급하는 NPT 보상의 이율 인상 이벤트가 4월 1일(금) 00시를 기점으로 종료되었습니다.

현재 네오핀은 클레이튼(KLAY), 트론(TRX) 스테이킹 시, 네오핀 토큰(NPT) 추가 보상을 계속 진행하고 있습니다.

감사합니다.
네오핀 드림

====================

안녕하세요.
블록체인 오픈플랫폼 네오핀입니다.

네오핀 토큰(NPT)의 글로벌 동시 상장을 기념하여 오는 2월 26일(토)부터 3월 31일(목)까지 스테이킹 NPT 보상 이율을 기존 APR 3.0%에서 APR 9.0%으로 인상합니다.

현재 네오핀은 클레이튼(KLAY), 트론(TRX) 스테이킹 시, 네오핀 토큰(NPT)을 추가 보상하고 있습니다.

타 서비스보다 높은 스테이킹 이율과 더불어 NPT 추가 보상도 함께 경험해보세요.

1. NPT 보상 이율 : APR 9.0% (기존 APR 3.0% 대비 약 3배 인상)
2. 적용 시점 : 2022. 02. 26. 09:00 (KST)
3. 해제 시점 : 2022. 03. 31. 24:00 (KST)

감사합니다.
네오핀 드림


    `,
  },
  en: {
    subject:
      "[Event Closed] Increase in Additional NPT Reward Rate for KLAY, TRX Staking",
    body: `Hi, this is NEOPIN, an open blockchain platform for all.

Please be informed that the “Increased Interest Rate in Additional NPT Reward Event for Staking Klaytn(KLAY) and Tron(TRX)” that initiated on Feb. 26th, 2022 has now been closed as of Mar. 31st, 2022 3:00:00 PM (UTC).

Please note: NEOPIN still provides additional NPT Rewards for staking Klaytn(KLAY) and Tron(TRX).

Team NEOPIN

====================

Hi, this is NEOPIN, an open blockchain platform for all.

To celebrate the DUAL listing of NPT on two global exchanges, we are increasing the NPT Reward rate for Staking from 3% APR to 9% APR from Feb. 26th, 2022 to Mar. 31st, 2022.

NEOPIN is currently providing additional NPT rewards for staking Klaytn(KLAY) and Tron (TRX).

Enjoy additional NPT Rewards and higher staking returns!

1. NPT Reward rate: 9% APR (increased from 3% APR)
2. Period of Application: Feb. 26th, 2022, 00:00 — Mar. 31st, 2022 15:00 (UTC)

Team NEOPIN

    `,
  },
};
