import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "../style/app-download-page.module.scss";
import MockupImg from "../assets/img/download/mockup.png";
import NeopinLogoImg from "../assets/img/download/neopin-logo.png";

interface IProps {
  lang: string;
}

export interface IEventProps {
  lang: string;
}

const Event: React.FC<IProps> = ({ lang }) => {
  const { t, i18n } = useTranslation(["download"]);
  useEffect(() => {
    i18n.changeLanguage(lang.toLowerCase());
  }, [lang, i18n]);
  const AOS_DEEP_LINK = "https://neopin.page.link/uDgG";
  const IOS_DEEP_LINK = "https://neopin.page.link/RDgS";
  return (
    <div className={`${styles.pageAppDownload} ${lang.toLowerCase()}`}>
      <div className={styles.wrapper}></div>
      <div className={styles.header}>
        <Link to="/" className={styles.logo}>
          <img src={NeopinLogoImg} alt="neopin"></img>
        </Link>
      </div>
      <div className={styles.flexContainer}>
        <div className={styles.context}>
          <h1>{t("h1")}</h1>
          <h2>{t("h2")}</h2>
          <div className={styles.paragraph }>
            <p>{t("context1")}</p>
            <p>{t("context2")}</p>
          </div>
          <div className={styles.downloadButtons}>
            <a
              className={`${styles.playStore} ${styles.btnStore}`}
              href={AOS_DEEP_LINK}
            >
              구글 플레이 스토어
            </a>

            <a
              className={`${styles.appStore} ${styles.btnStore}`}
              href={IOS_DEEP_LINK}
            >
              애플 앱 스토어
            </a>
          </div>
        </div>
        <div className={styles.mockupImg}>
          <img src={MockupImg} alt="Neopin mockup img" />
        </div>
      </div>
    </div>
  );
};

export default Event;
