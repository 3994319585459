export const airdrop3spaceArt221219 = {
  ko: {
    subject:
      "[소식] 네오핀 X 세번째공간(3space Art) NFT 에어드랍 이벤트 사전 안내",
    body: `안녕하세요, 네오핀입니다.

네오핀이 NFT 지갑 출시와 세번째공간(3space Art) 온보딩을 기념하여 주목받는 신진작가 ‘AVANT CLASSIC’의 NFT 에어드랍 이벤트를 진행할 예정입니다.

[네오핀 X 세번째공간 NFT 에어드랍 이벤트]
- 이벤트 기간: 2022.12.21(수) 17:00 ~ 28(수) 16:59 [KST]
- 당첨자 발표: 2023.1.5(목)
- 참여 방법: SNS 참여 미션 수행 & 네오핀 회원가입 KYC 인증
- 에어드랍: 총 132만 원 상당의 AVANT CLASSIC 구미나이트베어 NFT 4종
   ① <a href="https://3space.art/art/871">Plants vs Nightbear</a>
   ② <a href="https://3space.art/art/870">Freddy Nightbear</a>
   ③ <a href="https://3space.art/art/813">Coffee Addict Nightbear</a>
   ④ <a href="https://3space.art/art/812">COVID 19 Nightbear</a>

※ KYC 인증 소요 시간을 고려해 이벤트 미리 앱 다운로드 후, 회원가입 및 KYC 완료를 권장드립니다.

12월 21일 수요일, 자세한 참여방법을 안내해드릴 예정이오니 많은 관심 부탁드립니다. 


  `,
  },

  en: {
    subject: "[Announcement] NEOPIN X 3space Art NFT Airdrop Pre-notice",
    body: `NEOPIN will be joined by 3spaceArt in an NFT airdrop event featuring super rookie artist, AVANT CLASSIC.

[NEOPIN X 3art Space NFT Airdrop]
- WHEN: 2022.12.21(Wed.) 08:00 ~ 28(Wed.) 07:59 [UTC]
- WINNER ANN: 2023.1.5(Thu.)
- HOW: Social media participation tasks & NEOPIN KYC verification 
- REWARDS: 4 Gummy Nightbears worth 1,000$
    ① <a href="https://3space.art/art/871">Plants vs Nightbear</a>
    ② <a href="https://3space.art/art/870">Freddy Nightbear</a>
    ③ <a href="https://3space.art/art/813">Coffee Addict Nightbear</a>
    ④ <a href="https://3space.art/art/812">COVID 19 Nightbear</a>

※ KYC verification may take up to three working days. We recommend you to download the app and complete the process before the event ends.

Event details will be announced on Dec 21(Wed.).
    
    
`,
  },
};
