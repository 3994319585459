/* eslint-disable import/no-anonymous-default-export */
export const notice220214 = {
  ko: {
    subject: "[안내] 네오핀 개인정보처리방침 개정 안내",
    body: `안녕하세요. 네오핀(NEOPIN)입니다.

네오핀을 이용해 주시는 회원 여러분께 진심으로 감사 드립니다.
네오핀 개인정보 처리방침 개정 안내 드리오니 개정 및 수정된 내용을 확인해 주시기 바랍니다
 

1. 개정본 시행일

2022년 2월 15일자로 효력이 발생하게 됩니다.

현재 적용중인 개인정보 처리방침은 이후 효력을 상실하게 되며, 개정된 개인정보 처리방침이 적용됩니다.

 

2. 문의 및 이의제기

개정되는 개인정보 처리방침에 대한 문의 및 이의제기는 support@neopin.io로 접수해 주시기 바랍니다.

시행일 전까지 이의제기 및 거부 의사를 표시(회원탈퇴)하지 않고 서비스를 계속 이용하시는 경우, 개정된 개인정보 처리방침에 동의하신 것으로 간주됩니다.

 3. 변경사항 

<img src="/assets/img/notice/privacy-20220215(1).png" />
<img src="/assets/img/notice/privacy-20220215(2).png" />

언제나 더 나은 서비스를 위해 노력하는 네오핀이 되겠습니다.

감사합니다.`,
  },
  en: {
    subject: "",
    body: "",
  },
};
