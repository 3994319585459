export const governanceOpen230829 = {
  ko: {
    subject: "[공지] 네오핀, 클레이튼 개인 홀더 대상 거버넌스 투표 플랫폼 출시",
    body: `안녕하세요, 네오핀입니다. 

    네오핀은 클레이튼 체인 최초로 클레이튼 거버넌스 포럼에 올라온 의제에 대해 개인 사용자들이 네오핀을 통해 투표권을 행사할 수 있도록 거버넌스 투표 플랫폼을 출시했습니다.
    
    네오핀에 KLAY를 스테이킹한 사용자는 네오핀 통해 거버넌스 카운슬(GC) 멤버 투표에 참여할 수 있습니다. 
     
    [클레이튼 거버넌스 투표 참여 방법]
    1. [투자] 탭 — [Liquid Stake] 또는 [Stake] 탭 — KLAY 상품 선택
    2. [거버넌스로 이동하기] 버튼 선택 
    3. 거버넌스 창에서 현재 진행 중인 안건 선택 후 상세 내용 확인
    4. 투표권 활용하여 찬성 혹은 반대 선택 후 트랜잭션 진행
    5. 트랜잭션 정보 확인 후 승인
    
    항상 네오핀을 이용해 주셔서 감사합니다.
    
    
    `,
  },
  en: {
    subject:
      "[Announcement] NEOPIN Launches Klaytn Governance Voting For KLAY Holders",
    body: `NEOPIN has launched Klaytn governance voting platform that enables individual users to exercise their voting rights through NEOPIN on topics presented in the Klaytn Governance Forum. 

    Users who stake KLAY on NEOPIN can actively engage in Governance Council (GC) voting via NEOPIN.
    
    [How to participate]
    1. Go to Earn > Liquid Stake or Stake tab > select KLAY product.
    2. Click the “Go to Governance” button.
    3. Review and select an agenda.
    4. Vote "YES" or "NO" then proceed with the transaction. 
    5. Review and authorize the transaction.
    
    Thank you for your continuous support.
    
    `,
  },
};
