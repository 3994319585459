export const DVisionPartnership220404 = {
  ko: {
    subject: "[파트너십] 네오핀, 디비전 네트워크와 전략적 파트너십 체결",
    body: `안녕하세요.
블록체인 오픈플랫폼 네오핀입니다.

네오위즈홀딩스의 블록체인 전문 손자회사 네오핀은 ‘디비전 네트워크(DVISION Network)’와 ‘메타버스 플랫폼 사업에 대한 전략적 파트너십’을 체결했습니다.

이번 협약은 양사가 메타버스 플랫폼 ‘디비전 월드’를 공동으로 개발 운영하여 사회·경제·문화 전체를 아우르는 광범위한 메타버스 모델 구축을 목표로 체결되었습니다. 특히, 네오핀과 디비전은 각각 발행한 토큰의 생태계 확장을 위해 연계 서비스를 제공할 계획입니다.

네오핀은 이번 파트너십 체결 이후 메타버스 생태계 내에서 P2E, S2E, NFT 사업을 더욱 구체화시켜 나갈 방침입니다
`,
  },

  en: {
    subject:
      "[Partnership] NEOPIN signs strategic partnership with Dvision Network",
    body: `Hi, we are NEOPIN, an open blockchain platform for all.

NEOPIN, a blockchain subsidiary of NEOPLY, has announced that it has signed a strategic partnership with DVision Network regarding the metaverse platform business.

This partnership was signed between the two companies for joint development and operation of a metaverse platform “DVision World''. Its aim is to build an all-encompassing metaverse model that embraces society, economy, and culture. NEOPIN and DVision also plan to provide connected services that will expand the ecosystem of each company’s token.

NEOPIN’s objective is to concretize its P2E, S2E, and NFT businesses within the metaverse ecosystem for a better user experience for all.
`,
  },
};
