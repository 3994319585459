/* eslint-disable import/no-anonymous-default-export */
export const bithumbListing220311 =  {
  ko: {
    subject: "[상장] 네오핀 토큰(NPT) 빗썸 거래소 상장",
    body: `안녕하세요.
블록체인 오픈플랫폼 네오핀입니다.

‘네오핀 토큰(NPT)’이 빗썸 거래소에 오는 11일(금) 상장합니다. 11일(금) 한국시간 13시 30분에 입금이 개시되며, 11일(금) 16시에 NPT/KRW, NPT/BTC 거래 페어가 개시될 예정입니다.

1. 거래소 : Bithumb (빗썸)
2. 토큰명 : NPT
3. 마켓 : NPT/KRW, NPT/BTC
4. 입금 개시 : 2022년 3월 11일(금) 13:30 (KST)
5. 거래 개시 : 2022년 3월 11일(금) 16:00 (KST)
     `,
  },
  en: {
    subject: "[Listing] NEOPIN Token(NPT) listing on Bithumb",
    body: `Hi, we are NEOPIN, an open blockchain platform for all. 

    We brought you some more exciting news for you today: NPT (Neopin Token) will be listed on Bithumb!

    Deposit will open on Mar. 11th, 2022 04:30 (UTC), and the trading pair for NPT-KRW will begin on Mar. 11th, 2022 07:00 (UTC).

    New listing on “Bithumb”:
1. Exchange: Bithumb
2. Name of Token: NPT
3. Market: NPT/KRW, NPT/BTC
4. Opening Date of Deposit: Mar. 11th, 2022 04:30 (UTC)
5. Opening Date of Trading Pair: Mar. 11th, 2022 07:00 (UTC)
    `,
  },
};
