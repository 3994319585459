export const listingIndodax230509 = {
  en: {
    subject: "[Listing] NEOPIN Token $NPT listing on INDODAX",
    body: `We are thrilled to announce that the NEOPIN Token $NPT will be listed on INDODAX!


    Details: 
    1. Exchange: INDODAX
    2. Token Ticker: $NPT
    3. Market: NPT/IDR
    4. Deposits will open on May 10, 2023 at 2:00 PM (UTC+7) / 7:00 AM (UTC)
    5. Trading will begin on May 11, 2023 at 2:00 PM (UTC+7) / 7:00 AM (UTC)
    
    
    As always, we would like to express our gratitude to our community for their continued support, and we look forward to sharing more updates and milestones with you in the future!
    
    `,
  },
};
