export const iosMaintenance230331 = {
  en: {
    subject: "[Notice] Service Update Completed and iOS Usage Notice",
    body: `NEOPIN has been updated to expand its product offerings and improve usability.
    However, the new version of the iOS app is pending review by the Apple App Store, causing a delay in its release. In the meantime, the existing iOS v2.1.0 version is available for use, but some features may be limited. We will make an announcement as soon as the review is complete.
    
    The updated Android version is available and all services are functioning normally.
    
    Thank you for your continued support of NEOPIN.
    `,
  },
};
