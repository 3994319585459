import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import colors from "../../style/colors";

interface IProps {
  lang: string;
}

const Footer: React.FC<IProps> = ({ lang }) => {
  const { t, i18n } = useTranslation("home");
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);
  return (
    <FooterEl>
      <div className="container col-lg-10  mt-0 px-4 px-lg-5 footer-container">
        <div className="row">
          <div className="me-5 mt-2">
            <div className="mt-0 mb-4">
              <p className="mt-10 mb-2 footer-links-wrapper">
                <a
                  className=" text-decoration-hover-underline terms-link"
                  href="terms.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("termsOfService")}
                </a>
                <a
                  className=" text-decoration-hover-underline privacy-link"
                  href="privacy.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("privacyPolicy")}
                </a>
              </p>
            </div>
            <div className="mt-0 email-tel">
              <span className="b-0">
                Email:{" "}
                <a href="mailto:support@neopin.io" className="b-0">
                  support@neopin.io
                </a>
              </span>
              <span className="b-0">Tel: +65 86906170 </span>
            </div>
          </div>
        </div>
        <div className="social-links">
          <SnsButton
            link="#"
            iconSrc={require("../../assets/img/sns/discord-icon.png").default}
            name="discord"
          />
          <SnsButton
            link="https://twitter.com/neopinofficial"
            iconSrc={require("../../assets/img/sns/twitter-icon.png").default}
            name="twitter"
          />
          <SnsButton
            link="http://medium.com/neopin-official"
            iconSrc={require("../../assets/img/sns/medium-icon.png").default}
            name="medium"
          />
          <SnsButton
            link="https://t.me/neopin_announcements"
            iconSrc={require("../../assets/img/sns/telegram-icon.png").default}
            name="telegram"
          />
        </div>
      </div>
      <div className="container px-4 px-lg-5 copy-footer"></div>
    </FooterEl>
  );
};

interface ISnsButton {
  link: string;
  iconSrc: any;
  name: string;
}
export const SnsButton: React.FC<ISnsButton> = (props) => {
  return (
    <SocialButton {...props}>
      <a
        href={props.link}
        target="_blank"
        className={props.name}
        rel="noopener noreferrer"
      >
        <img src={props.iconSrc} alt={props.name} />
      </a>
    </SocialButton>
  );
};

interface IFooterEl {}
const FooterEl = styled.footer<IFooterEl>`
  background-color: ${colors.bgLight};
  padding: 3rem 0;
  .footer-container {
    display: flex;
    flex-direction: column;
  }
  .footer-links-wrapper {
    display: flex;
    flex-direction: column;
    font-size: 18px;

    a {
      color: #000;
      &.terms-link {
        padding: 0 32px 0 0;
      }
      &.privacy-link {
        font-weight: bold;
        padding: 20px 0 0;
        &::before {
          display: none;
        }
      }
    }
  }
  .email-tel {
    display: flex;
    flex-direction: column;
  }
  .social-links {
    margin-top: 70px;
    margin: 70px auto 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 229px;
  }
  @media screen and (min-width: 992px) {
    .footer-container {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }
    .footer-links-wrapper {
      flex-direction: row;
      align-items: center;
      a.privacy-link {
        position: relative;
        padding: 0 32px;
        &::before {
          position: absolute;
          left: 0px;
          top: 5px;
          width: 1px;
          display: inline-block;
          content: "";
          height: 12px;
          background-color: #000;
        }
      }
    }
    .email-tel {
      flex-direction: row;
      span {
        margin-right: 10px;
      }
    }
    .social-links {
      margin: 0;
    }
  }
`;

const SocialButton = styled.span`
  position: relative;
  display: inline-block;
  width: 46px;
  height: 46px;
  background-color: #a6b4b8;
  border-radius: 25px;

  a {
    display: inline-block;
    padding: 7px;
  }
  img {
    width: 32px;
    height: 32px;
  }
`;

export default Footer;
