export const ethPool230412 = {
  en: {
    subject: "[Announcement] NEOPIN Adds EL & HVH tokens on Swap Pool",
    body: `NEOPIN has added two new tokens to its Ethereum network services:

    - Elysia EL Token
    - HAVAH HVH Token
    
    In addition, we have added two new Swap Pool pairs:
    
    - NPT-EL Swap Pool
    - NPT-HVH Swap Pool
    
    With these new products, you can now provide liquidity and swap them easily through NEOPIN.
    
    Thank you for your continuous support for NEOPIN.
    
    `,
  },
};
