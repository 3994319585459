/* eslint-disable import/no-anonymous-default-export */
export const golfImpact220314 = {
  ko: {
    subject:
      "[이벤트] 크립토 골프 임팩트 KLAY 에어드랍 이벤트 (후원: NEOWIZ x Klaytn x NEOPIN)",
    body: `안녕하세요.
블록체인 오픈플랫폼 네오핀입니다.

크립토골프임팩트(CGI)에서 KLAY 에어드랍 이벤트를 진행합니다.

네오위즈, 클레이튼 그리고 네오핀은 크립토 골프 임팩트 사전 출시 마케팅의 일환으로 네오핀 지갑 보유자 7,000명을 대상으로 총 15만 달러 상당의 KLAY 경품을 지급하는 대규모 에어드랍 이벤트를 진행합니다.

※ ‘크립토 골프 임팩트(CGI)’는 블록체인 오픈플랫폼 네오핀(NEOPIN)을 기반으로 서비스 되며, 클레이튼(Klaytn) 메인넷 상에 구축된 플레이 투 언 (Play to Earn) 모바일 골프 게임입니다.

참여 기간 : 2022년 3월 14일(월) ~ 23일(수)

참여 방법 : 
● 이벤트 페이지, SNS 채널에 소개된 과제 완료
● 네오핀 지갑 Klaytn 주소 등록
● 7,000명 럭키 추첨 후 네오핀 지갑에 $20 상당의 KLAY 에어드랍
※ 이벤트에 대한 상세 안내는 크립토 골프 게임 이벤트 공지사항 미디엄 포스팅에서 확인하시면 됩니다.
<a href="https://medium.com/@cryptogolfimpact/b7551c5bfdf5">https://medium.com/@cryptogolfimpact/b7551c5bfdf5</a>

지급 일정 : 2022년 3월 25일(금)
    
     `,
  },
  en: {
    subject:
      "[EVENT] Crypto Golf Impact KLAY Airdrop Event (Sponsored by NEOWIZ x Klaytn x NEOPIN)",
    body: `Hi, this is NEOPIN, an open blockchain platform for all.

Crypto Golf Impact(CGI) is holding a KLAY airdrop event.

As part of the pre-release marketing for Crypto Golf Impact, the three partners, NEOWIZ, Klaytn and NEOPIN will be launching a large-scale airdrop campaign with $150,000 worth of KLAY prize pool for 7,000 NEOPIN wallet holders.

ICYMI : Crypto Golf Impact is Play-to-Earn mobile golf application powered by NEOPIN blockchain platform - which is build on the Klaytn mainnet.

1) Period of the Event
March. 14th, 2022 ~ March. 23rd, 2022

2) How to participate
● Complete the tasks outlined in the event page which includes following Klaytn and Crypto Golf Impact’s official Twitter account, and Social media channels.
● Register Klaytn address of the NEOPIN wallet
● A total of 7,000 random users will be selected via lucky draw to receive the $20.00 equivalent of KLAY tokens that will be airdropped to their respective NEOPIN wallets.
※ For more details, Please refer to the notice on the Crypto Golf Impact’s official Announcements page.
<a href="https://medium.com/@cryptogolfimpact/b7551c5bfdf5">https://medium.com/@cryptogolfimpact/b7551c5bfdf5</a>

3) Reward Schedule
Winners will be announced along with the token distribution on March. 25th, 2022.

    `,
  },
};
