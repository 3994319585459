import React, { useState } from "react";
import "../style/faq.scss";
export interface IFqaQnaProps {
  question: string;
  answer: string;
  index: number;
  selectIndex?: undefined | number;
}

const FaqQna: React.FC<IFqaQnaProps> = ({
  question,
  answer,
  index,
  selectIndex,
}) => {
  const [isActive, setIsActive] = useState<boolean>(
    selectIndex === index ?? false
  );
  const toggleQuestion = (isOpen: boolean) => {
    setIsActive(!isOpen);
  };
  return (
    <div className={`qna index-${index}`}>
      <div
        className={`question ${isActive ? "active" : "disable"}`}
        onClick={() => toggleQuestion(isActive)}
      >
        <div className="question-wrap">
          <span className="icon-q"></span>
          <span className="question-text">{question}</span>
        </div>
        <div className="icon-arrow-down"></div>
      </div>
      <div
        className="answer"
        dangerouslySetInnerHTML={{ __html: answer }}
      ></div>
    </div>
  );
};

export default FaqQna;
