export const bravenineBvt220512 = {
  ko: {
    subject: "[공지] 브레이브 나인 P2E 서버 오픈, 네오핀 온보딩",
    body: `안녕하세요.
블록체인 오픈 플랫폼 네오핀입니다.

네오위즈의 인기 모바일 게임 ‘브레이브 나인'이 5월 12일, P2E 서버를 오픈하고 네오핀에 온보딩되었습니다.

이에 따라 네오핀에서 브레이브 나인의 게임 내 토큰 ‘브레이브 토큰(BVT)’을 활용해 풀 예치, 스왑 상품을 이용할 수 있습니다.

<NPT-BVT 풀 예치·스왑 상품 출시>
NPT-BVT 풀 예치: NPT-BVT 풀 예치 시, NPT 보상
NPT-BVT 스왑: NPT↔BVT 간 스왑 가능

<브레이브 나인 소개>
2017년 출시 이후 지속적인 인기를 끌어온 한국의 모바일 전략 RPG, 브라운더스트의 글로벌 서비스입니다. 

300개 이상의 매력적인 용병과 일러스트, 풍부한 스토리로 글로벌 누적 이용자 수 500만 명을 기록하며 세계 게임 유저들로부터 관심을 받고 있습니다.

브레이브 나인은 네오핀 온보딩을 시작으로 NFT 발행, 거래소 오픈 등 콘텐츠를 확대해나갈 방침입니다.

<a href="https://www.bravenine.com/en">▶ 브레이브 나인 홈페이지 바로가기</a>

블록체인 생태계 확장을 위해 노력하고 있는 네오핀과 브레이브 나인에 많은 관심 부탁드립니다.

감사합니다.
`,
  },
  en: {
    subject: "[Notice] “Brave Nine” onboards NEOPIN with P2E server launch",
    body: `
    Hello,
We are NEOPIN, an open blockchain platform for all.

We are pleased to announce that “Brave Nine”, a top mobile game by NEOWIZ, has onboarded NEOPIN by launching its P2E server on May 12th. You may now use in-game “Brave Tokens (BVT)” for Pool Deposit and Swap services on our NEOPIN platform. 

<NPT-BVT Pool Deposit/Swap services launched>
NPT-BVT Pool Deposit: NPT rewarded for NPT-BVT Pool 
NPT-BVT Swap: Swap available between NPT and BVT

<More on “Brave Nine”>
“Brave Nine” is the global service name of “Brown Dust”, a Korean mobile strategy RPG that has steadily gained popularity since its launch in 2017.

With more than 300 mercenaries, abundant illustrations, and rich storylines, “Brave Nine” has recorded 5 million cumulative users globally and has been in the spotlight among global gamers ever since.

Starting with its onboarding to NEOPIN, “Brave Nine” plans to expand its content by issuing NFTs and operating its own exchange.

<a href="https://www.bravenine.com/en">▶ Go to Brave Nine Homepage</a>

<Download Brave Nine>
<a href="https://play.google.com/store/apps/details?id=com.neowizgames.game.browndust.srpg.global&hl=us&shortlink=global&pid=Brand%20Site%20Download%20Global">▶ Google Play Store</a>
<a href="https://apps.apple.com/us/app/brave-nine-strategy-rpg/id1299021478">▶ App Store</a>

We appreciate your support and interest in NEOPIN and Brave Nine. We will keep striving to expand the blockchain ecosystem for all.

Thank you. 

Team NEOPIN
`,
  },
};
