export const huobiListing220412 = {
  ko: {
    subject: "[상장] 네오핀 토큰(NPT) 후오비 글로벌 거래소 상장",
    body: `안녕하세요.
블록체인 오픈플랫폼 네오핀입니다.

‘네오핀 토큰(NPT)’이 후오비 글로벌 거래소에 오는 4월 15일(금) 상장합니다. 4월 12일(화) 한국시간 오후 6시 30분에 입금이 개시되며, 4월 15일(금) 오후 7시에 NPT/USDT 거래 페어가 개시될 예정입니다.

1. 거래소 : Huobi Global (후오비 글로벌)
2. 토큰명 : NPT
3. 마켓 : NPT/USDT
4. 입금 개시 : 2022년 4월 12일(화) 오후 6:30 (KST)
5. 거래 개시 : 2022년 4월 15일(금) 오후 7:00 (KST)
`,
  },
  en: {
    subject: "[Listing] Neopin Token(NPT) listing on Huobi Global",
    body: `Hi, we are NEOPIN, an open blockchain platform for all.

Another thrilling piece of news brought to you today: NPT will be listed on Huobi Global!

Deposit will open at 9:30 AM (UTC) on Apr. 12th, 2022, and the trading pair for NPT-USDT will begin at 10:00 AM (UTC) on Apr. 15th, 2022.

New listing on “Huobi Global” :
1. Exchange : Huobi Global
2. Name of Token : NPT
3. Market : NPT/USDT
4. Opening Date of Deposit : Apr. 12th, 2022. 9:30 AM (UTC)
5. Opening Date of Trading Pair : Apr. 15th, 2022. 10:00 AM (UTC)
`,
  },
};
