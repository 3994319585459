export const airdrop3spaceArtEnd221228 = {
  ko: {
    subject:
      "[소식] 네오핀 X 세번째공간(3space Art) NFT 에어드랍 이벤트 종료 안내",
    body: `안녕하세요, 네오핀입니다.

    네오핀과 세번째공간(3space Art)가 함께 선보인 NFT 에어드랍 이벤트가 종료되었습니다.
    
    당첨자는 2023년 1월 5일(목) 17:00(KST) 세번째공간과 네오핀의 공식 SNS를 통해 발표될 예정입니다.
    
    많은 참여와 관심에 감사드립니다.
    
  `,
  },

  en: {
    subject: "[Announcement] NEOPIN X 3space Art NFT Airdrop Completion Notice",
    body: `NEOPIN and 3space Art’s joint NFT airdrop has been completed.

    Winners will be announced through NEOPIN and 3space Art’s social media on Thursday, Jan. 5, 2022 at 08:00 (UTC).
    
    Thank you for your participation and support.
    
`,
  },
};
