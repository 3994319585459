export const serviceUpdate230915 = {
  ko: {
    subject: "[공지] 서비스 업데이트에 따른 점검 사전 안내",
    body: `네오핀은 서비스 및 상품의 확장과 사용자 편의성 개선을 위해 업데이트를 진행할 예정입니다.
    업데이트 시간 동안 자산 입출금을 포함한 모든 서비스가 일시 중단될 예정이오니 이용에 참고 부탁드립니다.
    
    [ 점검 일시 ]
    - 2023년 9월 21일(목) 14:00 ~ 16:00(KST)
    * 점검 시간은 상황에 따라 변동될 수 있습니다.
    
    [ 업데이트 내용 ]
    1. 핀시아 체인 추가 및 FNSA 스테이킹 상품 추가
    
    2. 코스모스 체인 추가 및 ATOM 스테이킹 상품 추가
    
    3. 네오핀 앱 Explore 탭 커뮤니티 탭으로 전환
    - 멤버십과 거버넌스 기능을 포함한 커뮤니티 탭 메뉴 신설
    
    더 다양하고 안전한 디파이 상품들을 출시할 예정이오니 많은 관심 부탁드립니다.
    항상 네오핀을 이용해주셔서 감사합니다.
    
    네오핀 팀 드림
    
    `,
  },
  en: {
    subject: "[Maintenance] Service Update and Maintenance",
    body: `NEOPIN will be undergoing maintenance to enhance products and improve user experience. Please note that service will be temporarily suspended during the maintenance period. If you need to deposit or withdraw funds, please check the date and time below and plan accordingly.

    [ Schedule ]
    21 SEP 2023 (Thu) 05:00 - 07:00 (UTC)
    *The scheduled maintenance time may be extended based on circumstances.
    
    [ Updates ]
    1. Adding Finschia chain and FNSA Staking product
    
    2. Adding Cosmos chain and ATOM Staking product
    
    3. Transforming NEOPIN App’s Explore tab to Community tab
    - The new Community tab will include Membership and Governance features.
    
    Stay tuned for more exciting DeFi product developments.
    Thank you for your continuous support and understanding.
    
    Sincerely,
    The NEOPIN Team
    
    `,
  },
};
