/** API가 아직 없어서 하드코딩. API가 추가되면 주석처리 한 부분 해제 */

import { timeRange } from "../util/time";
import { IFetcherOption, request } from "./apiRequest";

export interface INotice {
  en: {
    subject: string;
  };
  id: number;
  ko: {
    subject: string;
  };
  openAt: string;
  closeAt: string;
  title?: string;
}

export interface INoticeList {
  desc: string;
  notices: INotice[];
  result: number;
  resultString: string;
}

export const fetchNoticeList = async (url: string) => {
  const option: IFetcherOption = {
    method: "GET",

    onRequest(params: any) {
      return params;
    },

    onResponse(res: INoticeList) {
      return res;
    },

    onError(err: any) {
      return {
        desc: "",
        notices: [],
        result: 0,
        resultString: "",
      };
    },
  };
  const reqData = await request<INoticeList>(url, option);
  console.log(reqData);
  const jsonParse = {
    result: 0,
    resultString: "Success",
    desc: "",
    notices: [
      {
        id: 1,
        title: "NEOPIN Released",
        ko: { subject: "[안내] NEOPIN 서비스 오픈 안내" },
        en: { subject: "[Notice] NEOPIN Released" },
        openAt: "2022-01-25T00:00:00.063Z",
        closeAt: "2999-12-28T00:00:00.063Z",
      },
      {
        id: 2,
        title: "NEOPIN Join Event",
        ko: { subject: "NEOPIN 가입 이벤트" },
        en: { subject: "NEOPIN Join Event" },
        openAt: "2022-02-06T15:00:00.063Z",
        closeAt: "2022-02-13T23:59:00.063Z",
      },
      {
        id: 3,
        title: "NEOPIN Join Event",
        ko: {
          subject: "[안내] 사용자 증가에 따른 간헐적 접속 장애 발생 (조치완료)",
        },
        en: {
          subject:
            "[Notice] Temporary network error due to traffic congestion (Network normalized)",
        },
        openAt: "2022-02-10T01:20:04Z",
        closeAt: "2099-02-13T23:59:00.063Z",
      },
      {
        id: 4,
        title: "",
        ko: {
          subject: "[안내] 네오핀 개인정보처리방침 개정 안내",
        },

        openAt: "2022-02-14T01:20:04Z",
        closeAt: "2099-02-13T23:59:00.063Z",
      },
      {
        id: 5,
        title: "[종료] 초대코드 공유하고 NPT 받자!",
        ko: {
          subject: "[종료] 초대코드 공유하고 NPT 받자!",
        },
        en: {
          subject: "[Event Closed] Refer a friend & Earn 1NPT each!",
        },
        openAt: "2022-02-23T01:00:00Z",
        closeAt: "2099-03-02T09:00:00.063Z",
      },
      {
        id: 6,
        title: "[이벤트] 텔레그램 입장하고 커피쿠폰 받자!",
        ko: {
          subject: "[종료] 텔레그램 입장하고 커피쿠폰 받자!",
        },
        openAt: "2022-02-23T01:00:00Z",
        closeAt: "2099-03-02T09:00:00.063Z",
      },
      {
        id: 7,
        title: "[상장] 네오핀 토큰(NPT) 글로벌 동시 상장",
        ko: {
          subject: "[상장] 네오핀 토큰(NPT) 글로벌 동시 상장",
        },
        en: {
          subject:
            "[Listing] NEOPIN Token(NPT) Dual Listing on Global Exchanges",
        },
        openAt: "2022-02-28T02:50:00Z",
        closeAt: "2099-03-02T09:00:00.063Z",
      },
      {
        id: 8,
        ko: {
          subject: "[종료] KLAY, TRX 스테이킹 시 NPT 추가 보상 이율 인상",
        },
        en: {
          subject:
            "[Event Closed] Increase in Additional NPT Reward Rate for KLAY, TRX Staking",
        },
        openAt: "2022-02-28T02:50:00Z",
        closeAt: "2099-03-02T09:00:00.063Z",
      },
      {
        id: 9,
        title: "[공지] 가입이벤트 7 NPT 보상 및 스테이킹 NPT 보상 지급 개시",
        ko: {
          subject:
            "[공지] 가입이벤트 7 NPT 보상 및 스테이킹 NPT 보상 지급 개시",
        },
        en: {
          subject:
            "[Notice] Advanced Payout of NPT Rewards: 7 NPT from Sign-up Event and NPT Rewards for Staking",
        },
        openAt: "2022-02-28T02:50:00Z",
        closeAt: "2099-03-02T09:00:00.063Z",
      },
      // 220302 afo 딜레이 이슈로 공지 내림
      // {
      //   id: 10,
      //   title: "[공지] ETH-NPT AFO 모집 안내",
      //   ko: {
      //     subject: "[공지] ETH-NPT AFO 모집 안내",
      //   },
      //   en: {
      //     subject: "[Notice] ETH-NPT AFO",
      //   },
      //   openAt: "2022-02-28T02:50:00Z",
      //   closeAt: "2099-03-02T09:00:00.063Z",
      // },
      {
        id: 11,
        title: " [안내] ETH-NPT AFO 일정 연기 ",
        ko: {
          subject: " [안내] ETH-NPT AFO 일정 연기 ",
        },
        en: {
          subject:
            "[Important Announcement] ETH-NPT AFO Schedule Postponed Indefinitely",
        },
        openAt: "2022-03-02T12:00:45Z",
        closeAt: "2099-03-02T09:00:00.063Z",
      },
      {
        id: 13,
        title: "[상장] 네오핀 토큰(NPT) 빗썸 거래소 상장",
        ko: {
          subject: "[상장] 네오핀 토큰(NPT) 빗썸 거래소 상장",
        },
        en: {
          subject: "[Listing] NEOPIN Token(NPT) listing on Bithumb",
        },
        openAt: "2022-03-11T07:00:45Z",
        closeAt: "2099-01-01T09:00:00.063Z",
      },
      {
        id: 14,
        ko: {
          subject:
            "[이벤트] 크립토 골프 임팩트 KLAY 에어드랍 이벤트 (후원: NEOWIZ x Klaytn x NEOPIN)",
        },
        en: {
          subject:
            "[EVENT] Crypto Golf Impact KLAY Airdrop Event (Sponsored by NEOWIZ x Klaytn x NEOPIN)",
        },
        openAt: "2022-03-14T01:41:32Z",
        closeAt: "2099-01-01T09:00:00.063Z",
      },
      {
        id: 15,
        ko: {
          subject:
            "[파트너십] 네오핀, ‘베리파이바스프’와 트래블룰 솔루션 사용 업무 협약 체결",
        },
        en: {
          subject:
            "[Partnership] NEOPIN signs MOU with “Verify VASP” for Travel Rule Solution",
        },
        openAt: "2022-03-16T01:47:57Z",
        closeAt: "2099-01-01T09:00:00.067Z",
      },
      {
        id: 16,
        ko: {
          subject: "[공지] AFO 취소 및 기타 안내",
        },
        en: {
          subject: "[Notice] Announcement on AFO Cancellation and Others",
        },
        openAt: "2022-03-17T06:32:16Z",
        closeAt: "2099-01-01T09:00:00.067Z",
      },
      {
        id: 17,
        ko: {
          subject: "[파트너십] 네오핀, ‘클레이튼 재단’과 전략적 파트너십 체결",
        },
        en: {
          subject:
            "[Partnership] NEOPIN enters into strategic partnership with Klaytn Foundation as part of a “joint expansion of the global blockchain ecosystem”",
        },
        openAt: "2022-03-21T03:35:37Z",
        closeAt: "2099-01-01T09:00:00.067Z",
      },
      {
        id: 18,
        ko: {
          subject: "[상장] 네오핀 토큰(NPT) 코인원 거래소 상장",
        },
        en: {
          subject: "[Listing] NEOPIN Token(NPT) listing on Coinone”",
        },
        openAt: "2022-03-22T00:55:12Z",
        closeAt: "2099-01-01T09:00:00.067Z",
      },
      {
        id: 19,
        ko: {
          subject: "[공지] 네오핀 커뮤니티 사칭 주의 안내",
        },
        en: {
          subject: "[Announcement] Scam Alert for NEOPIN Users",
        },
        openAt: "2022-03-23T04:34:03Z",
        closeAt: "2099-01-01T09:00:00.067Z",
      },
      {
        id: 20,
        ko: {
          subject: "[파트너십] 네오핀, ‘NHN벅스’와 전략적 파트너십 체결",
        },
        en: {
          subject: "[Partnership] NEOPIN signs MOU with NHN Bugs",
        },
        openAt: "2022-03-24T01:56:02Z",
        closeAt: "2099-01-01T09:00:00.067Z",
      },
      {
        id: 21,
        ko: {
          subject: "[상장] 네오핀 토큰(NPT) 비트겟 거래소 상장",
        },
        en: {
          subject: "[Listing] NEOPIN Token(NPT) listing on Bitget",
        },
        openAt: "2022-03-25T01:36:52Z",
        closeAt: "2099-01-01T09:00:00.067Z",
      },
      {
        id: 22,
        ko: {
          subject: "[상장] 네오핀 토큰(NPT) 게이트아이오 거래소 상장",
        },
        en: {
          subject: "[Listing] Neopin Token(NPT) listing on Gate.io",
        },
        openAt: "2022-03-30T09:31:01Z",
        closeAt: "2099-01-01T09:00:00.067Z",
      },
      {
        id: 23,
        ko: {
          subject: "[파트너십] 네오핀, 디비전 네트워크와 전략적 파트너십 체결",
        },
        en: {
          subject:
            "[Partnership] NEOPIN signs strategic partnership with Dvision Network",
        },
        openAt: "2022-04-04T01:42:23Z",
        closeAt: "2099-01-01T09:00:00.067Z",
      },
      {
        id: 24,
        ko: {
          subject: "[이벤트] 브레이브나인(BRAVE NINE) KLAY 에어드랍 이벤트",
        },
        en: {
          subject: "[EVENT] BRAVE NINE KLAY Airdrop Event",
        },
        openAt: "2022-04-12T07:50:43Z",
        closeAt: "2099-01-01T09:00:00.067Z",
      },
      {
        id: 25,
        ko: {
          subject: "[상장] 네오핀 토큰(NPT) 후오비 글로벌 거래소 상장",
        },
        en: {
          subject: "[Listing] Neopin Token(NPT) listing on Huobi Global",
        },
        openAt: "2022-04-12T08:06:00Z",
        closeAt: "2099-01-01T09:00:00.067Z",
      },
      {
        id: 26,
        ko: {
          subject: "[공지] 크립토 골프 임팩트 정식 출시",
        },
        en: {
          subject: "[Notice] Crypto Golf Impact - Official Launch",
        },
        openAt: "2022-04-28T02:00:00Z",
        closeAt: "2099-01-01T09:00:00.067Z",
      },
      {
        id: 27,
        ko: {
          subject: "[공지] 브레이브 나인 P2E 서버 오픈, 네오핀 온보딩",
        },
        en: {
          subject:
            "[Notice] “Brave Nine” onboards NEOPIN with P2E server launch",
        },
        openAt: "2022-05-13T02:20:00Z",
        closeAt: "2099-01-01T09:00:00.067Z",
      },
      {
        id: 28,
        ko: {
          subject: "[상장] 네오핀 토큰(NPT) 코빗 거래소 상장",
        },
        en: {
          subject: "[Listing] NEOPIN Token(NPT) listing on korbit",
        },
        openAt: "2022-05-26T04:55:00Z",
        closeAt: "2099-01-01T09:00:00.067Z",
      },
      {
        id: 29,
        ko: {
          subject: "[상장] 네오핀 토큰(NPT) 비트렉스 글로벌 거래소 상장",
        },
        en: {
          subject: "[Listing] NEOPIN Token(NPT) listing on Bittrex Global",
        },
        openAt: "2022-06-03T04:15:00Z",
        closeAt: "2099-01-01T01:00:00.067Z",
      },
      {
        id: 30,
        ko: {
          subject: "[공지] NPT(네오핀 토큰) 스테이킹 서비스 출시",
        },
        en: {
          subject: "[Notice] NPT Staking Service Launch",
        },
        openAt: "2022-07-01T00:00:00Z",
        closeAt: "2099-01-01T01:00:00.067Z",
      },
      {
        id: 31,
        ko: {
          subject: "[공지] 클레이튼, 트론 스테이킹 KLAY, NPT 리워드 정산 완료 ",
        },
        en: {
          subject:
            "[Notice] Klaytn, Tron Staking KLAY, NPT Reward Compensation Completed",
        },
        openAt: "2022-07-25T10:03:00Z",
        closeAt: "2099-01-01T01:00:00.067Z",
      },
      {
        id: 32,
        ko: {
          subject: "[점검] 서비스 업데이트에 따른 점검 사전 안내",
        },
        en: {
          subject: "Prior Notice on Service Maintenance for Update",
        },
        openAt: "2022-07-25T10:05:00Z",
        closeAt: "2099-01-01T07:00:00.067Z",
      },
      {
        id: 33,
        ko: {
          subject: "[공지] 서비스 업데이트 및 신규 서비스 런칭 안내",
        },
        en: {
          subject: "[Notice] Major Update and New Service Launch",
        },
        openAt: "2022-08-01T00:00:00Z",
        closeAt: "2099-01-01T07:00:00.067Z",
      },
      {
        id: 34,
        ko: {
          subject:
            "[공지] 네오핀 웹 서비스 및 신규 서비스 출시에 대한 세부 안내",
        },
        en: {
          subject: "[Notice] Details on NEOPIN Web, Swaps & Farms Launch",
        },
        openAt: "2022-08-01T00:00:00Z",
        closeAt: "2099-01-01T07:00:00.067Z",
      },
      {
        id: 35,
        ko: {
          subject: "[점검] 서비스 업데이트에 따른 점검 안내 (완료)",
        },
        en: {
          subject:
            "[Notice] Scheduled Maintenance for Service Updates (completed)",
        },
        openAt: "2022-08-23T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 36,
        ko: {
          subject: "[점검] 서비스 업데이트에 따른 점검 안내 (완료)",
        },
        en: {
          subject:
            "[Notice] Scheduled Maintenance for Service Updates (completed)",
        },
        openAt: "2022-08-26T09:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 37,
        ko: {
          subject: "[공지] 보안설정 변경 및 점검 사전안내 (완료)",
        },
        en: {
          subject:
            "Prior Notice on Security Setting Changes and Service Maintenance (completed)",
        },
        openAt: "2022-09-28T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 38,
        ko: {
          subject: "[공지] 보안설정 변경 및 점검 안내 (완료)",
        },
        en: {
          subject:
            "[Notice] Security Setting Changes and Service Maintenance (completed)",
        },
        openAt: "2022-10-04T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 39,
        ko: {
          subject: "[소식] 네오핀 NFT 지갑 출시 안내",
        },
        en: {
          subject: "[News] NFT Wallet Service Launch",
        },
        openAt: "2022-10-04T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 40,
        ko: {
          subject: "[공지] 서비스 점검 사전 안내 (완료)",
        },
        en: {
          subject: "[Notice] Prior Notice on Service Maintenance (completed)",
        },
        openAt: "2022-10-14T08:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 41,
        ko: {
          subject: "[점검] 고객센터 문의하기 서비스 점검 사전 안내 (완료)",
        },
        en: {
          subject: "[Maintenance] Customer Service maintenance (completed)",
        },
        openAt: "2022-10-24T05:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 42,
        ko: {
          subject: "[점검] 서비스 업데이트에 따른 점검 사전 안내 (완료)",
        },
        en: {
          subject: "[Maintenance] System update notice (completed)",
        },
        openAt: "2022-11-04T09:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 43,
        ko: {
          subject:
            "[소식] 네오핀 × LILLIUS 유틸리티 NFT 단독 에어드랍 이벤트 참여 안내 (완료)",
        },
        en: {
          subject:
            "[Announcement] NEOPIN x LILLIUS Exclusive Utility NFT Airdrop Event Notice (completed)",
        },
        openAt: "2022-11-10T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 44,
        ko: {
          subject:
            "[소식] 네오핀 × LILLIUS 유틸리티 NFT 단독 에어드랍 이벤트 종료 안내",
        },
        en: {
          subject:
            "[Announcement] NEOPIN × LILLIUS Exclusive NFT Airdrop Event Completion Notice",
        },
        openAt: "2022-11-15T15:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 45,
        //// 영문만 배포
        // ko: {
        //   subject:
        //     "[소식] 네오핀 x 쉽팜 1,000 NPT & 베팅티켓 에어드랍 사전 안내",
        // },
        en: {
          subject:
            "[Announcement] NEOPIN x Sheepfarm 1,000 NPT & Betting Ticket Airdrop Pre-notice",
        },
        openAt: "2022-11-28T09:20:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 46,
        ko: {
          subject: "[소식] 네오핀 x 쉽팜 인 메타랜드 온보딩 안내 (완료)",
        },
        en: {
          subject:
            "[Announcement] NEOPIN X Sheepfarm 1,000 NPT & Betting Ticket Airdrop (completed)",
        },
        openAt: "2022-12-02T06:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 47,
        ko: {
          subject: "[공지] Yield Farm V1 상품 예치금 반환 안내 (완료)",
        },
        en: {
          subject: "[Notice] Yield Farm V1 product deposit return (completed)",
        },
        openAt: "2022-12-07T09:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 48,
        ko: {
          subject: "[공지] IPV 상품 서비스 종료 예정 안내",
        },
        en: {
          subject:
            "[Notice] Scheduled on Termination of the IPV product service",
        },
        openAt: "2022-12-08T09:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 49,
        ko: {
          subject: "[공지] ETH 지갑 주소 통합 예정 안내",
        },
        en: {
          subject: "[Notice] Scheduled on the integration of ETH  address",
        },
        openAt: "2022-12-08T09:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 50,
        ko: {
          subject: "[점검] 서비스 업데이트에 따른 점검 사전 안내 (완료)",
        },
        en: {
          subject: "[Maintenance] System update notice (completed)",
        },
        openAt: "2022-12-09T09:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 51,
        ko: {
          subject: "[공지] 네오핀 웹 서비스 이용 안내",
        },
        en: {
          subject: "[Notice] Services on NEOPIN Launchpad",
        },
        openAt: "2022-12-14T08:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 52,
        // ko: {
        //   subject: "[공지] 네오핀 웹 서비스 이용 안내",
        // },
        en: {
          subject:
            "[Announcement] NEOPIN X Sheepfarm 1,000 NPT & Betting Ticket Airdrop Completion Notice",
        },
        openAt: "2022-12-16T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 53,
        ko: {
          subject:
            "[소식] 네오핀 X 세번째공간(3space Art) NFT 에어드랍 이벤트 사전 안내",
        },
        en: {
          subject: "[Announcement] NEOPIN X 3space Art NFT Airdrop Pre-notice",
        },
        openAt: "2022-12-19T06:10:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 54,
        ko: {
          subject: "[소식] 멀티체인 지원 확대, 폴리곤 네트워크 토큰 추가 안내",
        },
        en: {
          subject:
            "[Announcement] NEOPIN integrates Polygon for multi-chain expansion.",
        },
        openAt: "2022-12-20T01:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 55,
        ko: {
          subject:
            "[소식] 네오핀 X 세번째공간(3space Art) NFT 에어드랍 이벤트 안내",
        },
        en: {
          subject: "[Announcement] NEOPIN X 3space Art NFT Airdrop",
        },
        openAt: "2022-12-21T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 56,
        // ko: {
        //   subject:
        //     "",
        // },
        en: {
          subject:
            "[Announcement] Notice for NPT Winners of NEOPIN x Sheepfarm 1,000 NPT & Betting Ticket Airdrop",
        },
        openAt: "2022-12-22T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 57,
        ko: {
          subject: "[소식] SSX-NPT Yield Farm 상품 SSX 토큰 리워드 이벤트 안내",
        },
        en: {
          subject:
            "[Announcement] Bonus Reward Event for SSX-NPT Pool (Extended)",
        },
        openAt: "2022-12-23T05:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 58,
        ko: {
          subject:
            "[소식] 네오핀 X 세번째공간(3space Art) NFT 에어드랍 이벤트 종료 안내",
        },
        en: {
          subject:
            "[Announcement] NEOPIN X 3space Art NFT Airdrop Completion Notice",
        },
        openAt: "2022-12-28T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 59,
        ko: {
          subject:
            "[파트너십] 네오핀, 엑스큐케임즈와 파트너십 체결, 인피니티 사가X 온보딩",
        },
        en: {
          subject:
            "[Partnership] NEOPIN signs partnership with XQGames, onboarding Infinity Saga X",
        },
        openAt: "2023-01-03T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 60,
        ko: {
          subject: "[공지] IPV 상품 서비스 종료 안내",
        },
        en: {
          subject: "[Notice] Termination of IPV product support",
        },
        openAt: "2023-01-09T06:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 61,
        ko: {
          subject:
            "[파트너십] 네오핀, 픽셀플레이와 파트너십 체결, 픽셀배틀 온보딩",
        },
        en: {
          subject:
            "[Partnership] NEOPIN signs partnership with Pixel Play, onboards Pixel Battle",
        },
        openAt: "2023-01-26T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 62,
        ko: {
          subject: "[파트너십] 네오핀, 프레스토 랩스와 파트너십 체결",
        },
        en: {
          subject: "[Partnership] NEOPIN signs partnership with Presto Labs",
        },
        openAt: "2023-02-15T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 63,
        ko: {
          subject: "[공지] 서비스 업데이트에 따른 점검 사전 안내",
        },
        en: {
          subject: "[Maintenance] System update notice (completed)",
        },
        openAt: "2023-02-20T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 64,
        ko: {
          subject: "NEOPIN support Polygon network now",
        },
        en: {
          subject: "NEOPIN support Polygon network now",
        },
        openAt: "2023-02-27T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 65,
        en: {
          subject: "[Partnership] NEOPIN signs partnership with Ozys",
        },
        openAt: "2023-03-06T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 66,
        en: {
          subject:
            "[Partnership] NEOPIN signs partnership agreement with Boomco",
        },
        openAt: "2023-03-08T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 67,
        en: {
          subject:
            "[Partnership] NEOPIN signs partnership agreement with Agate",
        },
        openAt: "2023-03-15T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },

      {
        id: 68,
        ko: {
          subject:
            "[Maintenance] Service Update and Maintenance - Schedule Change",
        },
        en: {
          subject:
            "[Maintenance] Service Update and Maintenance - Schedule Change",
        },
        openAt: "2023-03-27T11:15:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 69,
        ko: {
          subject:
            "[Notice] Scheduled on Termination of the S2T product service",
        },
        en: {
          subject:
            "[Notice] Scheduled on Termination of the S2T product service",
        },
        openAt: "2023-03-27T11:15:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 70,
        ko: {
          subject: "[Notice] Service Update Completed and iOS Usage Notice",
        },
        en: {
          subject: "[Notice] Service Update Completed and iOS Usage Notice",
        },
        openAt: "2023-03-31T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      // {
      //   id: 71,
      //   ko: {
      //     subject: "[Notice] NEOPIN iOS 2.2.0 Release Notice",
      //   },
      //   en: {
      //     subject: "[Notice] NEOPIN iOS 2.2.0 Release Notice ",
      //   },
      //   openAt: "2023-04-01T00:00:00Z",
      //   closeAt: "2099-01-23T07:00:00.067Z",
      // },
      {
        id: 72,
        ko: {
          subject: "[Notice] Tron Stake 2.0 and NEOPIN's Response",
        },
        en: {
          subject: "[Notice] Tron Stake 2.0 and NEOPIN's Response",
        },
        openAt: "2023-04-04T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 73,
        ko: {
          subject:
            "[Partnership] NEOPIN partners with Tripbtoz for Web3.0 travel platform",
        },
        en: {
          subject:
            "[Partnership] NEOPIN partners with Tripbtoz for Web3.0 travel platform",
        },
        openAt: "2023-04-05T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 74,
        ko: {
          subject: "[Notice] Important Notice Regarding Tron Staking",
        },
        en: {
          subject: "[Notice] Important Notice Regarding Tron Staking",
        },
        openAt: "2023-04-07T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 75,
        ko: {
          subject: "[Notice] Termination of the S2T product service",
        },
        en: {
          subject: "[Notice] Termination of the S2T product service",
        },
        openAt: "2023-04-10T01:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 76,
        ko: {
          subject: "[Announcement] NEOPIN Adds EL & HVH tokens on Swap Pool",
        },
        en: {
          subject: "[Announcement] NEOPIN Adds EL & HVH tokens on Swap Pool",
        },
        openAt: "2023-04-12T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 77,
        ko: {
          subject: "[Announcement] NEOPIN Adds MESH token on Swap Pool",
        },
        en: {
          subject: "[Announcement] NEOPIN Adds MESH token on Swap Pool",
        },
        openAt: "2023-04-19T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 78,
        ko: {
          subject: "[Maintenance] Service Update and Maintenance",
        },
        en: {
          subject: "[Maintenance] Service Update and Maintenance",
        },
        openAt: "2023-04-25T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 79,
        ko: {
          subject:
            "[Notice] Scheduled on Termination of the ZEMIT product service",
        },
        en: {
          subject:
            "[Notice] Scheduled on Termination of the ZEMIT product service",
        },
        openAt: "2023-05-01T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 80,
        ko: {
          subject: "[Notice] Termination of the ZEMIT product service",
        },
        en: {
          subject: "[Notice] Termination of the ZEMIT product service",
        },
        openAt: "2023-05-02T06:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 81,
        ko: {
          subject: "[Listing] NEOPIN Token $NPT listing on INDODAX",
        },
        en: {
          subject: "[Listing] NEOPIN Token $NPT listing on INDODAX",
        },
        openAt: "2023-05-09T06:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 82,
        en: {
          subject: "[Notice] Termination of the KLAP product service",
        },
        openAt: "2023-05-12T06:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 83,
        ko: {
          subject: "[공지] JOY 토큰과 스왑풀 추가",
        },
        en: {
          subject: "[Announcement] NEOPIN Adds JOY token on Swap Pool",
        },
        openAt: "2023-05-19T06:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 84,
        ko: {
          subject: "[공지] NEOPIN v2.4.0 업데이트 완료 안내",
        },
        en: {
          subject: "[Notice] NEOPIN v2.4.0 Update",
        },
        openAt: "2023-05-24T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 85,
        ko: {
          subject: "[공지] $BBC 상품 서비스 종료 예정 안내",
        },
        en: {
          subject:
            "[Notice] Scheduled on Termination of the $BBC product service",
        },
        openAt: "2023-06-02T09:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 86,
        ko: {
          subject: "[공지] $PACE 토큰과 스왑풀 추가",
        },
        en: {
          subject: "[Announcement] NEOPIN Adds $PACE token on Swap Pool",
        },
        openAt: "2023-06-08T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 87,
        ko: {
          subject: "[공지] 서비스 업데이트에 따른 점검 사전 안내",
        },
        en: {
          subject: "[Maintenance] Service Update and Maintenance",
        },
        openAt: "2023-06-26T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 88,
        ko: {
          subject: "[공지] 서비스 업데이트에 따른 점검 사전 안내",
        },
        en: {
          subject: "[Maintenance] Service Update and Maintenance",
        },
        openAt: "2023-07-07T14:11:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 89,
        ko: {
          subject: "[공지] 네오핀 멤버십 포인트 부스트 이벤트 안내",
        },
        en: {
          subject: "[Notice] NEOPIN Membership Point Boost Event",
        },
        openAt: "2023-07-11T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 90,
        ko: {
          subject:
            "[소식] 네오핀, UAE 아부다비 투자진흥청(ADIO) 혁신 프로그램 지원 선정",
        },
        en: {
          subject:
            "[Announcement] NEOPIN joins Innovation Programme of Abu Dhabi Investment Office (ADIO)",
        },
        openAt: "2023-07-11T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 91,
        ko: {
          subject: "[공지] oMEGA 토큰과 스왑풀 추가",
        },
        en: {
          subject: "[Announcement] NEOPIN Adds oMEGA token on swap pool",
        },
        openAt: "2023-07-24T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 92,
        ko: {
          subject:
            "[공지] npETH-ETH 스왑풀 추가 및 멤버십 포인트 부스트 이벤트 안내",
        },
        en: {
          subject:
            "[Announcement] npETH-ETH Swap Pool Release and Membership Point Boost Event",
        },
        openAt: "2023-08-08T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 93,
        ko: {
          subject: "[공지] 서비스 업데이트에 따른 점검 사전 안내",
        },
        en: {
          subject: "[Maintenance] Service Update and Maintenance",
        },
        openAt: "2023-08-25T12:20:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 94,
        ko: {
          subject:
            "[공지] 네오핀, 클레이튼 개인 홀더 대상 거버넌스 투표 플랫폼 출시",
        },
        en: {
          subject:
            "[Announcement] NEOPIN Launches Klaytn Governance Voting For KLAY Holders",
        },
        openAt: "2023-08-29T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
      {
        id: 95,
        ko: {
          subject: "[공지] 서비스 업데이트에 따른 점검 사전 안내",
        },
        en: {
          subject: "[Maintenance] Service Update and Maintenance",
        },
        openAt: "2023-09-15T00:00:00Z",
        closeAt: "2099-01-23T07:00:00.067Z",
      },
    ],
  };
  return {
    ...jsonParse,
    notices: jsonParse.notices
      .reverse()
      .filter((item) => timeRange(item.openAt, item.closeAt)),
  };
  // return request<INoticeList>(url, option);
};
