/** @format */

import React, { Suspense } from "react";
import { useParams } from "react-router";

interface IProps {
  lang: string;
}

export interface IEventProps {
  lang: string;
}

const Event: React.FC<IProps> = ({ lang }) => {
  const { event } = useParams();
  const EventComponent = React.lazy(
    () => import(`../components/event/${event ?? 'KycEvent'}`)
  );
  return (
    <div className={`event-page ${lang.toLowerCase()}`}>
      <Suspense fallback={<div></div>}>
        <EventComponent lang={lang} />
      </Suspense>
    </div>
  );
};

export default Event;
