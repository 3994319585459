export const partnersTripbtoz0405 = {
  en: {
    subject:
      "[Partnership] NEOPIN partners with Tripbtoz for Web3.0 travel platform",
    body: `NEOPIN announced its Memorandum of Understanding (MOU) with Tripbtoz to develop a Web 3.0-based travel platform.

    NEOPIN will help translate Tripbtoz's existing travel platform into a Web 3.0 version that will showcase the "Travel Web3.0'' ecosystem for young travelers to communicate.
    
    Thank you.
    
    `,
  },
};
