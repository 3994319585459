export const serviceComplete230227 = {
  en: {
    subject: "NEOPIN support Polygon network now",
    body: `NEOPIN has been updated for network connectivity and product expansion.
    Through this update, NEOPIN has been renewed with a new look and now offers the ability to participate in liquidity pools and token exchanges on the Polygon network, as well as the option to switch NPT tokens to the Polygon network.
    Please see below for details on the update:
    
    [ When ]
    February 27th, 2023 08:00(UTC)
    
    [ Update Contents ]
    1. NEOPIN App & Web Renewal
        Several features have been renewed to enhance user experience.
    
    2. New Product Launch
        With support for the Polygon network, new products have been launched on the Polygon network:
    
       1) Klaytn - Polygon Network Transition
           - The NPT token can be transferred between the Klaytn and Polygon chains using the network transition functionality.
           - This functionality can be accessed through the "Change" button in the "Balance" section of the “Wallet” tab.
    
        2) MATIC - NPT Pool (Polygon Network)
            You can earn rewards by providing liquidity to the MATIC-NPT pool through the "Earn" tab.
    
         3) MATIC - NPT Swap (Polygon Network)
             You can exchange MATIC and NPT tokens through the "Swap".
    
    3.Service Policy Changes
       Service usage environment related policies have been updated.
    
        1) Language Support Unified into English
            - Language support has been unified to English from Korean and English.
    
        2) Klaytn Transfer Fee Support Ended
           - Ended support for 10 free transactions per day on the Klaytn network.
    
    Thank you for your continuous support for NEOPIN.
    
`,
  },
};
