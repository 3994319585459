/** 시간과 관련된 util함수 */
import moment from "moment";
import "moment/locale/ko";
// 년월일을 YYYY-MM-DD 형식으로 출력
export function getYYYYmmDDFromISO(isoFormStr: string): string {
  const date = new Date(isoFormStr);
  return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? "0" : ""}${
    date.getMonth() + 1
  }-${date.getDate() < 10 ? "0" : ""}${date.getDate()}`;
}

// 시:분:초 형식으로 출력
export function getHHmmSSFromISO(isoFormStr: string): string {
  const date = new Date(isoFormStr);
  console.log(date);
  return `${date.getHours() < 10 ? "0" : ""}${date.getHours()}:${
    date.getMinutes() < 10 ? "0" : ""
  }${date.getMinutes()}:${
    date.getSeconds() < 10 ? "0" : ""
  }${date.getSeconds()}`;
}
export const timeRange = (startTime: string, endTime: string) => {
  const nowDate = moment();
  const openAt = moment(startTime);
  const closeAt = moment(endTime);

  return openAt < nowDate && closeAt > nowDate;
};
