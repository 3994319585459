/* eslint-disable import/no-anonymous-default-export */
export const patchNote220826 = {
  ko: {
    subject: "[점검] 서비스 업데이트에 따른 점검 안내 (완료)",
    body: `안녕하세요.
네오핀입니다.

네오핀은 상품의 확장을 위해 서비스 업데이트를 진행할 예정입니다.
업데이트 시간 동안 점검을 진행하여 서비스 이용이 잠시 중단될 예정이오니, 네오핀 이용에 있어 참고하시기 바랍니다.

- 일시: 2022.08.29(월) 오전 11:00 ~ 2:00 (3시간)

- 내용

    1) 신규 Yield Farm 상품 추가 및 스왑 지원
      Yield Farm 상품에 ‘HIBLOCKS(HIBS)’가 추가됩니다. HIBS-NPT 풀에 유동성을 공급하면 보상을 얻을 수 있습니다.
      다른 자산을 HIBS로 스왑하거나, 반대로 HIBS를 다른 자산으로 스왑할 수 있습니다.
      지갑 목록에 HIBS가 추가되어, 네오핀 지갑을 통해 HIBS를 관리할 수 있습니다.
      
    2) 신규 Stake 상품 추가
      Stake 상품에 ‘IPVERSE(IPV)’가 추가됩니다. IPV를 스테이크 상품에 위임하면 보상을 얻을 수 있습니다.

감사합니다.
`,
  },

  en: {
    subject: "[Notice] Scheduled Maintenance for Service Updates (completed)",
    body: `Please be informed that NEOPIN will perform a scheduled maintenance for service updates.
All services will be temporarily unavailable during the update. We appreciate your patience and understanding.

- Date: 29 August 2022, 2:00-5:00am UTC (3 hr) 

- Updates:

1) “IPVERSE(IPV)” available for Staking 
  - You may now stake IPV for rewards. 

2) “HIBLOCKS(HIBS)” added to Yield Farm and available for Swap 
  - You may now provide liquidity to the HIBS-NPT pool for rewards and also swap assets for/from HIBS. 
  - You may manage your HIBS assets on NEOPIN Wallet.
`,
  },
};
