/** @format */
export interface IFetcherOption {
  method: "GET" | "POST";
  onRequest: (params?: any) => any;
  onResponse: (value?: any) => any;
  onError?: (err?: any) => any;
}

export async function request<T>(
  url: string,
  option: IFetcherOption,
  params?: any
): Promise<T> {
  const fetchInit: RequestInit = {
    method: option.method,
    mode: "cors",
  };
  // 각 request별로 params parse
  const payload = option.onRequest(params);
  // method가 GET일 때 url에 query 추가
  let requestUrl = url;
  if (option.method === "GET" && payload) {
    let query = "";
    Object.keys(payload).forEach((key, i) => {
      if (i === 0) {
        query += "?";
      } else {
        query += "&";
      }
      query += `${key}=${payload[key]}`;
    });
    requestUrl += query;
  }
  // method가 POST일 때 fetch option에 body추가.
  if (option.method === "POST") {
    fetchInit.body = JSON.stringify(payload);
  }
  // fetch

  return fetch(process.env.REACT_APP_API_ENDPOINT + requestUrl, fetchInit)
    .then((res) => res.json())
    .then((res) => {
      const data = option.onResponse(res);
      return data;
    })
    .catch((err) => {
      if (option.onError) {
        return option.onError(err);
      }
      return err;
    });
}
