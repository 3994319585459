import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { SnsButton } from "../../components/home/Footer";
import { OPENTIME } from "./OpenTime";
import RemainDate from "./RemainDate";

export interface INoticeProps {
  lang: string;
}

const Background = styled.div`
  height: 100vh;
  background-image: url("assets/count_down/bg_blur.png");
  background-position: center bottom;
  background-repeat: no-repeat;
`;

const Contents = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`;

const TextDiv = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const FontStyle = `
  font-family: "THICCCBOI";
  font-style: normal;
  line-height: 100%;
`;

const Title = styled.div`
  ${FontStyle}
  font-weight: 700;
  font-size: 48px;
  color: #1d1d1d;
  line-height: 120%;
  @media only screen and (max-width: 787px) {
    font-size: 24px;
  }
`;

const SubTitle = styled.div`
  ${FontStyle}
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: #565959;
  @media only screen and (max-width: 787px) {
    font-size: 14px;
  }
`;

const Sub = styled.div`
  position: relative;
  ${FontStyle}
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  color: #565959;
  margin-bottom: 80px;
  min-width: 787px;
  @media only screen and (max-width: 787px) {
    font-size: 14px;
    margin-bottom: 32px;
    min-width: 320px;
  }
`;
const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 52px;
  align-items: center;
  justify-content: space-evenly;
  width: 480px;

  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 787px) {
    margin-top: 48px;
    width: 320px;
  }
`;

const FooterButtons = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  margin-bottom: 56px;
  @media only screen and (max-width: 787px) {
    margin-bottom: 48px;
  }
`;

const StyledSnsButton = styled(SnsButton)`
  height: 48px;
  width: 48px;
  margin: 16px;
  background-color: rgba(255, 255, 255, 0.2);
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }

  @media only screen and (max-width: 787px) {
    height: 40px;
    width: 40px;
    a {
      padding: 4px;
    }
  }
`;

const Logo = styled.img`
  height: 36px;
  width: 36px;
  position: absolute;
  top: 38px;
  left: 80px;
  @media only screen and (max-width: 787px) {
    height: 20px;
    width: 20px;
    top: 30px;
    left: 24px;
  }
`;

const Button = styled.button<{ dark?: boolean }>`
  position: absolute;
  font-family: "THICCCBOI";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  background: ${(props) => (props.dark ? "#ffffff" : `#319CEA`)};
  border-radius: 44px;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  color: ${(props) => (props.dark ? "#0088B5" : "#ffffff")};
  cursor: pointer;
  &:hover {
    background: ${(props) => (props.dark ? "#DEEBF7" : `#1178C2`)};
  }

  @media only screen and (max-width: 787px) {
    font-size: 16px;
  }
`;
const Gradient = styled.div`
  position: absolute;
  top: -4px;
  left: -4px;
  height: 56px;
  width: 192px;
  filter: blur(5px);
  border-radius: 44px;
  z-index: -1;

  background: linear-gradient(138deg, #32a1ff, #7cf0ff, #239fff);
  background-size: 400% 400%;

  //단말 최소단위를 320 / 640 으로 변경
  @-webkit-keyframes gradient {
    0% {
      background-position: 0% 2%;
    }
    50% {
      background-position: 100% 99%;
    }
    100% {
      background-position: 0% 2%;
    }
  }
  @-moz-keyframes gradient {
    0% {
      background-position: 0% 2%;
    }
    50% {
      background-position: 100% 99%;
    }
    100% {
      background-position: 0% 2%;
    }
  }
  @keyframes gradient {
    0% {
      background-position: 0% 2%;
    }
    50% {
      background-position: 100% 99%;
    }
    100% {
      background-position: 0% 2%;
    }
  }
  animation: gradient 4s ease infinite;
  @media only screen and (max-width: 787px) {
    height: 40px;
    width: 148px;
  }
`;

interface GradientButtonProps {
  dark?: boolean;
  style: React.CSSProperties;
  text: string;
  onClick?: () => void;
}

const GradientButtonDiv = styled.div`
  position: relative;
  height: 48px;
  width: 184px;

  @keyframes move_left {
    0% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes move_right {
    0% {
      transform: translateX(50%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @media only screen and (max-width: 787px) {
    height: 32px;
    width: 140px;
  }
`;

const GradientButton: React.FC<GradientButtonProps> = (props) => {
  return (
    <GradientButtonDiv {...props}>
      {props.dark ? <Gradient></Gradient> : null}
      <Button dark={props.dark}>{props.text}</Button>
    </GradientButtonDiv>
  );
};

const CountDown: React.FC<INoticeProps> = () => {
  const [open, SetOpen] = useState(dayjs().diff(OPENTIME) > 0);
  let interval: NodeJS.Timeout | null = null;

  const openCheck = useCallback(() => {
    if (dayjs().diff(OPENTIME) >= 0) {
      SetOpen(true);
      if (interval) clearInterval(interval);
      console.log("diiff");
    }
  }, [interval]);

  useEffect(() => {
    interval = setInterval(openCheck, 1000);
  }, [openCheck]);

  return (
    <Background>
      <Contents>
        <Logo src="assets/count_down/icon_logo.svg"></Logo>
        <TextDiv>
          <Title>
            NEOPIN DeFi Web APP
            <br /> COMING SOON
          </Title>
          <br />
          <SubTitle>“Stake now, boost your earnings”</SubTitle>
          <br />
          <Sub>
            Experience NEOPIN’s DeFi web application to accumulate crypto assets
          </Sub>
          <RemainDate openTime={OPENTIME}></RemainDate>
          <Buttons>
            <Link to={"/"}>
              <GradientButton
                text="Home"
                style={
                  open
                    ? {
                        animation:
                          "move_right 0.8s 1 cubic-bezier(0.33, 1, 0.68, 1)",
                        zIndex: "1",
                      }
                    : {}
                }
              ></GradientButton>
            </Link>
            {open ? (
              <GradientButton
                style={{
                  animation: "move_left 0.8s 1 cubic-bezier(0.33, 1, 0.68, 1)",
                }}
                dark
                text="Launch App"
                onClick={() => {
                  window.location.replace("https://app.neopin.io/swap");
                }}
              ></GradientButton>
            ) : (
              <></>
            )}
          </Buttons>
        </TextDiv>
        <FooterButtons>
          <StyledSnsButton
            link="https://bit.ly/neopindiscord"
            iconSrc={require("../../assets/img/sns/discord-icon.png").default}
            name="discord"
          />
          <StyledSnsButton
            link="https://bit.ly/3n8oAj7"
            iconSrc={require("../../assets/img/sns/twitter-icon.png").default}
            name="twitter"
          />
          <StyledSnsButton
            link="https://medium.com/neopin-official"
            iconSrc={require("../../assets/img/sns/medium-icon.png").default}
            name="medium"
          />
          <StyledSnsButton
            link="https://t.me/neopin_eng"
            iconSrc={require("../../assets/img/sns/telegram-icon.png").default}
            name="telegram"
          />
        </FooterButtons>
      </Contents>
    </Background>
  );
};

export default CountDown;
