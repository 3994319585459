export const airdropSheepfarm221222 = {
  // ko: {
  // },

  en: {
    subject:
      "[Announcement] Notice for NPT Winners of NEOPIN x Sheepfarm 1,000 NPT & Betting Ticket Airdrop",
    body: `We have completed the NPT airdrop for winners of the <NEOPIN x Sheepfarm 1,000 NPT & Betting Ticket Airdrop Event> which ended on the 16th of December. 

    NPT rewards were airdropped to the winners’ NEOPIN Wallet, and all winners were notified through an email notice.  
    
    Thank you for your participation.
    
    
`,
  },
};
