export const prestolabs230215 = {
  ko: {
    subject: "[파트너십] 네오핀, 프레스토 랩스와 파트너십 체결",
    body: `안녕하세요, 네오핀입니다.

    네오핀이 아시아 최대 퀀트 트레이딩 기업 ‘프레스토 랩스'와 파트너십을 체결했습니다.
    
    프레스토 랩스는 2014년 싱가포르에서 설립된 이후, 세계 최고 수준의 연구원, 엔지니어들과 함께 알고리즘에 기반한 퀀트 트레이딩 분석 기술을 자체 개발한 것으로 명성 높은 기업입니다.
    
    프레스토 랩스는 일일 거래대금이 세계 5위 규모인 3조 원에 달해 전통 금융시장에서 각광받는 기업으로 자리매김했는데요. 최근에는 다양한 분야로 사업 영역을 확장하며 주목받고 있습니다.
    
    이번 파트너십을 통해 네오핀과 프레스토 랩스는 양사가 보유한 금융 상품에 대한 노하우를 바탕으로 선진화된 가상자산 상품 개발 및 출시에 나설 예정입니다.
    
    이외에도 중앙화거래소(CEX), 탈중앙화거래소(DEX), 디파이(DeFi), 지갑 등 신규사업기회 모색 및 웹3 생태계 구축을 위한 프로젝트 투자 등 공동 협력을 이어나갈 예정이오니 많은 기대와 관심 부탁드립니다.
    
    감사합니다.
    
  `,
  },

  en: {
    subject: "[Partnership] NEOPIN signs partnership with Presto Labs",
    body: `NEOPIN has signed a strategic partnership with Presto Labs, Asia's largest quant trading company.

    Presto Labs, based in Singapore, is known for its proprietary algorithm-based quant trading analytics technology, developed by world-class researchers and engineers. The company gained widespread attention in traditional finance when it achieved fifth in daily trading volume, and now seeks to expand its business and presence.
    
    Under the partnership, NEOPIN and Presto Labs plan to collaborate on the development and launch of advanced virtual asset services, leveraging the financial expertise of both companies. 
    
    They will also explore various business opportunities to expand their Web3 ecosystems through co-building or joint investment in areas such as centralized exchanges (CEX), decentralized exchanges (DEX), decentralized finance (DeFi), and wallets. 
    
    
`,
  },
};
