export const serviceMaintenance230220 = {
  ko: {
    subject: "[공지] 서비스 업데이트에 따른 점검 사전 안내",
    body: `안녕하세요? 네오핀입니다.

    네오핀은 네트워크의 연결과 상품의 확장을 위한 업데이트를 진행할 예정입니다.
    업데이트 시간 동안 점검을 진행하여 서비스가 중단될 예정이오니, 자산 예치 및 인출이 필요하신 경우 아래 일시를 참고하여 대비해 주시기 바랍니다.
    
    [ 점검 일시 ]
    2023년 2월 27일 14:00(KST)
    
    [ 업데이트 내용 ]
    1. 네오핀 앱 & 웹 리뉴얼
    사용자님의 편의성을 높히기 위하여 일부 항목을 리뉴얼 하였습니다.
    
    2. 신규 서비스 출시
    폴리곤 네트워크 지원에 따라 폴리곤 계열 상품을 출시할 예정입니다.
      - NPT 토큰 Klaytn - Polygon 네트워크 전환 기능 적용
      - MATIC - NPT Pool 상품 추가(Polygon 네트워크)
      - MATIC - NPT 스왑 추가(Polygon 네트워크)
    
    3. 서비스 정책 변경
    서비스 이용 환경과 관련된 정책이 변경됩니다.
      - 네오핀 지원 언어, 영어로 통합 지원
    네오핀 지원 언어가 ‘영어' 로 변경됩니다. (기존: 한국어, 영어)
      - Klaytn 전송 수수료 1일 10회 지원 종료
    
    항상 네오핀을 이용해주셔서 감사합니다.
    
    
  `,
  },

  en: {
    subject: "[Maintenance] System update notice (completed)",
    body: `NEOPIN will be going through maintenance to add support for a new network and expand products.
    Please note that service will be temporarily suspended during maintenance. If you need to deposit or withdraw assets, please refer to the date and time below and plan accordingly.

    [ Schedule ]
    2023/02/27(Mon.) 05:00 (UTC)
    
    [ Updates ]
    1. NEOPIN App & Web Renewal
    Several features have been renewed to enhance user experience.
    
    2. New Product Launch
    We will release Polygon-based products in accordance with the support of the Polygon network on NEOPIN.
    - Adding Klaytn - Polygon network switching features for NPT
    - Adding MATIC - NPT Pool (Polygon network)
    - Adding MATIC - NPT Swap (Polygon network)
    
    3. Changes to Service Policy
    Service usage environment related policies will be updated.
    - Language supported unified to English
    NEOPIN has unified its language support into English (formerly Korean, English)
    - End support for 10 free transactions per day on the Klaytn network
    
    Thank you for your continued support of NEOPIN.
    
`,
  },
};
