/** @format */

import React, { useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router";
import useSWR from "swr";
import { fetchFaq, IFaq, ITopicFaq } from "../api/fetchFaq";
import "../style/faq.scss";
import { languageSelector } from "../util/language";
import FaqQna from "./FqaQna";

export interface IFaqProps {
  lang: string;
}
const Faq: React.FC<IFaqProps> = ({ lang }) => {
  const location = useLocation();

  const { data } = useSWR(`/napi/v1/faq`, fetchFaq);
  const selectLanguage = (data: IFaq): ITopicFaq[] => {
    const selected = languageSelector(data, lang);
    if (!selected) {
      return [];
    }
    return selected;
  };
  const id = Number(location.search?.substring(1).split("=")[1]);
  const hash = location.hash.substring(1);
  return (
    <div className="faq">
      {data &&
        selectLanguage(data).map((topic, index) => {
          const domID = topic.title
            ?.split("/")[0]
            .replace(" ", "")
            .toLowerCase();
          return (
            <div className={`section`} id={domID} key={topic.topic}>
              <div className="section-topic">{topic?.topic}</div>
              {topic?.qna[0] &&
                topic.qna.map((q, i) => {
                  return (
                    <FaqQna
                      question={q.question}
                      answer={q.answer}
                      key={i}
                      index={i}
                      selectIndex={domID === hash ? id : undefined}
                    />
                  );
                })}
            </div>
          );
        })}
      <div className="faq-footer">
        {lang === "EN" ? (
          <a href="mailto:help@neopin.io" className="contact-us">
            Contact Support
          </a>
        ) : (
          <a href="mailto:help@neopin.io" className="contact-us">
            문의하기
          </a>
        )}
      </div>
    </div>
  );
};

export default Faq;
