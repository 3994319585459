/* eslint-disable import/no-anonymous-default-export */
export const referralpayout220311 =  {
  ko: {
    subject: "[공지] 친구초대 이벤트 NPT 보상 지급 개시",
    body: `안녕하세요.
    블록체인 오픈플랫폼 네오핀입니다.
    
    네오핀 토큰(NPT)의 빗썸 거래소 상장을 기념하여 친구초대 이벤트 NPT 보상 지급을 시작합니다.
    
    지난 2월 23일(수) ~ 3월 2일(수) 진행했던 친구초대 이벤트에 대한 NPT 보상을 기존 4월 7일(목)에서 3월 11일(금)로 일정을 앞당겨 지급합니다.
    
    친구초대 이벤트에 참여하셨던 이용자님들은 11일 오후 2시부터 네오핀 어플 내 ‘미수령 보상’ 탭에서 수령하실 수 있습니다.
    
    [보상 수령 방법]
    지갑 > 나의 투자 > ‘미수령 보상’에서 ‘수령하기’ 버튼 클릭 > ‘보상 수령’ 버튼 클릭
    
    감사합니다.
    네오핀 드림
    
     `,
  },
  en: {
    subject: "[Notice] Advanced Payout of Referral Rewards (NPT)",
    body: `Hi, we are NEOPIN, an open blockchain platform for all.

    To celebrate NPT’s Bithumb Listing, we would like to announce an advanced payout of NPT rewards. This payout applies to NPT rewards from the Referral Event.
    
    NPT rewards for users who have participated in the Referral Event [Feb. 23rd (Wed) - Mar. 2nd (Wed)] will be rewarded starting from Mar. 11th (Fri) instead of Apr. 7th (Thurs).
    
    You may view your NPT rewards on the “Remaining Rewards” tab on the NEOPIN app starting from Mar. 11th (Fri) 05:00:00 (UTC).
    
    [How to Claim Rewards]
    Wallet > Invest > Tap “Claim” on the “Remaining Rewards” tab > Tap “Claim Rewards” tab
    
    We hope you enjoy your crypto experience with NEOPIN. 
    
    Thank you.
    Team NEOPIN
    `,
  },
};
