export const maintenance220823 = {
  ko: {
    subject: `[점검] 서비스 업데이트에 따른 점검 안내 (완료)`,
    body: `안녕하세요.
    블록체인 오픈 플랫폼 네오핀입니다.
    
    네오핀은 편리성과 안정성 강화를 위해 서비스 업데이트를 진행할 예정입니다.
    업데이트 시간 동안 점검을 진행하여 서비스 이용이 잠시 중단될 예정이오니, 네오핀 이용에 있어 참고하시기 바랍니다.
    
    - 일시: 2022.08.23 오후 3:00 ~ 4:00 (1시간)
    - 내용
      1) Yield Farm 단일 자산 예치 기능 추가
         Yield Farm 예치를 위해 별도의 스왑을 진행할 필요 없이, 하나의 자산으로 예치를 진행할 수 있는 단일 자산 예치 기능이 추가됩니다.
    
      2) 로딩 속도 개선
         화면과 상품 정보를 불러오는 로딩 속도를 개선하였습니다.
    
    사용자님들의 편의를 위해 최선을 다하는 네오핀에 앞으로도 꾸준한 관심과 응원 부탁 드립니다.
    감사합니다.
    
    `,
  },
  en: {
    subject: `[Notice] Scheduled Maintenance for Service Updates (completed)`,
    body: `Please be informed that NEOPIN will perform a scheduled maintenance for security reinforcement and service updates.
    All services will be temporarily unavailable during the update. We appreciate your patience and understanding.
    
     - Date: 23 August 2022, 6:00-7:00am UTC (1 hr) 
     - Updates:
      1) Yield Farm - Single asset deposit added
                    - You may deposit a single asset in yield farms without having to swap assets.
      2) Loading speed improved
                    - Page/product info loading speed to be improved
    
    NEOPIN will keep striving to provide the best user experience possible. 
    Thank you for your continued interest and support.
    `,
  },
};
