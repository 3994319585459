/* eslint-disable import/no-anonymous-default-export */
export const sheepFarmAirDrop221202 = {
  // 영문만 배포
  ko: {
    subject: "[소식] 네오핀 x 쉽팜 인 메타랜드 온보딩 안내 (완료)",
    body: `안녕하세요, 네오핀입니다.

    파트너사 ‘쉽팜 인 메타랜드’가 네오핀에 온보딩되었습니다.
    
    온보딩을 통해 앞으로 NPT를 통해 ‘Sheep Racing’에서 사용할 수 있는 베팅티켓을 구매할 수 있게 될 예정입니다.
    
    현재 네오핀에는 쉽팜 인 메타랜드의 거버넌스 토큰 ‘나이팅게일 토큰(NGIT)’ 및 ‘마멀레이드 토큰(MARD)’ 관련 디파이 상품도 출시되어 있으니 많은 관심 부탁드립니다.
    
    [‘쉽팜 인 메타랜드’란?]
    ‘쉽팜 인 메타랜드’는 ‘Sheep Farming’과 ‘Sheep Racing’을 즐길 수 있는 P2E Virtual World입니다. ‘Sheep Farming’은 목초지를 구입해 다양한 품종의 양을 모아 사육하고 양털을 팔아 토큰을 생산하는 P2E 게임이며, ‘Sheep Racing’은 베팅티켓을 이용해 레이싱에 참여하고 베팅 당첨금을 얻는 게임입니다.
    
    네오핀과 ‘쉽팜 인 메타랜드’는 온보딩을 통해 계속해서 협력해나갈 계획이니 많은 기대 부탁드립니다.
    
    감사합니다.    
  `,
  },

  en: {
    subject:
      "[Announcement] NEOPIN X Sheepfarm 1,000 NPT & Betting Ticket Airdrop (completed)",
    body: `NEOPIN and “Sheepfarm in Metaland” will be dropping NPT and betting tickets for “Sheep Racing”.

    Betting tickets will be given to all participants, and a total of 1,000 NPT will be up for grabs.
    
    [1,000 NPT & Betting Ticket Airdrop]
    - WHEN: 2022.12.2(Fri.) 05:00:00 ~ 12.16(Fri.) 05:59:59 [UTC]
    (🔗Event & Race schedule can be found on Sheepfarm’s Medium - <a href="https://sheepfarm.medium.com/sheepfarm-x-neopin-airdrop-event-e1df388c30e2"> go to Sheepfarm Medium</a>)
    - WINNER ANN: 2022.12.23(Fri.) 08:00 [UTC]
    - HOW:
       ① Gleam task & NEOPIN KYC verification <a href="https://gleam.io/O4cEI/sheepfarm-x-neopin-npt-token-airdrop-event"> Go to Gleam </a>
       ② Receive betting tickets and participate in event race (held twice - every friday during event period)
       ③ Invite friends to Gleam and receive points 
    - AIRDROP:
       ① All event participants will receive up to 20 betting tickets (10 per week)
       ② Top 20 betters in the event races will receive 25 NPT each (500 NPT total) 
       ③ Top 5 gleam task point earners will receive 100 NPT each (500 NPT total)
    
    ※ KYC verification may take up to three working days. We recommend you to complete sign up and KYC verification before we verify your wallet address.
     
`,
  },
};
