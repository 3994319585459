export const rewardSettlementCompletet20220725 = {
  ko: {
    subject: `[공지] 클레이튼, 트론 스테이킹 KLAY, NPT 리워드 정산 완료 `,
    body: `안녕하세요. 
네오핀 운영팀입니다. 

클레이튼 리워드 정산 시스템의 장애로 인해 금일 지급될 클레이튼, 트론 스테이킹 시 KLAY, NPT 리워드 수량의 오차를 확인하였으며 
클레이튼, 트론 스테이킹 한 전체 이용자 대상으로 전수조사 완료 후 오차가 발생한 수량을 추가 지급 하였습니다. 
지갑 > 나의 투자 >  미수령 보상에서 수령 가능합니다.

서비스  이용에 불편을 드려 대단히 죄송합니다.

감사합니다.`,
  },
  en: {
    subject: `[Notice] Klaytn, Tron Staking KLAY, NPT Reward Compensation Completed`,
    body: `We have confirmed that there was an unfortunate error made in the calculations for KLAY, NPT rewards when staking Klaytn and Tron to be compensated today.

After completing a full investigation for all Klaytn and Tron staking users, the amount of rewards for which an error occurred has been compensated. 

Now you can receive the unclaimed rewards through Wallet > Invest > Remaining Rewards.

We are sincerely sorry for the inconvenience caused to you.

Thank you. 

Team NEOPIN
`,
  },
};
